import React from "react";
import ReactDOM from "react-dom/client";
import "yup-phone";

import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import "./services/i18n";
import "./index.css";
import { Provider } from "react-redux";
import { createStore } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { Spinner } from "./components/Loading/Spinner.component";
import { SpinnerTypes } from "./utils/util-vars";

import persistStore from "redux-persist/es/persistStore";
import Modal from "react-modal";
import { createBrowserRouter } from "react-router-dom";
import { setStore } from "./services/store";
import { enableToggleMockedApi, webServerLauncher } from "./services/launch-mocked-web-server";
Modal.setAppElement("#root");

enableToggleMockedApi();
webServerLauncher();
// TODO: display Spinner before flags provider is loaded
const renderApp = async () => {
  const store = createStore({ withPersistor: true });
  const persistor = persistStore(store);

  setStore(store);

  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
  root.render(
    <Provider store={store}>
      <PersistGate loading={<Spinner type={SpinnerTypes.default} />} persistor={persistor}>
        <App createRouter={createBrowserRouter} />
      </PersistGate>
    </Provider>
  );
};

renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
