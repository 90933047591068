import { createApi } from "@reduxjs/toolkit/query/react";
import { TransactionEndpoints } from "../enums/transactions-endpoints";
import { commonResponseSchema } from "../models/responses/common-response";
import { TransactionsRequest, transactionsResponseSchema } from "../models/transactions/transactions";
import { baseQueryProtected } from "../query-fn/protected";

export const TransactionApi = createApi({
  reducerPath: "transactionsApi",
  baseQuery: baseQueryProtected,
  endpoints: builder => ({
    getTransactions: builder.query({
      query: ({ limit }: TransactionsRequest) => ({
        url: TransactionEndpoints.TRANSACTIONS + `?${new URLSearchParams({ limit: String(limit) })}`,
        method: "GET",
      }),
      transformResponse: response => {
        return commonResponseSchema(transactionsResponseSchema()).parse(response).data;
      },
    }),
  }),
});

export const { useGetTransactionsQuery } = TransactionApi;
