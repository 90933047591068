import * as yup from "yup";

export const getCsrfResponseSchema = yup.object().shape({
  data: yup.object().shape({
    csrfToken: yup.object().shape({
      expiresIn: yup.number().required(),
      token: yup.string().required(),
    }),
  }),
});

export type GetCsrfResponse = yup.InferType<typeof getCsrfResponseSchema>;
