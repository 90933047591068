import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "ui-components/src/components/Button/Button.component";
import { useGetPaymentConfirmationQuery } from "../../api/slices/payment.api";
import { Spinner } from "../Loading/Spinner.component";
import { SpinnerTypes, ToastTypes } from "../../utils/util-vars";
import { InlineSpinner } from "../Loading/InlineSpinner.component";
import { useAppDispatch } from "../../store/store";
import { appActions } from "../../store/slices/app.slice";
import { notify } from "../../utils/notify";
import { useLazyPermissionQuery } from "../../api/slices/user.api";
import { ApiErrorCode } from "../../api/error-codes";

interface PaymentConfirmationComponentProps {
  transactionId: string;
  retry: () => void;
}

const PaymentConfirmationComponent: FC<PaymentConfirmationComponentProps> = ({ transactionId, retry }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false);
  const { data, error } = useGetPaymentConfirmationQuery(transactionId, {
    pollingInterval: isPaymentConfirmed ? undefined : 3000,
  });
  const [isNextStepAvailable, setIsNextStepAvailable] = useState(false);
  const [
    fetchPermission,
    {
      data: permissionData,
      error: permissionError,
      isLoading: isPermissionUpdating,
      isUninitialized: isPermissionUninitialized,
    },
  ] = useLazyPermissionQuery({
    pollingInterval: isNextStepAvailable ? undefined : 3000,
  });
  const isProcessing =
    (!error && !data?.isPaymentConfirmed) || (data?.isPaymentConfirmed && !isPermissionUninitialized);

  useEffect(() => {
    // stop polling if payment confirmed
    if (data?.isPaymentConfirmed) {
      setIsPaymentConfirmed(true);
    }
  }, [data?.isPaymentConfirmed]);

  useEffect(() => {
    // stop polling when permission upgraded
    if (permissionData?.error?.code && permissionData.error.code !== ApiErrorCode.OnboardingRequired) {
      setIsNextStepAvailable(true);
    }
  }, [permissionData?.error?.code]);

  useEffect(() => {
    if (permissionData?.data) {
      dispatch(appActions.updateAccessToken({ accessToken: permissionData.data.accessToken.token }));
    }
  }, [dispatch, permissionData?.data]);

  const handleOkButtonClicked = async () => {
    await fetchPermission("");
  };

  useEffect(() => {
    if (permissionError) {
      notify(t("paymentConfirmation.permissionError"), ToastTypes.error);
    }
  }, [permissionError, t]);

  if (error) {
    return (
      <div className="grid w-full grid-cols-2 gap-8">
        <h2 className="col-span-2 text-center font-brown-bold text-2xl">{t("paymentConfirmation.error.title")}</h2>
        <h3 className="col-span-2 text-center font-brown-regular text-xs">
          {t("paymentConfirmation.error.description")}
        </h3>
        <Button buttonType="selectButton" className="col-span-2 !bg-red-400" disabled={isProcessing} onClick={retry}>
          {t("paymentConfirmation.error.submit.title")}
        </Button>
      </div>
    );
  }

  if (isProcessing) {
    return (
      <div className="grid w-full grid-cols-2 gap-8">
        <h2 className="col-span-2 text-center font-brown-bold text-2xl">{t("paymentConfirmation.processing.title")}</h2>
        <div className="h-32">
          <Spinner type={SpinnerTypes.default} />
        </div>
      </div>
    );
  }

  return (
    <div className="grid w-full grid-cols-2 gap-8">
      <h2 className="col-span-2 text-center font-brown-bold text-2xl">{t("paymentConfirmation.successful.title")}</h2>
      <h3 className="col-span-2 text-center font-brown-regular text-xs">
        {t("paymentConfirmation.successful.description")}
      </h3>
      <Button
        buttonType="selectButton"
        className="col-span-2 bg-blue-700"
        onClick={handleOkButtonClicked}
        disabled={isPermissionUpdating}
      >
        {isPermissionUpdating && (
          <>
            <InlineSpinner />
          </>
        )}
        {t("paymentConfirmation.successful.submit.title")}
      </Button>
    </div>
  );
};

export default PaymentConfirmationComponent;
