import { MembershipApi } from "../../api/endpoints/membership.api";
import { getStore } from "../../services/store";

export const loadMembership = async () => {
  const membership = getStore().dispatch(MembershipApi.endpoints.getMembership.initiate(""));

  try {
    const response = await membership.unwrap();

    return response;
  } catch (error) {
    // TODO: handle error in the future

    return {};
  } finally {
    membership.unsubscribe();
  }
};
