import {
  defaultSectionsFieldsOrder,
  defaultSectionsOrder,
} from "../../app/guards/feature-flags/definitions/signUpFieldsArrangement";
import { useFeatureFlags } from "../../app/guards/feature-flags/useFeatureFlags";

/**
 * Create form config with ordered sections and ordered fields inside
 */
export const useSignUpFormConfig = () => {
  const flags = useFeatureFlags();

  const sectionsOrderDefault = defaultSectionsOrder();
  const sectionsOrderFromFlag = flags.signUpFieldsArrangement.sectionsOrder;
  const isCustomArrangementEnabled = flags.signUpFieldsArrangement.enabled && Boolean(sectionsOrderFromFlag);
  const orderedSections = isCustomArrangementEnabled ? sectionsOrderFromFlag : sectionsOrderDefault;

  const fieldsInsideSectionsDefault = defaultSectionsFieldsOrder();
  const fieldsInsideSectionsFromFlag = flags.signUpFieldsArrangement.sectionsFieldsOrder;
  const fieldsInsideSections = isCustomArrangementEnabled ? fieldsInsideSectionsFromFlag : fieldsInsideSectionsDefault;

  return {
    // @ts-ignore
    sections: orderedSections.map(section => ({
      section,
      // @ts-ignore
      fields: fieldsInsideSections[section] || [],
    })),
  };
};
