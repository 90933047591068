import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Plan } from "../../api/models/plans/plan";

interface IPreMadePlanCardProps {
  type?: "regular" | "upgrade";
  plan: Plan;
  selectedPlan?: Plan;
  handleSelectPlan: (plan: Plan) => void;
}

const ButtonClasses = {
  regular: {
    selected: "bg-blue-600  text-white",
    unselected: "bg-white text-blue-600 border-2 border-blue-600 hover:bg-blue-600 hover:text-white transition-all",
  },
  upgrade: {
    selected: "bg-white text-[#5F1DA8]-600",
    unselected: "text-white border-2 border-white",
  },
};

const cardClasses = {
  regular: "bg-white",
  upgrade: "bg-gradient-to-t from-[#5F1DA8] to-[#635CF3] text-white",
};

export const PreMadePlanCard = ({ type = "regular", plan, selectedPlan, handleSelectPlan }: IPreMadePlanCardProps) => {
  // @ts-ignore
  const ButtonClassSetter = btClasses => ({
    [btClasses.selected]: selectedPlan?.id === plan.id,
    [btClasses.unselected]: selectedPlan?.id !== plan.id,
  });

  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        "col-span-1 flex flex-col min-h-full gap-5 justify-items-start rounded-[20px] p-4 backdrop-blur-md",
        cardClasses[type]
      )}
    >
      <div className="text-left font-brown-regular">
        <h5 className="font-brown-bold text-blue-600">{plan.name}</h5>
        <h2 className="py-2 font-brown-bold sm:py-1 sm:text-sm lg:text-4xl ">
          {plan.currencySymbol}
          {plan.price}{" "}
        </h2>
      </div>
      <div className="py-5">
        {plan.fees.map(fee => (
          <div key={fee.id} className="flex flex-col py-1 ">
            <h5 className="text-left font-inter sm:text-xs lg:text-sm ">
              {fee.description} {fee.amount}
            </h5>
          </div>
        ))}
      </div>
      <div className="mt-auto">
        <button
          className={classNames(
            "font-brown-regular sm:text-xs lg:text-sm w-full  p-3 rounded-lg",
            ButtonClassSetter(ButtonClasses[type])
          )}
          onClick={() => handleSelectPlan(plan)}
        >
          {t("premadePlansPage.selectPlan")} {plan.name}
        </button>
      </div>
    </div>
  );
};
