import { z } from "zod";

export const VerificationSchema = () =>
  z.object({
    // title: z.literal("Verification"),
    // instance: z.literal("/v2/verify/confirm"),
    accessToken: z.object({
      token: z.string(),
      expiresIn: z.number(),
      scopes: z.array(z.string()),
    }),
  });

export type VerificationResponse = z.infer<ReturnType<typeof VerificationSchema>>;
