import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ApiErrorCode } from "../../api/error-codes";
import { getServerErrorResponse } from "../../api/error-handling";
import { useValidateEmailMutation, useValidatePhoneMutation } from "../../api/slices/auth.api";
import { createErrorMessage, memoizeDebounce } from "../../utils/form/server-side-validation";
import { useValidationSchema } from "./useValidationSchema";

export const useSignUpValidation = () => {
  const { t } = useTranslation();
  const [validateEmailRequest] = useValidateEmailMutation();
  const [validatePhoneRequest] = useValidatePhoneMutation();

  const validateEmail = useCallback(
    memoizeDebounce(async (email: string, context) => {
      try {
        await validateEmailRequest({ email }).unwrap();
        return true;
      } catch (error) {
        const code = getServerErrorResponse(error)?.code;
        throw context.createError(
          createErrorMessage(
            {
              [ApiErrorCode.EmailAlreadyInUse]: t("validation.server.emailAlreadyInUse"),
            },
            code,
            t("validation.server.invalidEmailField")
          )
        );
      }
    }),
    [t, validateEmailRequest]
  );

  const validatePhone = useCallback(
    memoizeDebounce(async (phone: string, context) => {
      try {
        await validatePhoneRequest({ phone }).unwrap();
        return true;
      } catch (error) {
        const code = getServerErrorResponse(error)?.code;
        throw context.createError(
          createErrorMessage(
            {
              [ApiErrorCode.PhoneAlreadyInUse]: t("validation.server.phoneAlreadyInUse"),
              [ApiErrorCode.PhoneAlreadyInUse2]: t("validation.server.phoneAlreadyInUse"),
            },
            code,
            t("validation.server.invalidPhoneField")
          )
        );
      }
    }),
    [t, validatePhoneRequest]
  );

  const schema = useValidationSchema({ validateEmail, validatePhone });

  return schema;
};
