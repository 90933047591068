import { z } from "zod";
import { currencyCodeSchema } from "../atoms/currency-code";

export const discountSchema = () =>
  z.object({
    id: z.string(),
    planName: z.string(),
    planId: z.string(),
    currencyISO: currencyCodeSchema(),
    price: z.number(),
    discountCodeId: z.string(),
    status: z.string(),
    createdAt: z.string(),
    activatedOn: z.string(),
    lastBilledOn: z.string(),
  });
