import { FC, ReactElement } from "react";
import ReactModal from "react-modal";
import { CloseButton } from "../CloseButton/CloseButton.component";

interface ModalProps {
  title?: string;
  children: ReactElement;
  isOpened: boolean;
  closeModal?: () => void;
}

export const Modal: FC<ModalProps> = ({ isOpened, title, children, closeModal }) => (
  <ReactModal
    className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
    overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-[9999]"
    isOpen={isOpened}
    onRequestClose={closeModal}
    contentLabel={title}
  >
    {children}
    {closeModal && <CloseButton closeHandler={closeModal} />}
  </ReactModal>
);
