import { Navigate, Route } from "react-router-dom";
import { AppRoutePath } from "../../app/constants";
import { OnboardingLayout } from "../../layouts/OnboardingLayout";
import { KycStatusPage } from "./status/KycStatus.page";
import { KycIntroPage } from "./intro/KycIntro.page";
import { KycVerificationPage } from "./verification/KycVerification.page";

export const getKycRoutes = () => (
  <Route path={AppRoutePath.KYC()} element={<OnboardingLayout withHeaderMenu={false} />}>
    <Route index element={<Navigate to={AppRoutePath.KYC_INTRO()} />} />
    <Route path={AppRoutePath.KYC_INTRO()} element={<KycIntroPage />} />
    <Route path={AppRoutePath.KYC_VERIFICATION()} element={<KycVerificationPage />} />
    <Route path={AppRoutePath.KYC_STATUS()} element={<KycStatusPage />} />
  </Route>
);
