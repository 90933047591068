export enum ToastTypes {
  success = "success",
  error = "error",
  warning = "warning",
  info = "info",
  default = "default",
}

export enum SpinnerTypes {
  default = "default",
  top = "top",
  bottom = "bottom",
}
