import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "ui-components/src/components/Button/Button.component";
import { VerificationCodeField } from "ui-components/src/components/Input/Input.field";
import { ConfirmMfaApi } from "../../../api/slices/confirm-mfa.api";
import { Form } from "../../../components/Form/Form.component";
import { InlineSpinner } from "../../../components/Loading/InlineSpinner.component";
import { appActions } from "../../../store/slices/app.slice";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { notify } from "../../../utils/notify";
import { ToastTypes } from "../../../utils/util-vars";

export const DeviceConfirmPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const confirmToken = useAppSelector(state => state.app.confirmToken);
  const [confirmDevice, { data, error, isLoading }] = ConfirmMfaApi.useDeviceConfirmMutation();
  const methods = useForm({
    defaultValues: {
      secretCode: "",
    },
  });
  const isButtonActive = methods.watch("secretCode").length === 6 || isLoading;

  useEffect(() => {
    if (data) {
      dispatch(
        appActions.updateAccessToken({
          accessToken: data.accessToken.token,
        })
      );
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (error) {
      notify(t("confirmMfa.invalidCode"), ToastTypes.error);
    }
  }, [error, t]);

  if (!confirmToken) {
    return null;
  }

  return (
    <div className="mx-auto mb-8 mt-10 max-w-[495px] flex-1">
      <div className="flex flex-col rounded-2xl bg-white/[.94] px-12 py-6 shadow-md">
        <div className="mb-6">
          <h1 className="w-full text-center text-2xl font-bold">{t("confirmMfa.title")}</h1>
          <p className="font-inter w-full whitespace-pre-wrap py-2 text-center text-sm ">
            {t("confirmMfa.description")}
          </p>
          <p className="w-full whitespace-pre-wrap py-2 text-center text-sm font-bold">{t("confirmMfa.hint")}</p>
        </div>
        <Form
          {...methods}
          onSubmit={({ secretCode }) =>
            confirmDevice({
              confirmToken,
              secretCode: Number(secretCode),
            })
          }
        >
          <div className="mb-6">
            <div className="font-inter relative flex flex-col justify-between gap-3">
              <VerificationCodeField length={6} name="secretCode" placeholder={""} autoFocus label="otp" />
            </div>
          </div>
          <div className="flex items-center justify-center">
            <Button type="submit" buttonType="selectButton" disabled={!isButtonActive}>
              {methods.formState.isSubmitting ? (
                <>
                  <InlineSpinner />
                  {t("signIn.form.submitButton.loading")}
                </>
              ) : (
                <>{t("confirmMfa.buttonText")}</>
              )}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
