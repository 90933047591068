import { createBrowserRouter } from "react-router-dom";

type Router = ReturnType<typeof createBrowserRouter>;
let _router: Router;

export const setRouter = (router: Router) => {
  _router = router;
};

export const getRouter = () => _router;
