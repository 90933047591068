import { z } from "zod";
import { currencyCodeSchema } from "../atoms/currency-code";

export const walletSchema = () =>
  z.object({
    enabled: z.array(currencyCodeSchema()),
    disabled: z.array(currencyCodeSchema()),
    config: z
      .record(
        currencyCodeSchema(),
        z.object({
          // UUID
          currencyId: z.string(),
          list: z.array(
            z.object({
              cardDesign: z.string(),
            })
          ),
        })
      )
      .optional(),
  });
