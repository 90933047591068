import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryImages } from "../query-fn/images";

if (!process.env.REACT_APP_IMAGE_PROXY_URL) {
  throw new Error(`"REACT_APP_IMAGE_PROXY_URL" environment variable is not provided`);
}

export const ImageApi = createApi({
  reducerPath: "imageApi",
  baseQuery: baseQueryImages,
  endpoints: builder => ({
    getImage: builder.query({
      query: url => ({
        url: process.env.REACT_APP_IMAGE_PROXY_URL + url,
        method: "GET",
        responseHandler: async response => URL.createObjectURL(await response.blob()),
      }),
    }),
  }),
});

export const { useLazyGetImageQuery } = ImageApi;
