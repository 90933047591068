import React, { FC, useEffect, useMemo } from "react";
import { createBrowserRouter, createRoutesFromElements, RouteObject, RouterProvider } from "react-router-dom";
import { getRouteElements } from "./App.routes";

import { ToastContainer } from "react-toastify";
import { Spinner } from "../components/Loading/Spinner.component";
import { SpinnerTypes } from "../utils/util-vars";
import { setRouter } from "../services/router";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/pagination";

interface AppProps {
  createRouter: (routes: RouteObject[]) => ReturnType<typeof createBrowserRouter>;
}

const App: FC<AppProps> = ({ createRouter }) => {
  const routes = useMemo(() => createRoutesFromElements(getRouteElements()), []);
  const router = useMemo(() => createRouter(routes), [routes, createRouter]);

  useEffect(() => {
    setRouter(router);
  }, [router]);

  return (
    <>
      <RouterProvider router={router} fallbackElement={<Spinner type={SpinnerTypes.default} />} />
      <ToastContainer />
    </>
  );
};

export default App;
