import React, { forwardRef, HTMLProps, ReactNode } from "react";
import classNames from "classnames";
import { UI_TEST_IDS } from "../../test-utils/ui-test-ids";

export interface CheckboxProps extends Omit<HTMLProps<HTMLInputElement>, "type" | "label"> {
  type?: "checkbox" | "radio";
  label: ReactNode;
  helperText?: string | null;
  classes?: {
    root?: string;
    label?: string;
    helperText?: string;
  };
  isError?: boolean;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ type = "checkbox", label, classes, className, helperText, isError, ...props }, ref) => {
    return (
      <div className={classNames("inputfield-root", classes?.root, { "is-error": isError })}>
        <div className={classNames("flex gap-3 items-center")}>
          {/* @ts-ignore */}
          <input
            ref={ref}
            type={type}
            className={classNames("rounded-checkbox cursor-pointer", className)}
            {...props}
            data-testid={UI_TEST_IDS.input.control(props.name)}
          />
          {label && (
            // @ts-ignore
            <label htmlFor={props.id} className={classNames("cursor-pointer", classes?.label)}>
              {label}
              {props.required ? "*" : ""}
            </label>
          )}
        </div>
        {helperText && (
          <p
            className={classNames("opacity-50 text-sm mt-1", classes?.helperText, {
              "inputfield-helper-text": isError,
            })}
          >
            {helperText}
          </p>
        )}
      </div>
    );
  }
);
