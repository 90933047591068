import i18next from "i18next";
import { setLocale } from "yup";
import { initReactI18next } from "react-i18next";
import en from "../locales/en.json";

const setYupTranslations = () => {
  // non-nullable variant of t function
  const t = (key: string) => i18next.t(key) || key;
  setLocale({
    mixed: {
      required: t("validation.required"),
    },
    string: {
      email: t("validation.format.email"),
      // phone: t("validation.format.phone"),
    },
  });
};

i18next.on("initialized", setYupTranslations);

i18next.use(initReactI18next).init({
  debug: true,
  resources: {
    en: {
      translation: en,
    },
  },
  lng: "en",
  returnNull: false,
});

export default i18next;
