import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { AppRoutePath } from "../app/constants";
import DashboardLayout from "./DashboardLayout";

const AccountsLayout = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const accountsLinks = useMemo(
    () => [
      {
        path: AppRoutePath.ACCOUNTS.ROOT(),
        children: t("accounts.topBar.links.root"),
        isActive: location.pathname === AppRoutePath.ACCOUNTS.ROOT(),
      },
      {
        children: t("accounts.topBar.links.add"),
        path: AppRoutePath.ACCOUNTS.ADD(),
        isActive: location.pathname === AppRoutePath.ACCOUNTS.ADD(),
      },
    ],
    [location, t]
  );
  return <DashboardLayout topBarLinks={accountsLinks} />;
};

export default AccountsLayout;
