import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { combineReducers } from "redux";
import { AuthApi } from "../api/slices/auth.api";
import { AppSlice } from "./slices/app.slice";
import storage from "redux-persist/lib/storage";
import { persistReducer, PERSIST } from "redux-persist";
import { featureFlagsSlice } from "./slices/feature-flags.slice";
import { PERSISTOR_STORAGE_ROOT_KEY } from "../app/constants";
import { SelectPlanApi } from "../api/slices/selectPlan.api";
import { ImageApi } from "../api/slices/image.api";
import { OnboardingSlice } from "./slices/onboarding.slice";
import { UserApi } from "../api/slices/user.api";
import { PaymentApi } from "../api/slices/payment.api";
import { VerificationApi } from "../api/slices/verification.api";
import { ConfirmMfaApi } from "../api/slices/confirm-mfa.api";
import { KycApi } from "../api/slices/kyc.api";
import { TransactionApi } from "../api/slices/transactions.api";
import { UserCardApi } from "../api/slices/userCard.api";
import { BaseProtectedApi } from "../api/endpoints/slices/base-protected.api";

const rootReducer = combineReducers({
  [AuthApi.reducerPath]: AuthApi.reducer,
  [SelectPlanApi.reducerPath]: SelectPlanApi.reducer,
  [ImageApi.reducerPath]: ImageApi.reducer,
  [UserApi.reducerPath]: UserApi.reducer,
  [PaymentApi.reducerPath]: PaymentApi.reducer,
  [VerificationApi.reducerPath]: VerificationApi.reducer,
  [ConfirmMfaApi.reducerPath]: ConfirmMfaApi.reducer,
  [AppSlice.name]: AppSlice.reducer,
  [OnboardingSlice.name]: OnboardingSlice.reducer,
  [KycApi.reducerPath]: KycApi.reducer,
  [TransactionApi.reducerPath]: TransactionApi.reducer,
  [UserCardApi.reducerPath]: UserCardApi.reducer,
  [BaseProtectedApi.reducerPath]: BaseProtectedApi.reducer,
  featureFlags: featureFlagsSlice.reducer,
});

// Whitelist stores for localstorage
const persistConfig = {
  key: PERSISTOR_STORAGE_ROOT_KEY,
  storage,
  whitelist: [AppSlice.name, OnboardingSlice.name],
};

export interface CreateStoreArgs {
  withPersistor?: boolean;
  preloadedState?: RootState;
}

export const createStore = ({ withPersistor, preloadedState }: CreateStoreArgs = {}) =>
  configureStore({
    // @ts-ignore
    reducer: withPersistor ? persistReducer(persistConfig, rootReducer) : rootReducer,
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [PERSIST],
        },
      })
        .concat(AuthApi.middleware)
        .concat(SelectPlanApi.middleware)
        .concat(ImageApi.middleware)
        .concat(UserApi.middleware)
        .concat(PaymentApi.middleware)
        .concat(VerificationApi.middleware)
        .concat(ConfirmMfaApi.middleware)
        .concat(KycApi.middleware)
        .concat(TransactionApi.middleware)
        .concat(UserCardApi.middleware)
        .concat(BaseProtectedApi.middleware),
  });

export type AppStore = ReturnType<typeof createStore>;
export type AppDispatch = AppStore["dispatch"];
export type RootState = ReturnType<typeof rootReducer>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
