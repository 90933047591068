import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { handleRedirect } from "./handleRedirect";

type QueryResult = Awaited<ReturnType<ReturnType<typeof fetchBaseQuery>>>;

export const handleQueryResult = (queryResult: QueryResult) => {
  handleRedirect(queryResult);

  // put other query handlers

  return queryResult;
};
