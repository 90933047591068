import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Plan } from "../../../api/models/plans/plan";
import { NationalCurrencyIso } from "../../../app/constants";
import { FullCurrencyLabel } from "../../../components/CurrencyLabel/CurrenyLabel.component";
import { Label } from "./Label.component";

const isNationalCurrency = (currency: string): currency is NationalCurrencyIso => {
  return Object.values<string>(NationalCurrencyIso).includes(currency);
};

interface WalletListProps {
  plan: Plan;
}

export const WalletList: FC<WalletListProps> = ({ plan }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Label>{t("checkout.wallets.title")}</Label>
      <div className="mt-3 flex flex-col gap-3">
        {plan.currencies.wallet.enabled.filter(isNationalCurrency).map(itemCurrency => {
          return <FullCurrencyLabel key={itemCurrency} currency={itemCurrency} />;
        })}
      </div>
    </div>
  );
};
