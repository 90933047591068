import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useMatches } from "react-router-dom";
import NotificationIcon from "ui-components/src/assets/NotificationIcon.svg";
import { AppRoutePath } from "../../app/constants";
import DashboardTopBar from "../../components/DashboardTopBar/DashboardTopBar.component";

const CardsPage = () => {
  return <Outlet />;
};

export default CardsPage;
