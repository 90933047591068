import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { VerificationCodeField } from "ui-components/src/components/Input/Input.field";
import { Form } from "../../components/Form/Form.component";
import { IConfirmMfa } from "../../api/models/confirm-mfa.interface";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useConfirmMfaMutation, useSetupMfaMutation } from "../../api/slices/confirm-mfa.api";
import Button from "ui-components/src/components/Button/Button.component";
import GoogleAuth from "../../assets/google-auth.svg";
import MicrosoftAuth from "../../assets/microsoft-auth.svg";
import TwillioAuth from "../../assets/twillio-auth.svg";
import Lock from "../../assets/lock-fill.svg";
import { useAppDispatch } from "../../store/store";
import { appActions } from "../../store/slices/app.slice";
import { notify } from "../../utils/notify";
import { getErrorResponseMessage } from "../../api/models/responses/common-response";
import { useNavigate } from "react-router-dom";
import { AppRoutePath } from "../../app/constants";
import { ToastTypes } from "../../utils/util-vars";

export const ConfirmMfaPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [setupMfa, { data: setupMfaResponse }] = useSetupMfaMutation();
  const [confirmMfa, { data: confirmMfaResponse, error: confirmMfaError }] = useConfirmMfaMutation();
  const methods = useForm<IConfirmMfa>({
    resolver: zodResolver(
      z.object({
        secretCode: z.string(),
      })
    ),
    mode: "onChange",
  });

  useEffect(() => {
    setupMfa({ type: "TOTP" });
  }, [setupMfa]);

  useEffect(() => {
    if (confirmMfaError) {
      notify(getErrorResponseMessage(confirmMfaError) || t("confirmMfa.invalidCode"), ToastTypes.error);
    }
  }, [confirmMfaError, t]);

  useEffect(() => {
    if (confirmMfaResponse) {
      dispatch(appActions.updateAccessToken({ accessToken: confirmMfaResponse.accessToken.token }));
      navigate(AppRoutePath.DASHBOARD());
    }
  }, [confirmMfaResponse, t, dispatch, navigate]);

  return (
    <div className="mx-auto mb-8 mt-10 max-w-[995px] flex-1">
      <div className="auth-card-padding grid h-[556px] grid-cols-2 gap-20 rounded-2xl bg-white shadow-md">
        <div className="col-span-1 items-center">
          <div className="flex flex-nowrap content-center align-middle">
            <img className="mt-1.5 h-full" src={Lock} alt={"^"} />
            <h1 className="pl-4 font-brown-bold text-xl">{t("setupMfa.2fa.title")}</h1>
          </div>
          <div className="px-6">
            <p className="whitespace-pre-wrap pt-12 font-inter text-sm">{t("setupMfa.2fa.description")}</p>
          </div>
          <div className="grid grid-cols-3 justify-between py-8 px-6">
            <div className="col-span-1">
              <img src={GoogleAuth} alt="Google Auth" />
              <span className="mt-3 flex whitespace-pre-wrap font-inter text-xs text-black/[.5]">
                {t("setupMfa.2fa.googleAuth")}
              </span>
            </div>
            <div className="col-span-1">
              <img src={TwillioAuth} alt="Twillio Auth" />
              <span className="mt-3 flex whitespace-pre-wrap font-inter text-xs text-black/[.5]">
                {t("setupMfa.2fa.twilioAuth")}
              </span>
            </div>
            <div className="col-span-1">
              <img src={MicrosoftAuth} alt="Microsoft Auth" />
              <span className="mt-3 flex whitespace-pre-wrap font-inter text-xs text-black/[.5]">
                {t("setupMfa.2fa.microsoftAuth")}
              </span>
            </div>
          </div>
        </div>
        <div className="col-span-1 items-center">
          <div className="flex items-center pt-16">
            <span className="flex-col text-sm">{t("setupMfa.2fa.otp.text")}</span>
            <img className="w-1/3 flex-col" src={setupMfaResponse?.qrCodeUrl || ""} alt="otpQr" />
          </div>
          <div className="flex w-full pt-6">
            <Form
              {...methods}
              onSubmit={({ secretCode }) => {
                if (!setupMfaResponse?.confirmToken?.token || !setupMfaResponse?.secret) {
                  return;
                }
                return confirmMfa({
                  secret: setupMfaResponse?.secret,
                  secretCode,
                  confirmToken: setupMfaResponse?.confirmToken?.token,
                });
              }}
            >
              <div className="relative flex max-w-full flex-col justify-between gap-3 font-inter">
                <VerificationCodeField
                  length={6}
                  id="secretCode"
                  name="secretCode"
                  placeholder={""}
                  autoFocus={true}
                  label="code"
                />
              </div>
              <div className="relative flex max-w-full flex-col justify-between pt-6">
                <Button buttonType={"selectButton"} type="submit">
                  Confirm
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
