import { useMemo } from "react";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { ValidationServerSide, withServerSideValidation } from "../../utils/form/server-side-validation";
import { onlyLatinCharactersRegex, PasswordValidation } from "../../utils/validators";

interface UseValidationSchemaArgs {
  validateEmail: ValidationServerSide;
  validatePhone: ValidationServerSide;
}

export const useValidationSchema = ({ validateEmail, validatePhone }: UseValidationSchemaArgs) => {
  const { t } = useTranslation();
  const today = new Date();

  return useMemo(
    () =>
      yup
        .object({
          email: withServerSideValidation({
            baseSchema: yup.string().required().email().default(""),
            validateServerSide: validateEmail,
          }),
          mobileNumber: withServerSideValidation({
            baseSchema: yup.string().required().default(""),
            validateServerSide: validatePhone,
          }),
          firstName: yup.string().required().default(""),
          latinFirstName: yup.string().when("$latinFirstName", {
            // @ts-ignore
            is: val => val === "",
            then: yup.string().notRequired(),
            // @ts-ignore
            otherwise: yup
              .string()
              .matches(onlyLatinCharactersRegex, t("signUp.form.fields.latinFirstName.error") || ""),
          }),
          lastName: yup.string().required().default(""),
          latinLastName: yup.string().when("$latinLastName", {
            // @ts-ignore
            is: val => val === "",
            then: yup.string().notRequired(),
            // @ts-ignore
            otherwise: yup
              .string()
              .matches(onlyLatinCharactersRegex, t("signUp.form.fields.latinLastName.error") || ""),
          }),

          countryCode: yup.string().required().default("GB"),
          state: yup
            .string()
            .when("countryCode", {
              is: "US",
              then: yup.string().required(),
            })
            .default(""),
          postCode: yup.string().required().default(""),
          city: yup.string().required().default(""),
          addressLine1: yup.string().required().default(""),
          addressLine2: yup.string().default(""),
          referralCode: yup.string().default(""),

          password: yup
            .string()
            .min(8, t("signIn.form.fields.password.error") || "")
            .matches(/[a-z]/, t("passwordRules.error.lowercase") || "")
            .matches(/[A-Z]/, t("passwordRules.error.uppercase") || "")
            .matches(/[0-9]/, t("passwordRules.error.number") || "")
            .max(32)
            .required(t("signIn.form.fields.password.required") || ""),
          confirmPassword: yup
            .string()
            .required()
            .oneOf([yup.ref("password"), null], t("validation.passwordsMustMatch") ?? (undefined || ""))
            .default(""),

          preferredDisplayLanguage: yup.string().required().default("en"),

          dateOfBirth: yup
            .date()
            .typeError(t("signUp.form.fields.birthday.error") as string)
            .transform(value => new Date(value))
            .max(
              new Date(new Date(today).setFullYear(today.getFullYear() - 18)),
              (t("signUp.form.fields.birthday.minAge.error") as string) || ""
            )
            .min(
              new Date(new Date(today).setFullYear(today.getFullYear() - 120)),
              (t("signUp.form.fields.birthday.maxAge.error") as string) || ""
            )
            .required(t("signUp.form.fields.birthday.required.error") as string)
            .default(today),

          ssn: yup
            .string()
            .when("countryCode", {
              is: "US",
              // @ts-ignore
              then: yup
                .string()
                .required()
                .matches(/^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$/, (t("signUp.form.fields.ssn.error") as string) || ""),
            })
            // @ts-ignore
            .default(""),
          membershipAgreement: yup
            .boolean()
            .required()
            .oneOf([true], t("signup.form.fields.agreeMembershipAgreement.error") || "")
            .default(false),

          termsAgreement: yup
            .boolean()
            .required()
            .oneOf([true], t("signup.form.fields.agreeTermsAndConditions.error") || "")
            .default(false),
        })
        .required(),
    [t, validateEmail, validatePhone]
  );
};

export type SignUpFieldValues = yup.InferType<ReturnType<typeof useValidationSchema>>;
