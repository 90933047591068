import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryProtected } from "../query-fn/protected";
import { SelectPlanEndpoints } from "../enums/select-plan-endpoints";
import { commonResponseSchema } from "../models/responses/common-response";
import { paymentDraftSchema } from "../models/payment/payment-draft";
import { ShippingPlanResponse, ShippingPlanSchema } from "../models/shippingPlan/shipping-plan";
import { discountSchema } from "../models/payment/discount";

export const SelectPlanApi = createApi({
  reducerPath: "selectPlanApi",
  baseQuery: baseQueryProtected,
  endpoints: builder => ({
    paymentCardDraft: builder.mutation({
      query: body => ({
        url: SelectPlanEndpoints.CHOOSE_CARD,
        method: "POST",
        body,
      }),
      transformResponse: response => {
        return commonResponseSchema(paymentDraftSchema()).parse(response).data;
      },
    }),
    applyDiscount: builder.mutation({
      query: (body: { planId: string; discountCode: string }) => ({
        url: SelectPlanEndpoints.APPLY_DISCOUNT,
        method: "POST",
        body,
      }),
      transformResponse: response => commonResponseSchema(discountSchema()).parse(response).data,
    }),
    shippingPlans: builder.query<ShippingPlanResponse, { planCode: string }>({
      query: arg => ({
        url: SelectPlanEndpoints.SHIPPING_PLANS,
        params: arg,
      }),
      transformResponse: response => {
        return commonResponseSchema(ShippingPlanSchema()).parse(response).data;
      },
    }),
  }),
});

export const { usePaymentCardDraftMutation, useLazyShippingPlansQuery, useApplyDiscountMutation } = SelectPlanApi;
