import { forwardRef } from "react";
import classNames from "classnames";

export interface ButtonProps {
  children: React.ReactNode;
  type?: "button" | "submit" | "reset";
  buttonType?: "selectButton" | "continueButton" | "backButton" | "closeButton";
  isSelectButton?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
}

const Classes = {
  selectButton: "select-button",
  continueButton: "continue-button",
  backButton: "back-button",
  upGradeButton: "upgrade-button",
  closeButton: "close-button",
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ type = "submit", onClick, children, buttonType = "continueButton", className, disabled, ...props }, ref) => {
    const classes = classNames("font-brown-bold relative", Classes[buttonType], className);
    return (
      <button ref={ref} onClick={onClick} className={classes} disabled={disabled} {...props}>
        {children}
      </button>
    );
  }
);

export default Button;
