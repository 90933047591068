import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CircleCheckmarkIcon } from "ui-components/src/assets/icon/circle-checkmark.svg";
import Button from "ui-components/src/components/Button/Button.component";
import { AppRoutePath } from "../../app/constants";

export const StarterPlanConfirmationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goForward = () => navigate(AppRoutePath.CHECKOUT());
  const goBack = () => navigate(-1);

  return (
    <div className="mx-auto flex w-full max-w-[640px] py-24">
      <div className="relative flex-auto rounded-[20px] border-gray-200 bg-white/80 px-14 py-8 shadow backdrop-blur-xl">
        <h1 className="font-brown-bold text-2xl">{t("starterPlanConfirmation.title")}</h1>
        <h1 className="font-brown-bold text-2xl">{t("starterPlanConfirmation.subtitle")}</h1>
        <p className="font-inter mt-4 text-base">{t("starterPlanConfirmation.description")}</p>
        <div className="mt-3 grid grid-cols-2">
          <p className="flex items-center gap-1">
            <CircleCheckmarkIcon />
            {t("starterPlanConfirmation.benefits.tradeCrypto")}
          </p>
          <p className="flex items-center gap-1">
            <CircleCheckmarkIcon />
            {t("starterPlanConfirmation.benefits.deposit10kLimit")}
          </p>
          <p className="flex items-center gap-1">
            <CircleCheckmarkIcon />
            {t("starterPlanConfirmation.benefits.cardFunding")}
          </p>
        </div>
        <div className="mt-14 flex gap-3">
          <Button
            buttonType="selectButton"
            className="!bg-transparent !text-indigo-500 ring-1 ring-indigo-500"
            onClick={goBack}
          >
            {t("starterPlanConfirmation.buttons.chooseAnotherPlan")}
          </Button>
          <Button buttonType="selectButton" onClick={goForward}>
            {t("starterPlanConfirmation.buttons.continue")}
          </Button>
        </div>
      </div>
    </div>
  );
};
