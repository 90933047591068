import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { KycApi } from "../../../api/slices/kyc.api";
import { AppRoutePath } from "../../../app/constants";
import { Spinner } from "../../../components/Loading/Spinner.component";
import { appActions } from "../../../store/slices/app.slice";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { notify } from "../../../utils/notify";
import { SpinnerTypes, ToastTypes } from "../../../utils/util-vars";

// Taken from here:
// https://github.com/Jumio/implementation-guides/blob/master/netverify/netverify-web-v4.md#after-the-user-journey
// prettier-ignore
enum JumioErrorCode {
  ErrorOccuredOnServer                  = 9100,
  InvalidAuthorization                  = 9200,
  SessionExpired                        = 9210,
  ImageTransmittingFailed               = 9300,
  VerificationFailed                    = 9400,
  LostNetworkConnection                 = 9800,
  UnexpectedClientError                 = 9801,
  CommunicationWithServerFailed         = 9810,
  FileUploadOrCameraDisabled            = 9820,
  BiometricFaceCaptureFailed            = 9821,
  NoAcceptableSumbissionInThreeAttempts = 9835,
}

export const KycVerificationPage = () => {
  const { t } = useTranslation();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const dispatch = useAppDispatch();
  const { kycSubmitted, kycApproved } = useAppSelector(state => state.app);
  const navigate = useNavigate();
  const [isLoaded, setIsLoded] = useState(false);
  const [initiateWebClient, { data, error }] = KycApi.useInitiateWebClientMutation();

  const localizedJumioErrorCodes = useMemo(
    (): Record<JumioErrorCode, string> => ({
      [JumioErrorCode.ErrorOccuredOnServer]: t("kyc.verification.error.server"),
      [JumioErrorCode.InvalidAuthorization]: t("kyc.verification.error.authorization"),
      [JumioErrorCode.SessionExpired]: t("kyc.verification.error.sessionExpired"),
      [JumioErrorCode.ImageTransmittingFailed]: t("kyc.verification.error.imagesTransmissionFailed"),
      [JumioErrorCode.VerificationFailed]: t("kyc.verification.error.verificationFailed"),
      [JumioErrorCode.LostNetworkConnection]: t("kyc.verification.error.network"),
      [JumioErrorCode.UnexpectedClientError]: t("kyc.verification.error.client"),
      [JumioErrorCode.CommunicationWithServerFailed]: t("kyc.verification.error.communicationWithServer"),
      [JumioErrorCode.FileUploadOrCameraDisabled]: t("kyc.verification.error.fileUploadOrCameraFailed"),
      [JumioErrorCode.BiometricFaceCaptureFailed]: t("kyc.verification.error.biometricFaceCaptureFailed"),
      [JumioErrorCode.NoAcceptableSumbissionInThreeAttempts]: t("kyc.verification.error.reachedLimit"),
    }),
    [t]
  );

  useEffect(() => {
    initiateWebClient("");
  }, [initiateWebClient]);

  useEffect(() => {
    if (kycApproved) {
      navigate(AppRoutePath.DASHBOARD(), { replace: true });
      return;
    }

    if (kycSubmitted) {
      navigate(AppRoutePath.KYC_STATUS(), { replace: true });
      return;
    }
  }, [kycSubmitted, kycApproved, navigate]);

  useEffect(() => {
    if (error) {
      notify(t("kyc.verification.error.initialize"), ToastTypes.error);
      navigate(AppRoutePath.KYC_INTRO(), { replace: true });
    }
  }, [error, navigate, t]);

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (event.source === iframeRef.current?.contentWindow) {
        // Schema taken from here:
        // https://github.com/Jumio/implementation-guides/blob/master/netverify/netverify-web-v4.md
        const iframeMessageDataSchema = z.object({
          authorizationToken: z.string().optional(),
          transactionReference: z.string().optional(),
          customerInternalReference: z.string().optional(),
          eventType: z.number().optional(),
          dateTime: z.string().optional(),
          payload: z.object({
            value: z.enum(["loaded", "success", "error"]),
            metainfo: z
              .object({
                code: z.number().optional(),
              })
              .optional(),
          }),
        });
        const parsedResult = iframeMessageDataSchema.parse(JSON.parse(event.data));

        const { value } = parsedResult.payload;

        switch (value) {
          case "loaded":
            setIsLoded(true);
            break;
          case "error":
            const errorCode = parsedResult.payload.metainfo?.code;
            const message =
              localizedJumioErrorCodes[errorCode as JumioErrorCode] || t("kyc.verification.error.unknown");
            notify(message, ToastTypes.error);
            navigate(AppRoutePath.KYC_INTRO(), { replace: true });
            break;
          case "success":
            dispatch(appActions.kycSubmitted());
            navigate(AppRoutePath.KYC_STATUS(), { replace: true });
            break;
        }
      }
    };
    window.addEventListener("message", listener, false);

    return () => window.removeEventListener("message", listener, false);
  }, [setIsLoded, iframeRef, navigate, dispatch, localizedJumioErrorCodes, t]);

  return (
    <>
      {!isLoaded && (
        <div className="mx-auto mb-8 mt-10 max-w-[995px] flex-1">
          <div className="auth-card-padding flex min-h-[556px] flex-col items-center justify-between rounded-2xl bg-white shadow-md">
            <div className="relative h-10">
              <Spinner type={SpinnerTypes.default} />
            </div>
          </div>
        </div>
      )}
      {data && (
        <div className="mx-auto mb-8 mt-10 max-w-[995px] flex-1" style={{ display: isLoaded ? undefined : "none" }}>
          <div className="flex min-h-[556px] flex-col items-center justify-between overflow-hidden rounded-2xl bg-white shadow-md">
            <iframe
              ref={iframeRef}
              title="idv-client"
              src={data.redirectUrl}
              width="100%"
              height="760"
              allow="camera;fullscreen;accelerometer;gyroscope;magnetometer"
              allowFullScreen
              style={{ border: 0 }}
              scrolling="yes"
            />
          </div>
        </div>
      )}
    </>
  );
};
