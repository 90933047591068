import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { AppRoutePath } from "../app/constants";
import DashboardLayout from "./DashboardLayout";

const CardsLayout = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const cardsLinks = useMemo(
    () => [
      {
        children: t("cardsPage.topBar.links.cards"),
        path: AppRoutePath.CARDS(),
        isActive: location.pathname === AppRoutePath.CARDS(),
      },
      {
        children: t("cardsPage.topBar.links.virtualCards"),
        path: AppRoutePath.VIRTUAL_CARDS(),
        isActive: location.pathname === AppRoutePath.VIRTUAL_CARDS(),
      },
      {
        children: t("cardsPage.topBar.links.addVirtualCards"),
        path: AppRoutePath.ADD_VIRTUAL_CARDS(),
        isActive: location.pathname === AppRoutePath.ADD_VIRTUAL_CARDS(),
      },
    ],
    [location, t]
  );
  return <DashboardLayout topBarLinks={cardsLinks} />;
};

export default CardsLayout;
