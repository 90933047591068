import * as yup from "yup";
import { signUpFieldsArrangement } from "./definitions/signUpFieldsArrangement";

/**
 * We need to describe all expected feature tags
 */
export const featureFlagsSchema = yup
  .object()
  .shape({
    membershipAgreement: yup.object().shape({
      enabled: yup.boolean().required(),
      url: yup.string().when("enabled", {
        is: true,
        then: schema => schema.required(),
        otherwise: schema => schema.optional(),
      }),
    }),
    signUpFieldsArrangement: signUpFieldsArrangement(),
  })
  .noUnknown();

export type FeatureFlags = yup.InferType<typeof featureFlagsSchema>;
