interface ICustomPlanCardProps {
  title: string;
  list: string[];
}

const CustomPlanCard = ({ title, list }: ICustomPlanCardProps) => {
  return (

    <div className="relative w-full rounded-[20px] border-gray-200 bg-white p-4 shadow backdrop-blur-xl dark:border-gray-700 dark:bg-gray-800 sm:p-8">
      <h5 className="mb-4 text-4xl font-bold text-black">{title}</h5>
      <ul role="list" className="grid w-full grid-cols-2 gap-4">
        {list.map((desc, index) => (
          <li key={index} className="col-span-1 flex space-x-3">
            <span className="flex content-center">
              <svg className="h-full" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                <g id="Group_760" data-name="Group 760" transform="translate(-512 -300)">
                  <path
                    id="checkmark-outline"
                    d="M12.11,9,8.358,13.288,6.75,11.68"
                    transform="translate(509.57 295.856)"
                    fill="none"
                    stroke="#635cf3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <g
                    id="Ellipse_228"
                    data-name="Ellipse 228"
                    transform="translate(512 300)"
                    fill="none"
                    stroke="#635cf3"
                    strokeWidth="1"
                  >
                    <circle cx="7" cy="7" r="7" stroke="none" />
                    <circle cx="7" cy="7" r="6.5" fill="none" />
                  </g>
                </g>
              </svg>
            </span>
            <span className="text-base font-medium text-gray-900">{desc}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomPlanCard;
