import React, { useState } from "react";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Button from "ui-components/src/components/Button/Button.component";
import { AccountApi } from "../../../api/endpoints/account.api";
import { AccountType, NationalCurrencyIso } from "../../../app/constants";
import { AsyncLoadableArea } from "../../../components/AsyncLoadableArea/AsyncLoadableArea.component";
import { getCurrenciesDescriptors } from "../../../components/CurrencyLabel/getCurrenciesDescriptors";
import { InlineSpinner } from "../../../components/Loading/InlineSpinner.component";

interface AddAccountItemProps {
  currencyCode: NationalCurrencyIso;
  refetch: () => void;
}

export const AddAccountItem: FC<AddAccountItemProps> = ({ currencyCode, refetch }) => {
  const { t } = useTranslation();
  // when true it means account list is updating and this component will be unmounted after update
  const [isResolved, setIsResolved] = useState(false);
  const [addAccount, { data, error, isLoading, isUninitialized }] = AccountApi.useAddAccountMutation();
  const isPressed = !isUninitialized;
  const currency = useMemo(() => getCurrenciesDescriptors(t)[currencyCode], [t, currencyCode]);
  const FlagIcon = currency.icon;
  const Container = isPressed ? "div" : "button";

  const handleAddClick = () => {
    if (!isPressed) {
      addAccount({
        type: AccountType.Wallet,
        currencyCode,
      });
    }
  };

  const handleOkClick = () => {
    setIsResolved(true);
    refetch();
  };

  return (
    <Container
      className={classNames(
        "relative h-[114px] cursor-pointer rounded-2xl border border-black/5 bg-white p-6 text-left transition-all ",
        { "hover:border-black/50 hover:bg-white/30": !isPressed }
      )}
      onClick={handleAddClick}
    >
      {isPressed ? (
        <AsyncLoadableArea
          data={data}
          error={error}
          isLoading={isLoading}
          render={() => (
            <div>
              <h2 className="font-brown-bold text-center text-base text-black">
                {t("accounts.list.addNewAccount.addedSuccessfully", { currencyCode })}
              </h2>
              <Button buttonType="selectButton" className="h-10 w-full" onClick={handleOkClick} disabled={isResolved}>
                {isResolved ? (
                  <>
                    <InlineSpinner />
                    {t("loading")}
                  </>
                ) : (
                  <>{t("ok")}</>
                )}
              </Button>
            </div>
          )}
        />
      ) : (
        <div className="flex flex-row items-center justify-start">
          <FlagIcon className="h-8 w-8" />
          <div className="ml-2">
            <h2 className="font-brown-bold text-base text-black">{currency.label}</h2>
            <h3 className="font-brown-regular text-xs opacity-50">{currency.shortCode}</h3>
          </div>
        </div>
      )}
    </Container>
  );
};
