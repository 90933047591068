import * as yup from "yup";

const errorResponseSchema = yup
  .object()
  .required()
  .shape({
    status: yup.number(),
    data: yup
      .object()
      .required()
      .shape({
        data: yup.object().nullable(),
        error: yup.object().shape({
          code: yup.number(),
          reason: yup.string(),
          message: yup.string(),
        }),
        message: yup.string(),
      }),
  });

export type ErrorResponseBody = yup.InferType<typeof errorResponseSchema>;

export function isErrorResponseValid(error: any): error is ErrorResponseBody {
  return errorResponseSchema.isValidSync(error);
}

export const getServerErrorResponse = (error: any) => {
  return isErrorResponseValid(error) ? error.data.error : undefined;
};
