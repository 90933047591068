import { useTranslation } from "react-i18next";
import { usePaymentCardDraftMutation } from "../../../api/slices/selectPlan.api";
import { Label } from "./Label.component";
import { FC, ReactElement } from "react";
import { InlineSpinner } from "../../../components/Loading/InlineSpinner.component";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import { Plan } from "../../../api/models/plans/plan";

interface AsyncLoadableAreaProps<T> {
  isLoading: boolean;
  data?: T;
  error?: FetchBaseQueryError | SerializedError;
  renderWhenLoaded: (data: T) => ReactElement;
}

const AsyncLoadableArea = <T,>({ isLoading, data, error, renderWhenLoaded }: AsyncLoadableAreaProps<T>) => {
  const { t } = useTranslation();
  if (isLoading) {
    return <InlineSpinner />;
  }

  if (error || !data) {
    return <>{t("n/a")}</>;
  }

  return renderWhenLoaded(data);
};

interface BillProps {
  plan: Plan;
  makePaymentDraftResult: ReturnType<typeof usePaymentCardDraftMutation>[1];
}

// TODO: Fix loader
export const Bill: FC<BillProps> = ({ plan, makePaymentDraftResult: { isLoading, error, data } }) => {
  const { t } = useTranslation();

  const renderPrice = (price: number) => {
    return (
      <div className="text-right">
        <span className="pl-1">{plan.currencySymbol}</span>
        {price.toFixed(2)}
      </div>
    );
  };

  return (
    <>
      <Label>{t("checkout.bill.title")}</Label>
      <div className="mt-6 grid flex-auto grid-cols-2 font-inter text-xs text-black">
        <div>{t("checkout.bill.fields.plan")}</div>
        <div>
          <AsyncLoadableArea
            isLoading={isLoading}
            data={plan}
            error={error}
            renderWhenLoaded={loadedData => renderPrice(loadedData.price)}
          />
        </div>
        <div>{t("checkout.bill.fields.card")}</div>
        <div>
          <AsyncLoadableArea
            isLoading={isLoading}
            data={data}
            error={error}
            renderWhenLoaded={loadedData => renderPrice(loadedData.cardAmount)}
          />
        </div>
        <div>{t("checkout.bill.fields.shipping")}</div>
        <div>
          <AsyncLoadableArea
            isLoading={isLoading}
            data={data}
            error={error}
            renderWhenLoaded={loadedData => renderPrice(loadedData.shippingAmount)}
          />
        </div>
      </div>
      <div className="mt-2 grid flex-auto grid-cols-2 border-t border-primary-blue pt-2 font-brown-bold text-xs text-black">
        <div>{t("checkout.bill.fields.total")}</div>
        <div>
          <AsyncLoadableArea
            isLoading={isLoading}
            data={data}
            error={error}
            renderWhenLoaded={loadedData => renderPrice(loadedData.totalAmount)}
          />
        </div>
      </div>
    </>
  );
};
