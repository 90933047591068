import { useEffect, useMemo } from "react";
import { useGetUserQuery } from "../../../../api/slices/user.api";
import { useGetAccountBalanceQuery, useGetCryptoBalanceQuery } from "../../../../api/slices/userCard.api";
import { useTranslation } from "react-i18next";
import { notify } from "../../../../utils/notify";
import { ToastTypes } from "../../../../utils/util-vars";

export const useUserCard = () => {
  const { t } = useTranslation();
  const { data: userData, isLoading: isUserDataLoading, error: userError } = useGetUserQuery();
  const {
    data: accountBalance,
    isLoading: isAccountBalanceLoading,
    error: accountError,
  } = useGetAccountBalanceQuery("");
  const { data: cryptoBalance, isLoading: isCryptoBalanceLoading, error: cryptoError } = useGetCryptoBalanceQuery("");

  const accountValue = accountBalance?.eWallet.totalBalance;
  const cardsValue = accountBalance?.card.totalBalance;
  const cryptoValue = cryptoBalance?.totalBalance;

  const totalBalance = useMemo(() => {
    if (accountError && cryptoError) {
      return t("userCard.noValue");
    }

    let total = 0;

    if (accountValue) {
      total += accountValue;
    }

    if (cardsValue) {
      total += cardsValue;
    }

    if (cryptoValue) {
      total += cryptoValue;
    }

    return "$" + total.toLocaleString();
  }, [accountValue, cardsValue, cryptoValue, accountError, cryptoError, t]);

  useEffect(() => {
    if (userError) {
      notify(t("userCard.userError"), ToastTypes.error);
    }
  }, [userError, t]);

  useEffect(() => {
    if (cryptoError) {
      notify(t("userCard.cryptoError"), ToastTypes.error);
    }
  }, [cryptoError, t]);

  useEffect(() => {
    if (accountError) {
      notify(t("userCard.accountBalanceError"), ToastTypes.error);
    }
  }, [accountError, t]);

  return {
    isLoading: isAccountBalanceLoading || isCryptoBalanceLoading || isUserDataLoading,
    accountsBalance: accountError ? t("userCard.noValue") : "$" + accountBalance?.eWallet.totalBalance.toLocaleString(),
    cardsBalance: accountError ? t("userCard.noValue") : "$" + accountBalance?.card.totalBalance.toLocaleString(),
    cryptoBalance: cryptoError ? t("userCard.noValue") : "$" + cryptoBalance?.totalBalance.toLocaleString(),
    totalBalance,
    firstName: userData?.firstName || "",
    lastName: userData?.lastName || "",
  };
};
