import { Route } from "react-router-dom";
import { BaseLayout } from "../layouts/BaseLayout";
import { AuthLayout } from "../layouts/AuthLayout";
import { OnboardingLayout } from "../layouts/OnboardingLayout";
import ForgotPassword from "../pages/forgot-password/ForgotPassword.page";
import { SignInPage } from "../pages/sign-in/SignIn.page";
import { SignUpPage } from "../pages/sign-up/SignUp.page";
import { AppRoutePath, UserAuthState } from "./constants";
import { ResetPassword } from "../pages/reset-password/ResetPassword.page";
import { ResetLayout } from "../layouts/ResetLayout";
import brandConfig from "shared-config/brand.config.json";
import { DashboardPage } from "../pages/dashboard/DashboardPage";
import { RequireAuth } from "./guards/auth/RequireAuth";
import { ConfirmMfaPage } from "../pages/confirm-mfa/ConfirmMfa.page";
import { SelectPlanPage } from "../pages/select-plan/SelectPlan.page";
import { CreateCustomPlan } from "../pages/create-custom-plan/CreateCustomPlan";
import { PreMadePlansPage } from "../pages/pre-made-plans/PreMadePlans.page";
import { UpgradePlanPage } from "../pages/upgrade-plan/UpgradePlan.page";
import CardDesignPage from "../pages/card-design-page/CardDesignPage";
import { CheckoutPage } from "../pages/checkout/Checkout.page";
import MobileVerificationPage from "../pages/mobile-verification/MobileVerification.page";
import EmailVerificationPage from "../pages/email-verification/EmailVerification.page";
import { loadCsrfToken } from "./loaders/loadAppData";
import { loadFeatureFlags } from "./loaders/loadFeatureFlags";
import { DisplayRouterStateWrapper } from "../test-utils/DisplayRouterState";
import { AuthRedirect } from "./guards/auth/AuthRedirect";
import DashboardLayout from "../layouts/DashboardLayout";
import CardsPage from "../pages/cards-page/CardsPage";
import { loadMembership } from "./loaders/loadMembership";
import { DeviceConfirmPage } from "../pages/sign-in/device-confirm/DeviceConfirm.page";
import { getKycRoutes } from "../pages/kyc/Kyc.routes";
import { StarterPlanConfirmationPage } from "../pages/starter-plan-confirmation/StarterPlanConfirmation.page";
import { getAccountRoutes } from "../pages/account/account.routes";
import Cards from "../pages/cards-page/Cards";
import VirtualCards from "../pages/cards-page/VirtualCards";
import AddVirtualCards from "../pages/cards-page/AddVirtualCards";
import CardsLayout from "../layouts/CardsLayout";
import AccountsLayout from "../layouts/AccountsLayout";

const getGuestRoutes = () => (
  <Route element={<RequireAuth authLevel={UserAuthState.GUEST} />}>
    <Route element={<AuthLayout />}>
      <Route path={AppRoutePath.SIGN_IN()} element={<SignInPage />} />
      <Route element={<ResetLayout />}>
        <Route path={AppRoutePath.RESET_PASSWORD()} element={<ResetPassword />} />
        <Route path={AppRoutePath.FORGOT_PASSWORD()} element={<ForgotPassword />} />
      </Route>
    </Route>
    <Route element={<OnboardingLayout withHeaderMenu={true} />}>
      {brandConfig.features.registration.enabled && <Route path={AppRoutePath.SIGN_UP()} element={<SignUpPage />} />}
    </Route>
  </Route>
);

const getOnboardingRoutes = () => (
  <Route element={<RequireAuth authLevel={UserAuthState.ONBOARDING_REQUIRED} />}>
    <Route path={AppRoutePath.ONBOARDING()} element={<OnboardingLayout withHeaderMenu={true} />}>
      <Route index element={<SelectPlanPage />} />
      <Route path={AppRoutePath.CREATE_CUSTOM_PLAN()} element={<CreateCustomPlan />} />
      <Route path={AppRoutePath.CREATE_PRE_MADE_PLAN()} element={<PreMadePlansPage />} />
      <Route path={AppRoutePath.STARTER_PLAN_CONFIRMATION()} element={<StarterPlanConfirmationPage />} />
      <Route path={AppRoutePath.UPGRADE_PLAN()} element={<UpgradePlanPage />} />
    </Route>
    <Route element={<OnboardingLayout withHeaderMenu={true} />}>
      <Route path={AppRoutePath.CARD_DESIGN()} element={<CardDesignPage />} />
      <Route path={AppRoutePath.CHECKOUT()} element={<CheckoutPage />} />
    </Route>
  </Route>
);

const getMfaRoutes = () => (
  <Route element={<OnboardingLayout withHeaderMenu={false} />}>
    <Route element={<RequireAuth authLevel={UserAuthState.MFA_SETUP_REQUIRED} />}>
      <Route path={AppRoutePath.MFA_SETUP()} index element={<ConfirmMfaPage />} />
    </Route>
    <Route element={<RequireAuth authLevel={UserAuthState.MFA_REQUIRED} />}>
      <Route path={AppRoutePath.CONFIRM_MFA()} element={<DeviceConfirmPage />} />
    </Route>
  </Route>
);

const getVerificationRoutes = () => (
  <Route element={<RequireAuth authLevel={UserAuthState.VERIFICATION_REQUIRED} />}>
    <Route element={<AuthLayout />}>
      <Route path={AppRoutePath.MOBILE_VERIFICATION()} element={<MobileVerificationPage />} />
      <Route path={AppRoutePath.EMAIL_VERIFICATION()} element={<EmailVerificationPage />} />
    </Route>
  </Route>
);

export const getRouteElements = () => {
  return (
    <Route
      path={AppRoutePath.ROOT()}
      loader={() => Promise.all([loadCsrfToken(), loadFeatureFlags()])}
      element={process.env.NODE_ENV === "test" ? <DisplayRouterStateWrapper /> : undefined}
    >
      <Route index element={<AuthRedirect />} />
      <Route element={<BaseLayout />}>
        {getGuestRoutes()}
        {getOnboardingRoutes()}
        {getVerificationRoutes()}
        {getMfaRoutes()}
        {getKycRoutes()}
        <Route element={<RequireAuth authLevel={UserAuthState.SIGNED_IN} />}>
          <Route loader={loadMembership} element={<DashboardLayout />}>
            <Route path={AppRoutePath.DASHBOARD()} element={<DashboardPage />} />
          </Route>
          <Route loader={loadMembership} element={<CardsLayout />}>
            <Route path={AppRoutePath.CARDS()} element={<CardsPage />}>
              <Route index element={<Cards />} />
              <Route path={AppRoutePath.VIRTUAL_CARDS()} element={<VirtualCards />} />
              <Route path={AppRoutePath.ADD_VIRTUAL_CARDS()} element={<AddVirtualCards />} />
            </Route>
          </Route>
          <Route loader={loadMembership} element={<AccountsLayout />}>
            {getAccountRoutes()}
          </Route>
        </Route>
      </Route>
    </Route>
  );
};
