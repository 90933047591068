import { createApi } from "@reduxjs/toolkit/query/react";
import { UserCardEndpoints } from "../enums/user-card-endpoints";
import { commonResponseSchema } from "../models/responses/common-response";
import { accountBalanceResponseSchema } from "../models/userCard/account";
import { cryptoBalanceResponseSchema } from "../models/userCard/crypto";
import { baseQueryProtected } from "../query-fn/protected";

export const UserCardApi = createApi({
  reducerPath: "userCard",
  baseQuery: baseQueryProtected,
  endpoints: builder => ({
    getAccountBalance: builder.query({
      query: () => ({
        url: UserCardEndpoints.AccountBalance,
        method: "GET",
      }),
      transformResponse: response => {
        return commonResponseSchema(accountBalanceResponseSchema()).parse(response).data;
      },
    }),
    getCryptoBalance: builder.query({
      query: () => ({
        url: UserCardEndpoints.CryptoBalance,
        method: "GET",
      }),
      transformResponse: response => {
        return commonResponseSchema(cryptoBalanceResponseSchema()).parse(response).data;
      },
    }),
  }),
});

export const { useGetAccountBalanceQuery, useGetCryptoBalanceQuery } = UserCardApi;
