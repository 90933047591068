import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppState {
  csrfToken: string | null; // used for access to public endpoints
  accessToken: string | null; // used for access to protected endpoints
  confirmToken: string | null; // used in some cases for mfa
  kycSubmitted: boolean;
  kycApproved: boolean;
}

const initialState: AppState = {
  csrfToken: null,
  accessToken: null,
  confirmToken: null,
  kycSubmitted: false,
  kycApproved: false,
};

export const AppSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setCsrfToken: (state, action: PayloadAction<{ csrfToken: string }>) => {
      state.csrfToken = action.payload.csrfToken;
    },
    signIn: (state, action: PayloadAction<{ accessToken: string; confirmToken?: string }>) => {
      state.accessToken = action.payload.accessToken;
      if (action.payload.confirmToken) {
        state.confirmToken = action.payload.confirmToken;
      }
    },
    signInNextActionRequired: (state, action: PayloadAction<{ accessToken: string; confirmToken?: string }>) => {
      state.accessToken = action.payload.accessToken;
      if (action.payload.confirmToken) {
        state.confirmToken = action.payload.confirmToken;
      }
    },
    signUp: (state, action: PayloadAction<{ accessToken: string }>) => {
      state.accessToken = action.payload.accessToken;
    },
    updateAccessToken: (state, action: PayloadAction<{ accessToken: string }>) => {
      state.accessToken = action.payload.accessToken;
    },
    kycSubmitted: state => {
      state.kycSubmitted = true;
    },
    kycApproved: state => {
      state.kycApproved = true;
    },
    logout: state => {
      state.accessToken = null;
    },
  },
});

export const appActions = AppSlice.actions;
