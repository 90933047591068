import React, { FC, useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { CardExpiryElement, CardCvcElement, CardNumberElement } from "@stripe/react-stripe-js";
import Button from "ui-components/src/components/Button/Button.component";
import { useTranslation } from "react-i18next";
import { useCreateCardPaymentMutation } from "../../api/slices/payment.api";
import { useGetUserQuery } from "../../api/slices/user.api";
import "./Payment.css";
import { ToastTypes } from "../../utils/util-vars";
import { notify } from "../../utils/notify";
import { useAppSelector } from "../../store/store";
import { InlineSpinner } from "../Loading/InlineSpinner.component";
import { getErrorResponseCode, getErrorResponseMessage } from "../../api/models/responses/common-response";
import { ApiErrorCode } from "../../api/error-codes";

const cssClasses = {
  inputClasses:
    "cursor-pointer bg-white py-5 px-6 ring-gray-200/20 rounded-[18px] transition-all h-14 text-right content-end justify-end",
  labelClasses: "font-brows text-xs text-black/50 uppercase",
};

interface PaymentProps {
  nextStep: (transactionId: string) => void;
}

const Payment: FC<PaymentProps> = ({ nextStep }) => {
  const onboardingState = useAppSelector(state => state.onboarding);
  const stripe = useStripe();
  const elements = useElements();
  const [createCardPayment, { data: createdCardPayment, error: paymentError }] = useCreateCardPaymentMutation();
  // we need to have own submitting state because we want to combine few async operations as one
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const { data: userData } = useGetUserQuery();
  const { inputClasses, labelClasses } = cssClasses;

  useEffect(() => {
    if (createdCardPayment && stripe) {
      if (String(createdCardPayment.paymentStatus).toLowerCase() === "requires_action") {
        const confirmation = async () => await stripe.confirmCardPayment(createdCardPayment.clientSecret);
        confirmation()
          .then(() => nextStep(createdCardPayment.transactionId))
          .catch(e => notify(`Error on 2FA - ${e.message}`, ToastTypes.error));
      } else {
        console.log("Payment failed", createdCardPayment);
      }
    }
  }, [createdCardPayment, nextStep, stripe]);

  useEffect(() => {
    if (paymentError) {
      const errorCode = getErrorResponseCode(paymentError);
      const defaultMessage = t("paymentConfirmation.error.description");
      const errorMessage =
        errorCode === ApiErrorCode.PaymentFailed
          ? getErrorResponseMessage(paymentError) || defaultMessage
          : defaultMessage;

      notify(errorMessage, ToastTypes.error);
    }
  }, [paymentError, setIsSubmitting, t]);

  // @ts-ignore
  const handleSubmit = async event => {
    event.preventDefault();
    if (userData && stripe && elements) {
      setIsSubmitting(true);
      try {
        // @ts-ignore
        const { error, token } = await stripe.createToken(elements.getElement(CardNumberElement), {
          name: [userData.latinFirstName, userData.latinLastName].join(" "),
          address_line1: userData.addressLine1 || "",
          address_line2: userData.addressLine2 || "",
          address_city: userData.city || "",
          address_state: userData.state || "",
          address_zip: userData.postCode || "",
          address_country: userData.countryCode || "",
        });
        // @ts-ignore

        if (error) {
          // @ts-ignore
          notify(error.message, ToastTypes.error);
          setIsSubmitting(false);
        } else {
          const request = {
            planId: onboardingState.selectedPlanId,
            productRef: onboardingState.selectedProductRef,
            cardDesign: onboardingState.selectedCardDesign,
            debitAmount: onboardingState.selectedDebitAmount,
            shipping: onboardingState.selectedShipping,
            currency: "USD",
            paymentReference: "STRIPE",
            paymentType: "SUBSCRIPTION",
            paymentChannel: "CARD",
            token: token.id,
          };
          await createCardPayment(request);
          setIsSubmitting(false);
        }
      } catch {
        setIsSubmitting(false);
      }
    }
  };
  return (
    <>
      {userData ? (
        <div className="w-full">
          <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-8">
            <h2 className="col-span-2 font-brown-bold text-2xl">{t("paymentForm.title")}</h2>
            <div className="col-span-2">
              <label className={labelClasses}>
                {t("paymentForm.cardNumber.label")}
                <CardNumberElement className={inputClasses} />
              </label>
            </div>
            <div className="col-span-1">
              <label className={labelClasses}>
                {t("paymentForm.expireDate.label")}
                <CardExpiryElement className={inputClasses} />
              </label>
            </div>
            <div className="col-span-1">
              <label className={labelClasses}>
                {t("paymentForm.cvcNumber.label")}
                <CardCvcElement className={inputClasses} />
              </label>
            </div>
            <Button
              buttonType="selectButton"
              type="submit"
              className="col-span-2 bg-blue-700"
              disabled={!stripe || isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <InlineSpinner />
                  {t("forgotPassword.fields.submitButton.loading")}
                </>
              ) : (
                <>{t("paymentForm.submit.title")}</>
              )}
            </Button>
          </form>
        </div>
      ) : null}
    </>
  );
};

export default Payment;
