import { LDClient } from "launchdarkly-js-client-sdk";

let _client: LDClient;

export const setLDClient = (client: LDClient) => {
  _client = client;
};

export const getLDClient = () => _client;

export let getLaunchDarklyId = () => {
  if (!process.env.REACT_APP_LAUNCH_DARKLY_ID) {
    throw new Error(`"REACT_APP_LAUNCH_DARKLY_ID" environment variable is not provided`);
  }

  return process.env.REACT_APP_LAUNCH_DARKLY_ID;
};

/**
 * We need custom flag to enable some devtools on staging or other test envs,
 * but disable on real production
 */
if (process.env.REACT_APP_DEVTOOLS) {
  const LAUNCH_DARKLY_ID_KEY = "launchDarklyId";
  const defaultIdResolver = getLaunchDarklyId;

  getLaunchDarklyId = () => {
    return localStorage.getItem(LAUNCH_DARKLY_ID_KEY) || defaultIdResolver();
  };

  Object.assign(window, {
    setLaunchDarklyId: (newId: string) => {
      localStorage.setItem(LAUNCH_DARKLY_ID_KEY, newId);
      document.location.reload();
    },
  });
}
