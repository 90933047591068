import { z } from "zod";
import { TransactionStatuses } from "../../../app/constants";

const transactionSchema = () =>
  z.object({
    transactionDate: z.string().or(z.date()),
    debitCredit: z.string(),
    amount: z.number(),
    currencyCode: z.nativeEnum(TransactionStatuses),
  });

export const transactionsResponseSchema = () => z.array(transactionSchema());

export type Transaction = z.infer<ReturnType<typeof transactionSchema>>;
export type TransactionsResponse = z.infer<ReturnType<typeof transactionsResponseSchema>>;
export type TransactionsRequest = { limit?: number };
