import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { Form } from "../../components/Form/Form.component";
import { useConfirmEmailMutation } from "../../api/slices/auth.api";
import { notify } from "../../utils/notify";
import { TextField } from "ui-components/src/components/Input/Input.field";
import { TFunction } from "i18next";
import { InlineSpinner } from "../../components/Loading/InlineSpinner.component";
import Button from "ui-components/src/components/Button/Button.component";
import { appActions } from "../../store/slices/app.slice";

interface ResetFormValues {
  email: string;
}

// @ts-ignore
const resetSchema = (t: TFunction): yup.InferType<ReturnType<typeof resetSchema>> =>
  yup.object().shape({
    // @ts-ignore
    email: yup.string().email(t("signIn.form.fields.email.error")).required(t("signIn.form.fields.email.required")),
  });

const ResetForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [confirmEmail, { data: confirmEmailData, error: confirmEmailError, isLoading }] = useConfirmEmailMutation();

  const classes = {
    title: "text-2xl font-bold mb-4 font-brown-bold",
    fieldset: "flex flex-col justify-between gap-3 font-brown-regular",
  };

  const methods = useForm<ResetFormValues>({
    resolver: yupResolver(resetSchema(t)),
    mode: "onBlur",
  });

  const onSubmitForm = async (data: ResetFormValues) => {
    await confirmEmail(data);
    if (confirmEmailError) {
      methods.setError("email", {
        type: "string",
        message: t("forgotPassword.validation.error.text") as string,
      });
    }
    if (confirmEmailData) {
      dispatch(appActions.logout);
      notify(t("forgotPassword.message.text"));
    }
  };

  return (
    <div className="mx-auto mb-8 mt-10 max-w-[520px] flex-1">
      {!confirmEmailData ? (
        <Form<ResetFormValues> {...methods} onSubmit={onSubmitForm}>
          <div className="pb-14 text-center">
            <h1 className={classes.title}>{t("forgotPassword.fields.title.text")}</h1>
            <p className="mt-8">{t("forgotPassword.fields.subtitle.text")}</p>
          </div>
          <div className={classes.fieldset + " relative"}>
            <TextField type="email" name="email" id="email" label={t("Email")} required />
          </div>
          <div className="my-6 flex items-center justify-center">
            <Button disabled={isLoading} data-testid="forgotPassword-button">
              {isLoading ? (
                <>
                  <InlineSpinner />
                  {t("forgotPassword.fields.submitButton.loading")}
                </>
              ) : (
                <>{t("forgotPassword.fields.submitButton.text")}</>
              )}
            </Button>
          </div>
          <div>
            <p className="text-center">
              <Link to="/forgot-email" className="font-semibold">
                {t("forgotPassword.forgotEmail.link.text")}
              </Link>
            </p>
          </div>
        </Form>
      ) : (
        <div className="pb-14 text-center">
          <h1 className={classes.title}>{t("forgotPassword.success.title.text")}</h1>
          <p className="mt-8">{t("forgotPassword.success.subtitle.text")}</p>
        </div>
      )}
    </div>
  );
};

export default ResetForm;
