import { z } from "zod";
import { commonResponseSchema } from "../responses/common-response";

export const membershipSubscriptionResponseSchema = () =>
  z.object({
    planId: z.string(),
    nextBillingOn: z.unknown(),
    status: z.number(),
    preferredCardProductRef: z.string(),
    planFrequency: z.string(),
    reference: z.string(),
    planCycle: z.number(),
    skipCardPurchase: z.boolean(),
    lastBilledOn: z.string(),
    skipUpgrade: z.boolean(),
    id: z.string(),
    paymentAccount: z.string(),
    modifiedAt: z.string(),
    subscriptionId: z.string(),
    preferredCardCardDesign: z.string(),
    createdAt: z.string(),
    statusText: z.string(),
    subStatusText: z.string(),
    contactId: z.string(),
    planName: z.string(),
    subStatus: z.number(),
    planPrice: z.number(),
    currencyId: z.string(),
    currencyCode: z.string(),
    activatedOn: z.string(),
    paymentMethod: z.string(),
    nextBillingAmount: z.number(),
    price: z.number(),
    planCode: z.string(),
  });

const membershipSubscriptionCommonResponseSchema = () => commonResponseSchema(membershipSubscriptionResponseSchema());

export type MembershipSubscriptionResponse = z.infer<ReturnType<typeof membershipSubscriptionResponseSchema>>;
export type MembershipSubscriptionСommonResponse = z.infer<
  ReturnType<typeof membershipSubscriptionCommonResponseSchema>
>;
