import React, { FC, PropsWithChildren } from "react";
import classNames from "classnames";

interface CardProps {
  className?: string;
}

export const Card: FC<PropsWithChildren<CardProps>> = ({ className, children }) => (
  <div className={classNames("bg-white shadow-md rounded-2xl", className)}>{children}</div>
);
