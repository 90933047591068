export type EqualityFn<TFunc extends (...args: any[]) => Promise<any>> = (
  newArgs: Parameters<TFunc>,
  lastArgs: Parameters<TFunc>
) => boolean;

/**
 * This function helps to avoid extra API calls in cases where server-side validation is used
 * You can refer to `useSignUpValidationSchema` to see how it is used
 * Originally, the code was taken from here:
 * https://github.com/microlinkhq/async-memoize-one/blob/master/index.js
 * The code wasn't added as library because of:
 * 1. no typescript support in the original code
 * 2. it's not a super-popular library and including it's as a library
 *    will increase possibility for instability of dependencies
 * 3. the function is straitforward and the changes are not expected,
 *    lets do not repeat left-pad problem :)
 *
 * @param fn - async function to memoize
 * @param isEqual - equality function, compares newArgs and oldArgs, if true - memoized value will be used
 * @param options - options.cachePromiseRejection defines to we need to cache promise rejections
 * @returns {TFunc} memoized wrapper around original function
 */
export const asyncMemoizeOne = <TFunc extends (...args: any[]) => Promise<any>>(
  fn: TFunc,
  isEqual: EqualityFn<TFunc>,
  { cachePromiseRejection = false } = {}
) => {
  if (!fn) throw new TypeError("You have to provide a `fn` function.");

  let calledOnce = false;
  let oldArgs: Parameters<TFunc>;
  let lastResult: Promise<any>;

  return async (...newArgs: Parameters<TFunc>) => {
    if (calledOnce && isEqual(newArgs, oldArgs)) return lastResult;

    lastResult = fn(...newArgs);

    if (!cachePromiseRejection && lastResult.catch) {
      lastResult.catch(() => (calledOnce = false));
    }

    calledOnce = true;
    oldArgs = newArgs;

    return lastResult;
  };
};
