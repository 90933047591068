import { z } from "zod";

export const paymentCardResponseSchema = () =>
  z.object({
    // should be options
    paymentStatus: z.string(),
    clientSecret: z.string(),
    transactionId: z.string(),
  });

export type paymentCardResponse = z.infer<ReturnType<typeof paymentCardResponseSchema>>;
