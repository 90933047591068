import { z } from "zod";

export const userSchema = () =>
  z.object({
    mobileValid: z.boolean(),
    memberCode: z.string(),
    lastName: z.string(),
    reMarketingId: z.string().nullable(),
    isTermsAgreed: z.boolean(),
    firstName: z.string(),
    doNotFax: z.boolean(),
    state: z.string().nullable(),
    city: z.string(),
    doNotPostalMail: z.boolean(),
    externalId: z.string().nullable(),
    doNotEmail: z.boolean(),
    emailAddress: z.string().optional(),
    nickname: z.string().nullable().optional(),
    mobileVerified: z.boolean(),
    ledgerAllowed: z.boolean(),
    addressLine1: z.string(),
    addressLine2: z.string().nullable().optional(),
    province: z.string().nullable(),
    dateOfBirth: z.string(),
    mobileNumber: z.string(),
    twoFaMethod: z.string().nullable(),
    userStatus: z.string(),
    partnerId: z.string(),
    countryCode: z.string(),
    preferredDisplayCurrencyId: z.string().nullable(),
    created: z.string(),
    referralCode: z.string().nullable(),
    csSessionId: z.string().nullable(),
    ssn: z.string().nullable(),
    emailVerified: z.boolean(),
    latinFirstName: z.string().optional().nullable(),
    pinSet: z.boolean(),
    contactId: z.string().optional(),
    latinLastName: z.string().optional().nullable(),
    referrerBy: z.string().nullable(),
    preferredCardCurrency: z.string().nullable(),
    postCode: z.string(),
    preferredDisplayLanguage: z.string(),
    doNotPhone: z.boolean(),
    gdprOptOut: z.boolean(),
    affiliateReferralCode: z.string().nullable().optional(),
    preferredJoiningPlanId: z.string().nullable().optional(),
  });

export type User = z.infer<ReturnType<typeof userSchema>>;
