import { useMemo } from "react";
import { AccountApi } from "../../../api/endpoints/account.api";
import { MembershipApi } from "../../../api/endpoints/membership.api";
import { PlansApi } from "../../../api/endpoints/plans.api";
import { AsyncLoadableArea } from "../../../components/AsyncLoadableArea/AsyncLoadableArea.component";
import { AddAccountItem } from "./AddAccountItem.component";

export const AddAccountPage = () => {
  const {
    data: membershipData,
    error: membershipError,
    isLoading: isMembershipLoading,
  } = MembershipApi.useGetMembershipQuery("");
  // prettier-ignore
  const {
    data: plansData,
    error: plansError,
    isLoading: isPlansLoading,
  } = PlansApi.useGetPlansQuery("");
  const {
    data: accountsData,
    error: accountsError,
    isLoading: isAccountsLoading,
    refetch,
  } = AccountApi.useGetAccountListQuery("");

  const isLoading = isMembershipLoading || isAccountsLoading || isPlansLoading;

  const data = useMemo(() => {
    if (!membershipData || !plansData || !accountsData) {
      return undefined;
    }

    const activePlan = plansData.data.find(itemPlan => itemPlan.id === membershipData.planId);
    if (!activePlan) {
      throw new Error(`Couldn't find plan from membership`);
    }

    const enabledCurrencies = activePlan.currencies.wallet.enabled;
    const activatedCurrencies = accountsData.map(itemAccount => itemAccount.currencyCode);
    const availableCurrencies = enabledCurrencies.filter(itemCurrency => !activatedCurrencies.includes(itemCurrency));

    return availableCurrencies;
  }, [membershipData, plansData, accountsData]);

  const error = useMemo(() => {
    const errorList = [membershipError, plansError, accountsError].filter(Boolean);

    return errorList.length === 0 ? undefined : new AggregateError(errorList);
  }, [membershipError, plansError, accountsError]);

  return (
    <AsyncLoadableArea
      data={data}
      error={error}
      isLoading={isLoading}
      render={currencyList => (
        <div className="grid auto-rows-fr grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
          {currencyList.map(itemCurrency => (
            <AddAccountItem key={itemCurrency} currencyCode={itemCurrency} refetch={refetch} />
          ))}
        </div>
      )}
    />
  );
};
