import React, { FC, HTMLProps, forwardRef } from "react";
import classNames from "classnames";

export type TextInputProps = Omit<HTMLProps<HTMLInputElement>, "label" | "ref">;

export const BaseTextInput: FC<TextInputProps> = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => (
  // @ts-ignore
  <input
    {...props}
    ref={ref}
    className={classNames("inputfield-control peer", props.className, props.className)}
    placeholder={props.placeholder || " "}
  />
));
