import { z } from "zod";

export const commonResponseSchema = <T extends z.ZodTypeAny>(dataSchema: T) =>
  z.object({
    status: z.string().optional(),
    data: dataSchema,
    error: z
      .object({
        code: z.number().optional(),
        reason: z.string().optional(),
        message: z.string().optional(),
      })
      .optional(),
    // TODO: there is inconsistant type on BE,
    // not sure we need to use it: sometimes it's object, sometime string
    // message: z.string().nullable().optional(),
  });

const errorResponseWrapperSchema = () =>
  z.object({
    status: z.number(),
    data: commonResponseSchema(z.unknown()),
  });

export const getErrorResponseCode = (error: unknown) => {
  const parseResult = errorResponseWrapperSchema().safeParse(error);

  return (parseResult.success && parseResult.data.data.error?.code) || null;
};

export const getErrorResponseMessage = (error: unknown) => {
  const parseResult = errorResponseWrapperSchema().safeParse(error);

  return (parseResult.success && parseResult.data.data.error?.message) || null;
};
