import { TransactionStatuses } from "../../../app/constants";
import { Transaction } from "../../models/transactions/transactions";

export const generateTransaction = (idx: number): Transaction => ({
  amount: idx + 1,
  debitCredit: "sent",
  transactionDate: new Date().toLocaleDateString(),
  currencyCode: idx % 2 === 0 ? TransactionStatuses.success : TransactionStatuses.unconfirmed,
});

export const generateTransactions = (count: number): Transaction[] =>
  Array.from({ length: count }, (_, idx) => generateTransaction(idx));

export const TRANSACTIONS = generateTransactions(50);
export const DEFAULT_TABLE_LIMIT = 10;
