import React, { useCallback, useEffect, useState } from "react";
import { usePaymentCardDraftMutation } from "../../api/slices/selectPlan.api";
import { useAppSelector } from "../../store/store";
import { Bill } from "./components/Bill.component";
import { WalletList } from "./components/WalletList.component";
import { PaymentMethods } from "./components/PaymentMethods.component";
import { MakePayment } from "./components/MakePayment.component";
import { ApplyDiscount } from "./components/ApplyDiscount.component";
import { useLazyGetImageQuery } from "../../api/slices/image.api";
import { PlansApi } from "../../api/endpoints/plans.api";

export const CheckoutPage = () => {
  const { data: dataPlans } = PlansApi.useGetPlansQuery("");
  const [getCardImage, { data: cardDesignImage }] = useLazyGetImageQuery();
  const selectedPlanId = useAppSelector(state => state.onboarding.selectedPlanId);
  const selectedPlan = (dataPlans?.data || []).find(itemPlan => itemPlan.id === selectedPlanId);
  const onboardingState = useAppSelector(state => state.onboarding);
  const [fetchPaymentDraft, makePaymentDraftResult] = usePaymentCardDraftMutation();
  const [isDiscountFormDirty, setIsDiscountFormDirty] = useState(true);

  const requestPaymentDraft = useCallback(
    async (discountCode?: string) => {
      if (!selectedPlan?.id) {
        return;
      }

      await fetchPaymentDraft({
        planId: selectedPlan?.id,
        preferredCardCurrency: onboardingState.selectedCurrency,
        productRef: onboardingState.selectedProductRef,
        cardDesign: onboardingState.selectedCardDesign,
        shipping: onboardingState.selectedShipping,
        paymentReference: "SUBSCRIPTION",
        discountCode,
      });
    },
    [
      fetchPaymentDraft,
      selectedPlan?.id,
      onboardingState.selectedCurrency,
      onboardingState.selectedProductRef,
      onboardingState.selectedCardDesign,
      onboardingState.selectedShipping,
    ]
  );

  useEffect(() => {
    requestPaymentDraft();
  }, [requestPaymentDraft]);

  useEffect(() => {
    if (onboardingState.selectedCardImageUrl) {
      getCardImage(onboardingState.selectedCardImageUrl);
    }
  }, [getCardImage, onboardingState]);

  if (!selectedPlan) {
    return null;
  }

  return (
    <>
      <div className="mx-auto flex w-3/4 py-24">
        <div className="flex w-full flex-row rounded-[20px] border-gray-200 bg-white/80 px-14 py-8 shadow backdrop-blur-xl dark:border-gray-700 dark:bg-gray-800">
          <div className="grow-0">
            {onboardingState.selectedCardImageUrl && (
              <img className="w-full px-4" src={cardDesignImage} alt="card design" />
            )}
          </div>
          <div className="ml-auto grow-0">
            <WalletList plan={selectedPlan} />
            {isDiscountFormDirty && <>IsDirty</>}
          </div>
          <div className="ml-auto">
            <Bill plan={selectedPlan} makePaymentDraftResult={makePaymentDraftResult} />
            <ApplyDiscount
              plan={selectedPlan}
              setIsDiscountFormDirty={setIsDiscountFormDirty}
              refetchBill={requestPaymentDraft}
            />
            <PaymentMethods />
          </div>
        </div>
      </div>
      <MakePayment />
    </>
  );
};
