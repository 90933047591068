import classNames from "classnames";
import { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

interface NavLinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  path: string;
  isActive: boolean;
  isDisabled?: boolean;
  isHidden?: boolean;
}

const NavLink: FC<PropsWithChildren<NavLinkProps>> = ({ path, isActive, isHidden, children, isDisabled, ...props }) => {
  if (isHidden) return null;

  return (
    <Link
      {...props}
      to={path}
      className={classNames(
        "inline-block items-center rounded-xl py-2.5 px-2.5 sm:flex sm:py-[14px] sm:px-5  text-sm hover:bg-[#635CF333] mb-[1px] text-[#6C6C6C] sm:gap-4",
        {
          "font-bold text-[#000] bg-[#635CF333]": isActive,
        }
      )}
    >
      {children}
    </Link>
  );
};

export default NavLink;
