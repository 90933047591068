import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useLoginMutation } from "../../api/slices/auth.api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { notify } from "../../utils/notify";
import { ToastTypes } from "../../utils/util-vars";
import { Form } from "../../components/Form/Form.component";
import { CheckboxField } from "ui-components/src/components/Checkbox/Checkbox.field";
import { EyeIcon } from "ui-components/src/components/icons/icons";
import { getServerErrorResponse } from "../../api/error-handling";
import { TextField } from "ui-components/src/components/Input/Input.field";
import { InlineSpinner } from "../../components/Loading/InlineSpinner.component";
import brandConfig from "shared-config/brand.config.json";
import Button from "ui-components/src/components/Button/Button.component";
import { appActions } from "../../store/slices/app.slice";
import { isNextActionRequired } from "../../api/models/sign-in";
import { APP_TEST_IDS } from "../../test-utils/app-test-ids";

interface SignInFormValues {
  email: string;
  password: string;
}

export const SignInPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [login, { isLoading, data, error }] = useLoginMutation();
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const classes = {
    title: "text-2xl font-bold mb-4 font-brown-bold",
    fieldset: "flex flex-col justify-between gap-3 inter-regular",
  };

  const methods = useForm<SignInFormValues>({
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string()
          .email(t("signIn.form.fields.email.error") || "")
          .required(t("signIn.form.fields.email.required") || ""),
        password: yup
          .string()
          .min(8, t("signIn.form.fields.password.error") || "")
          .required(t("signIn.form.fields.password.required") || ""),
      })
    ),
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const onSubmitForm = async (data: SignInFormValues) => {
    await login(data);
  };

  useEffect(() => {
    if (error) {
      if (isNextActionRequired(error)) {
        dispatch(
          appActions.signInNextActionRequired({
            accessToken: error.data.data.accessToken.token,
            confirmToken: error.data.data.confirmToken?.token,
          })
        );
      } else {
        // @ts-ignore
        notify(getServerErrorResponse(error)?.message, ToastTypes.error);
      }
    }
  }, [dispatch, error]);

  useEffect(() => {
    if (data) {
      dispatch(
        appActions.signIn({ accessToken: data.data.accessToken.token, confirmToken: data.data.accessToken.token })
      );
    }
  }, [data, dispatch]);

  return (
    <>
      <div className="mx-auto mb-8 mt-10 max-w-[520px] flex-1">
        <div className="auth-card-padding flex flex-col rounded-2xl bg-white shadow-md">
          <Form<SignInFormValues> {...methods} onSubmit={onSubmitForm}>
            <div className="card-title flex justify-center">
              <h1 className={classes.title} data-testid={APP_TEST_IDS.signIn.title()}>
                {t("signIn.title")}
              </h1>
            </div>
            <div className="mb-6">
              <div className={classes.fieldset}>
                <TextField type="email" name="email" id="email" label={t("signIn.form.fields.email.label")} required />
              </div>
            </div>
            <div className="mb-6">
              <div className={classes.fieldset + " relative"}>
                <TextField
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  label={t("signIn.form.fields.password.label")}
                  required
                />
                <EyeIcon
                  data-testid="password-toggle-icon"
                  onClick={togglePassword}
                  className="absolute right-0 top-3 mr-3 h-5 w-5 cursor-pointer"
                />
              </div>
            </div>
            <div className="mb-6 flex justify-between text-sm">
              <CheckboxField
                id="remember_me"
                name="remember_me"
                label={<Trans i18nKey="signIn.form.fields.rememberMe.label" />}
              />
              <Link className="text-primary-blue" to="/forgot-password">
                {t("signIn.form.labels.forgotPassword")}
              </Link>
            </div>
            <div className="mb-6 flex items-center justify-center">
              <Button type="submit" data-testid="signIn-button" disabled={isLoading}>
                {methods.formState.isSubmitting ? (
                  <>
                    <InlineSpinner />
                    {t("signIn.form.submitButton.loading")}
                  </>
                ) : (
                  <>{t("signIn.form.submitButton.text")}</>
                )}
              </Button>
            </div>
            {brandConfig.features.registration.enabled && (
              <div>
                <p className="text-center">
                  {t("signIn.form.labels.dontHaveAccount")}&nbsp;
                  <Link to="/sign-up" className="font-brown-bold font-semibold">
                    {t("signIn.form.labels.signUp")}
                  </Link>
                </p>
              </div>
            )}
          </Form>
        </div>
      </div>
    </>
  );
};
