import { FC, forwardRef, HTMLProps } from "react";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";

type SmoothTextInputProps = Omit<HTMLProps<HTMLInputElement>, "label" | "ref">;
export const SmoothTextInput = forwardRef<HTMLInputElement, SmoothTextInputProps>((props, ref) => (
  // @ts-ignore
  <input
    ref={ref}
    {...props}
    className={classNames(
      "rounded-2xl text-xs font-inter-regular px-6 py-4 border-2 border-gray-200/20",
      props.className
    )}
  />
));

type SmoothTextFieldProps = Omit<HTMLProps<HTMLInputElement>, "label" | "name" | "value" | "onChange"> & {
  name: string;
};

export const SmoothTextField: FC<SmoothTextFieldProps> = ({ name, ...props }) => {
  const { register, formState } = useFormContext();
  const inputProps = register(name);
  const errorText = formState.errors[name]?.message?.toString();
  const isError = Boolean(errorText);
  const isDisabled = formState.isSubmitting || props.disabled;

  return (
    <div className="inline-block" style={{ display: "inline-block" }}>
      <SmoothTextInput {...props} {...inputProps} disabled={isDisabled} />
      {isError && <p className="inputfield-helper-text">{errorText}</p>}
    </div>
  );
};
