import { z } from "zod";
import { currencyCodeSchema } from "../atoms/currency-code";

export const paymentDraftSchema = () =>
  z.object({
    currencyISO: currencyCodeSchema(),
    rate: z.number(),
    totalAmount: z.number(),
    cardAmount: z.number(),
    shippingAmount: z.number(),
    discountedAmount: z.number(),
  });

export type PaymentDraft = z.infer<ReturnType<typeof paymentDraftSchema>>;
