import { useTable, Column, Cell, Row } from "react-table";
import classNames from "classnames";

interface ReactTableProps<T extends object> {
  data: T[];
  columns: Column<T>[];
  className?: string;
  rowsClassName?: string;
}

const Table = <T extends object>({ columns, data, className, rowsClassName }: ReactTableProps<T>) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <div {...getTableProps()} className={classNames("w-full", className)}>
      <div className="pb-4">
        {headerGroups.map((headerGroup: any) => (
          <div
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className={`grid ${
              columns.length ? `grid-cols-${columns.length}` : "auto-cols-max"
            }  font-bold text-gray-500`}
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column: any) => (
              <div {...column.getHeaderProps()}>{column.render("Header")}</div>
            ))}
          </div>
        ))}
      </div>
      <div className={classNames(rowsClassName)} {...getTableBodyProps()}>
        {rows.map((row: Row<any>) => {
          prepareRow(row);

          return (
            <div
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className={`mt-4 grid grid-cols-4 text-sm capitalize text-black ${
                columns.length ? `grid-cols-${columns.length}` : "auto-cols-max"
              }`}
              {...row.getRowProps()}
            >
              {row.cells.map((cell: Cell) => {
                return <div {...cell.getCellProps()}>{cell.render("Cell")}</div>;
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Table;
