import { TFunction } from "i18next";
import { FC, SVGProps } from "react";
import { CryptoCurrencyTicker, NationalCurrencyIso } from "../../app/constants";
import { ReactComponent as CnyIcon } from "../../assets/currencies/national/cny.svg";
import { ReactComponent as UsdIcon } from "../../assets/currencies/national/usd.svg";
import { ReactComponent as EurIcon } from "../../assets/currencies/national/eur.svg";
import { ReactComponent as GbpIcon } from "../../assets/currencies/national/gbp.svg";
import { ReactComponent as JpyIcon } from "../../assets/currencies/national/jpy.svg";
import { ReactComponent as TbtcIcon } from "../../assets/currencies/crypto/tbtc.svg";

interface CurrencyDescriptor {
  name: string;
  label: string;
  symbol: string;
  // National currency iso code or crypto currency ticker
  shortCode: string;
  icon: FC<SVGProps<SVGSVGElement>>;
}

export const getCurrenciesDescriptors = (t: TFunction) => {
  const currenciesDescriptors: Record<NationalCurrencyIso | CryptoCurrencyTicker, CurrencyDescriptor> = {
    [NationalCurrencyIso.CNY]: {
      name: t("currencies.national.cny.name"),
      label: t("currencies.national.cny.label"),
      symbol: t("currencies.national.cny.symbol"),
      shortCode: NationalCurrencyIso.CNY,
      icon: CnyIcon,
    },
    [NationalCurrencyIso.USD]: {
      name: t("currencies.national.usd.name"),
      label: t("currencies.national.usd.label"),
      symbol: t("currencies.national.usd.symbol"),
      shortCode: NationalCurrencyIso.USD,
      icon: UsdIcon,
    },
    [NationalCurrencyIso.EUR]: {
      name: t("currencies.national.eur.name"),
      label: t("currencies.national.eur.label"),
      symbol: t("currencies.national.eur.symbol"),
      shortCode: NationalCurrencyIso.EUR,
      icon: EurIcon,
    },
    [NationalCurrencyIso.GBP]: {
      name: t("currencies.national.gbp.name"),
      label: t("currencies.national.gbp.label"),
      symbol: t("currencies.national.gbp.symbol"),
      shortCode: NationalCurrencyIso.GBP,
      icon: GbpIcon,
    },
    [NationalCurrencyIso.JPY]: {
      name: t("currencies.national.jpy.name"),
      label: t("currencies.national.jpy.label"),
      symbol: t("currencies.national.jpy.symbol"),
      shortCode: NationalCurrencyIso.JPY,
      icon: JpyIcon,
    },
    [NationalCurrencyIso.PHP]: {
      name: t("currencies.national.php.name"),
      label: t("currencies.national.php.label"),
      symbol: t("currencies.national.php.symbol"),
      shortCode: NationalCurrencyIso.PHP,
      // TODO: replace with PHP icon
      icon: JpyIcon,
    },
    [CryptoCurrencyTicker.TBTC]: {
      name: t("currencies.crypto.tbtc.name"),
      label: t("currencies.crypto.tbtc.label"),
      symbol: t("currencies.crypto.tbtc.symbol"),
      shortCode: CryptoCurrencyTicker.TBTC,
      icon: TbtcIcon,
    },
  };

  return currenciesDescriptors;
};
