import * as yup from "yup";
import { asyncDebounce } from "../async-debounce";
import { asyncMemoizeOne } from "../async-memoize-one";

export type ValidationServerSide = (value: any, context: yup.TestContext) => Promise<boolean>;
interface WithServerSideValidationArgs {
  baseSchema: yup.BaseSchema;
  validateServerSide: ValidationServerSide;
}

/**
 * This is a function to be used inside yup schema definition to implement server side validation
 * The reason to introduce this function is one annoying yup behavior:
 * yup always invoke all test functions, but we want to do not trigger server side valiation
 * if our base validation is failed
 * You can refer `useSignUpValidationSchema.ts` to see how it works
 *
 *
 * @param param0.baseSchema - basic yup definition of validation
 * @param param0.validateServerSide - the test function in yup style to resolve server side validation
 * @returns - boolean or throws validation errors
 */
export const withServerSideValidation = ({ baseSchema, validateServerSide }: WithServerSideValidationArgs) => {
  return baseSchema.test(async (value, context) => {
    await baseSchema.validate(value);
    await validateServerSide(value, context);

    return true;
  });
};

export interface CreateErrorArgs {
  code: number | undefined;
  messagesMap: Record<string, string | null>;
  fallbackMessage: string | null;
}

/**
 * Utility function to choose right error message in declarative manner
 * You can refer to `useSignUpValidationSchema.ts` to see how to use it
 *
 * @param messagesMap
 * @param code
 * @param fallbackMessage
 * @returns
 */
export const createErrorMessage = (
  messagesMap: Record<string, string | null>,
  code: number | undefined,
  fallbackMessage: string | null
) => ({
  message: (typeof code === "number" ? messagesMap[code] : fallbackMessage) ?? fallbackMessage ?? undefined,
});

/**
 * Utility function to get the wrapper around server side validation function
 * with memoization (last call) and debounce effect
 * Should be used with useMemo hook, you can refer to `useSignUpValidationSchema.ts`
 * to see how to use it
 *
 * @param validateFunction
 * @param delayTime
 * @returns
 */
export const memoizeDebounce = (validateFunction: ValidationServerSide, delayTime = 750) =>
  asyncMemoizeOne(asyncDebounce(validateFunction, delayTime), (first, second) => first[0] === second[0], {
    cachePromiseRejection: true,
  });
