import { fetchBaseQuery, BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import { appActions } from "../../store/slices/app.slice";
import { RootState } from "../../store/store";
import { AuthEndpoints } from "../enums/auth-endpoints";
import { ApiErrorCode } from "../error-codes";
import { getServerErrorResponse } from "../error-handling";
import { getBaseUrl } from "../getBaseUrl";
import { getCsrfResponseSchema } from "../models/get-csrf-token";
import { handleQueryResult } from "./utils/handleQueryResult";

const baseQuery = fetchBaseQuery({
  baseUrl: getBaseUrl(),
  prepareHeaders: (headers, { getState }) => {
    const csrfToken = (getState() as RootState).app.csrfToken;
    if (csrfToken) {
      headers.set("Authorization", `Bearer ${csrfToken}`);
    }
    return headers;
  },
});

export const baseQueryPublic: BaseQueryFn = async (args, api, extra) => {
  const originalResult = await baseQuery(args, api, extra);
  const errorResponseCode = originalResult.error && getServerErrorResponse(originalResult.error)?.code;
  const isTokenExpiredError = errorResponseCode && [ApiErrorCode.TokenExpired].includes(errorResponseCode);
  if (isTokenExpiredError) {
    const refreshTokenRespone = await baseQuery(AuthEndpoints.AUTH_CSRF, api, extra);
    const {
      data: { csrfToken },
    } = getCsrfResponseSchema.validateSync(refreshTokenRespone.data);
    api.dispatch(appActions.setCsrfToken({ csrfToken: csrfToken.token }));

    const secondResult = await baseQuery(args, api, extra);
    return handleQueryResult(secondResult);
  }

  return handleQueryResult(originalResult);
};
