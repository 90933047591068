import { z } from "zod";
import { currencyCodeSchema } from "../atoms/currency-code";

export const cardSchema = () =>
  z.object({
    enabled: z.array(currencyCodeSchema()),
    disabled: z.array(currencyCodeSchema()),
    config: z
      .record(
        currencyCodeSchema(),
        z.object({
          // UUID
          currencyId: z.string(),
          activeCardCount: z.number().optional(),
          list: z.array(
            z.object({
              // GSL_AL1 | GSL_GPST1 | GSL_PL1 | GSL_ST1 | NV_PVC1 | NV_SWANALUM | NV_SWANSTNLS
              productRef: z.string(),
              // ALUMINIUM | GOLD STEEL | PLASTIC | STAINLESS STEEL | STEEL
              cardType: z.string(),
              cost: z.number(),
              // 1206 | 1229 | 1248 | 8358
              cardDesign: z.string(),
              images: z.object({
                // URL to svg
                front: z.string(),
                // URL to svg
                back: z.string(),
              }),
            })
          ),
        })
      )
      .optional(),
    purchaseEnabled: z.array(z.unknown()).optional(),
  });
