import classNames from "classnames";
import React, { FC, forwardRef, PropsWithChildren, ReactNode } from "react";
import { BasePhoneInput, PhoneInputProps } from "./inputs/BasePhoneInput";
import { BaseSelectInput, SelectInputProps } from "./inputs/BaseSelectInput";
import { BaseTextInput, TextInputProps } from "./inputs/BaseTextInput";
import { BaseTextMaskInput, TextMaskInputProps } from "./inputs/BaseTextMaskInput";
import "./Input.css";
import { UI_TEST_IDS } from "../../test-utils/ui-test-ids";
import SelectSmooth from "../SelectSmooth/SelectSmooth";
import { VerificationCodeInput } from "./inputs/VerificationCodeInput";
import { VerificationInputProps } from "react-verification-input";

export interface InputProps {
  label: ReactNode;
  helperText?: string | null;
  classes?: {
    root?: string;
    label?: string;
    helperText?: string;
  };
  isError?: boolean;
  required?: boolean;
  disabled?: boolean;
  id?: string;
  name?: string;
}

/**
 * Provides floating label and helper text with error handler
 */
const InputWrapper: FC<PropsWithChildren<InputProps>> = ({
  label,
  classes,
  helperText,
  isError,
  children,
  ...props
}) => (
  // @ts-ignore
  <div
    className={classNames(
      "inputfield-root",
      {
        "is-error": isError,
      },
      classes?.root
    )}
    data-testid={UI_TEST_IDS.input.root(props.name)}
  >
    {children}
    {label && (
      // @ts-ignore
      <label htmlFor={props.id} className={classNames("inputfield-label", classes?.label)}>
        {label}
        {props.required ? "*" : ""}
      </label>
    )}
    {helperText && (
      <p
        className={classNames("inputfield-helper-text", classes?.helperText)}
        data-testid={UI_TEST_IDS.input.helperText(props.name)}
      >
        {helperText}
      </p>
    )}
  </div>
);

const SelectWrapper: FC<PropsWithChildren<InputProps>> = ({
  label,
  classes,
  helperText,
  isError,
  children,
  ...props
}) => (
  <div
    className={classNames(
      "inputfield-root",
      {
        "is-error": isError,
      },
      classes?.root
    )}
    data-testid={UI_TEST_IDS.input.root(props.name)}
  >
    <>
      {children}
      {label && (
        <label htmlFor={props.id} className={classNames("selectfield-label", classes?.label)}>
          <>
            {label}
            {props.required ? "*" : ""}
          </>
        </label>
      )}
      {helperText && (
        <p
          className={classNames("inputfield-helper-text", classes?.helperText)}
          data-testid={UI_TEST_IDS.input.helperText(props.name)}
        >
          {helperText}
        </p>
      )}
    </>
  </div>
);

export const TextInput = forwardRef<HTMLInputElement, InputProps & TextInputProps>(
  ({ label, helperText, isError, classes, ...props }, ref) => (
    <InputWrapper {...props} label={label} helperText={helperText} isError={isError} classes={classes}>
      {/* @ts-ignore */}
      <BaseTextInput {...props} ref={ref} data-testid={UI_TEST_IDS.input.control(props.name)} />
    </InputWrapper>
  )
);

export const TextMaskInput = forwardRef<HTMLInputElement, InputProps & TextMaskInputProps>(
  ({ label, helperText, isError, classes, ...props }, ref) => (
    <InputWrapper {...props} label={label} helperText={helperText} isError={isError} classes={classes}>
      <BaseTextMaskInput {...props} ref={ref} data-testid={UI_TEST_IDS.input.control(props.name)} />
    </InputWrapper>
  )
);

export const VerificationCode = forwardRef<HTMLInputElement, InputProps & VerificationInputProps>(
  ({ label, helperText, isError, classes, ...props }, ref) => (
    <InputWrapper {...props} label={label} helperText={helperText} isError={isError} classes={classes}>
      <VerificationCodeInput {...props} data-testid={UI_TEST_IDS.input.control(props.name)} />
    </InputWrapper>
  )
);

export const SelectInput = <Option,>({
  label,
  helperText,
  isError,
  classes,
  ...props
}: InputProps & SelectInputProps<Option>) => (
  <InputWrapper {...props} label={label} helperText={helperText} isError={isError} classes={classes}>
    <BaseSelectInput<Option> {...props} data-testid={UI_TEST_IDS.input.control(props.name)} />
  </InputWrapper>
);

export const SmoothSelect = <Option,>({
  label,
  helperText,
  isError,
  classes,
  ...props
}: InputProps & SelectInputProps<Option>) => (
  <SelectWrapper {...props} label={label} helperText={helperText} isError={isError} classes={classes}>
    <SelectSmooth<Option> {...props} data-testid={UI_TEST_IDS.input.control(props.name)} />
  </SelectWrapper>
);

export const PhoneInput: FC<InputProps & PhoneInputProps> = ({ label, helperText, isError, classes, ...props }) => (
  <InputWrapper {...props} label={label} helperText={helperText} isError={isError} classes={classes}>
    <BasePhoneInput {...props} data-testid={UI_TEST_IDS.input.control(props.name)} />
  </InputWrapper>
);
