import { AuthApi } from "../../api/slices/auth.api";
import { getStore } from "../../services/store";
import { appActions } from "../../store/slices/app.slice";

export const loadCsrfToken = async () => {
  const result = getStore().dispatch(AuthApi.endpoints.getCsrf.initiate());
  const response = await result.unwrap();

  getStore().dispatch(appActions.setCsrfToken({ csrfToken: response.data.csrfToken.token }));
  result.reset();

  return true;
};
