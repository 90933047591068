import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CryptoCurrencyTicker, NationalCurrencyIso } from "../../app/constants";
import { getCurrenciesDescriptors } from "./getCurrenciesDescriptors";

interface CurrencyLabelProps {
  currency: NationalCurrencyIso | CryptoCurrencyTicker;
  variant: "short" | "full";
}

const CurrencyLabel: FC<CurrencyLabelProps> = ({ currency, variant }) => {
  const { t } = useTranslation();
  const descriptors = useMemo(() => getCurrenciesDescriptors(t), [t]);
  const { name, shortCode, icon: FlagIcon } = descriptors[currency];
  const title = variant === "short" ? shortCode : name;

  return (
    <div className="flex items-center gap-2">
      <FlagIcon />
      <div className="font-inter text-xs text-black">{title}</div>
    </div>
  );
};

export const ShortCurrencyLabel: FC<Omit<CurrencyLabelProps, "variant">> = props => (
  <CurrencyLabel variant="short" {...props} />
);

export const FullCurrencyLabel: FC<Omit<CurrencyLabelProps, "variant">> = props => (
  <CurrencyLabel variant="full" {...props} />
);
