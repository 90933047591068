import React, { useMemo } from "react";
import { Link, useMatches } from "react-router-dom";
import { Logo } from "../Logo/Logo.component";
import { AppRoutePath, UserAuthState } from "../../app/constants";
import { useAppSelector } from "../../store/store";
import { determineAuthState } from "../../app/guards/auth/determineAuthState";

export const TopBar = ({ withHeaderMenu = true }) => {
  const matches = useMatches();
  const onboardingState = useAppSelector(state => state.onboarding);
  const accessToken = useAppSelector(state => state.app.accessToken);
  const resolvedAuthLevel = determineAuthState(accessToken);
  const navLinks = useMemo(
    () => [
      {
        name: "Personal Details",
        path: AppRoutePath.SIGN_UP(),
        isActive: matches.some(itemMatch => itemMatch.pathname === AppRoutePath.SIGN_UP()),
        isDisabled: resolvedAuthLevel !== UserAuthState.GUEST,
      },
      {
        name: "Membership",
        path: AppRoutePath.ONBOARDING(),
        isActive: matches.some(itemMatch => itemMatch.pathname.startsWith(AppRoutePath.ONBOARDING())),
        isDisabled: resolvedAuthLevel !== UserAuthState.ONBOARDING_REQUIRED,
      },
      {
        name: "Card",
        path: AppRoutePath.CARD_DESIGN(),
        isActive: matches.some(itemMatch => itemMatch.pathname === AppRoutePath.CARD_DESIGN()),
        isDisabled: !onboardingState.selectedPlanId,
      },
      {
        name: "Payment",
        path: "/",
        isActive: matches.some(itemMatch => itemMatch.pathname === AppRoutePath.CHECKOUT()),
        isDisabled:
          !onboardingState.selectedCurrency ||
          !onboardingState.selectedCardDesign ||
          !onboardingState.selectedProductRef ||
          !onboardingState.selectedShipping,
      },
    ],
    [matches, onboardingState, resolvedAuthLevel]
  );

  return (
    <div
      className="sticky top-0 z-[999] flex h-20 w-full items-center px-8"
      style={{
        background: "#FFFFFFCC 0% 0% no-repeat padding-box",
        backdropFilter: "blur(20px)",
      }}
    >
      <Logo />
      {withHeaderMenu ? (
        <nav className="flex h-full flex-1 items-center justify-end gap-10 pt-4 font-brown-bold font-bold md:justify-center">
          {navLinks.map((nav, index) => {
            if (nav.isDisabled) {
              return (
                <span
                  key={index}
                  className="hidden h-full select-none items-center border-b-4 border-transparent text-gray-400 md:flex"
                >{`${index + 1}. ${nav.name}`}</span>
              );
            }

            return (
              <Link
                className={`hidden h-full select-none items-center border-b-4 border-transparent text-gray-400 transition-all hover:cursor-pointer hover:border-black hover:text-gray-600 md:flex ${
                  nav.isActive ? "flex !border-black !text-black" : ""
                }`}
                to={nav.path}
                key={index}
              >{`${index + 1}. ${nav.name}`}</Link>
            );
          })}
        </nav>
      ) : null}
    </div>
  );
};
