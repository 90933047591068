import jwtDecode from "jwt-decode";
import { AccessTokenScope, decodedTokenSchema } from "../../../api/models/decoded-token";
import { UserAuthState } from "../../constants";

export const determineAuthState = (token: string | null) => {
  if (!token) {
    return UserAuthState.GUEST;
  }

  try {
    const decodedToken = jwtDecode(token);
    const validToken = decodedTokenSchema.validateSync(decodedToken);

    // TODO: refine better auth state differentiating
    if (validToken.scopes?.[0] === AccessTokenScope.NEW_USER) {
      return UserAuthState.ONBOARDING_REQUIRED;
    } else if (validToken.scopes?.[0] === AccessTokenScope.SETUP_MFA) {
      return UserAuthState.MFA_SETUP_REQUIRED;
    } else if (validToken.scopes?.[0] === AccessTokenScope.RESET_MFA) {
      return UserAuthState.MFA_REQUIRED;
    } else if (validToken.scopes?.[0] === AccessTokenScope.UNVERIFIED) {
      return UserAuthState.VERIFICATION_REQUIRED;
    }
  } catch (error) {
    return UserAuthState.INVALID_AUTH;
  }

  return UserAuthState.SIGNED_IN;
};
