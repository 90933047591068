import { PlanCard } from "../../components/PlanCard/PlanCard";
import { IPlanCard } from "../../interfaces/Cards.interface";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useState } from "react";
import { OnBoardingHeader } from "../../components/OnBoardingHeader/OnBoardingHeader.component";

export const SelectPlanPage = () => {
  const { t } = useTranslation();
  const data: { plans: IPlanCard[] } = {
    plans: [
      {
        id: 1,
        name: "Custom Plan",
        price: 10,
        currencyCode: "USD",
        features: [
          "Lorem ipsum dolor sit amet",
          "Lorem ipsum dolor",
          "Lorem ipsum dolor sit",
          "Lorem ipsum",
          "Lorem ipsum dolor sit amet",
          "Lorem ipsum dolor",
        ],
        buttonText: t("selectPlan.button.customPlan.text"),
      },
      {
        id: 2,
        name: "Pre Made Plan",
        price: 20,
        currencyCode: "USD",
        features: [
          "Lorem ipsum dolor sit amet",
          "Lorem ipsum dolor",
          "Lorem ipsum dolor sit",
          "Lorem ipsum",
          "Lorem ipsum dolor sit amet",
          "Lorem ipsum dolor",
        ],
        buttonText: t("selectPlan.button.preMadePlan.text"),
      },
    ],
  };
  const { state } = useLocation();
  const selectedPlan = state?.selectedPlan || data.plans[0];
  const [selectedPlanState, setSelectedPlanState] = useState(selectedPlan);

  const handleSelectPlan = (plan: IPlanCard) => {
    setSelectedPlanState(plan);
  };

  return (
    <div className="mx-auto pt-14">
      <OnBoardingHeader title={t("selectPlan.title")} description={t("selectPlan.description")} />
      <div className="flex h-[550px] flex-wrap justify-center gap-12 px-4">
        {data?.plans?.map((plan: IPlanCard) => (
          <div className="w-[500px]" key={plan.id}>
            <PlanCard plan={plan} selectedPlan={selectedPlanState} onSelectPlan={handleSelectPlan} />
          </div>
        ))}
      </div>
    </div>
  );
};
