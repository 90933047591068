import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "ui-components/src/components/Button/Button.component";
import { ReactComponent as CircleCheckmarkIcon } from "ui-components/src/assets/icon/circle-checkmark.svg";
import { useAppSelector } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import { AppRoutePath } from "../../../app/constants";

export const KycIntroPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { kycSubmitted, kycApproved } = useAppSelector(state => state.app);

  useEffect(() => {
    if (kycApproved) {
      navigate(AppRoutePath.DASHBOARD(), { replace: true });
      return;
    }

    if (kycSubmitted) {
      navigate(AppRoutePath.KYC_STATUS(), { replace: true });
      return;
    }
  }, [kycSubmitted, kycApproved, navigate]);

  const features = [t("kyc.intro.bulletList.first"), t("kyc.intro.bulletList.second"), t("kyc.intro.bulletList.third")];
  return (
    <div className="mx-auto mb-8 mt-10 max-w-[995px] flex-1">
      <div className="auth-card-padding grid h-[556px] grid-cols-2 gap-20 rounded-2xl bg-white shadow-md">
        <div className="col-span-1 items-center">
          <div className="flex flex-nowrap content-center align-middle">
            <h1 className="font-brown-bold text-2xl">{t("kyc.intro.title")}</h1>
          </div>
          <div className="">
            <p className="font-inter whitespace-pre-wrap pt-6 text-sm">{t("kyc.intro.description")}</p>
          </div>
        </div>
        <div className="col-span-1 items-center">
          <div className="flex items-center">
            <ul className="my-5 w-full space-y-5">
              {features.map((feature: string, index: number) => (
                <li className="flex space-x-3 py-4" key={index + feature}>
                  <span className="flex content-center pt-1">
                    <CircleCheckmarkIcon />
                  </span>
                  <span className="font-inter whitespace-pre-wrap text-base">{feature}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex w-full ">
            <div className="relative flex w-full flex-col justify-between">
              <Button
                buttonType={"selectButton"}
                onClick={() => {
                  navigate(AppRoutePath.KYC_VERIFICATION());
                }}
              >
                {t("kyc.intro.button.title")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
