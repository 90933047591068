import { z } from "zod";

export const getMfaSetupResponseSchema = () =>
  z.object({
    secret: z.string(),
    otpAuthUrl: z.string(),
    qrCodeUrl: z.string(),
    confirmToken: z.object({
      token: z.string(),
      expiresIn: z.number(),
      scopes: z.array(z.string()),
    }),
  });

export type GetMfaSetupResponse = z.infer<ReturnType<typeof getMfaSetupResponseSchema>>;
