import { MembershipEndpoints } from "../enums/membership-endpoints";
import { membershipSubscriptionResponseSchema } from "../models/membership/subscription";
import { commonResponseSchema } from "../models/responses/common-response";

import { BaseProtectedApi } from "./slices/base-protected.api";

export const MembershipApi = BaseProtectedApi.injectEndpoints({
  endpoints: builder => ({
    getMembership: builder.query({
      query: () => ({
        url: MembershipEndpoints.SUBSCRIPTION,
        method: "GET",
      }),
      transformResponse: response => {
        return commonResponseSchema(membershipSubscriptionResponseSchema()).parse(response).data;
      },
    }),
  }),
});

export const { useGetMembershipQuery } = MembershipApi;
