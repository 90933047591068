import React from "react";
import { PreMadePlanCard } from "../../components/PreMadePlanCard/PreMadePlanCard.component";
import { InlineSpinner } from "../../components/Loading/InlineSpinner.component";
import { notify } from "../../utils/notify";
import { ToastTypes } from "../../utils/util-vars";
import { useNavigate } from "react-router-dom";
import { AppRoutePath } from "../../app/constants";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { OnboardingActions } from "../../store/slices/onboarding.slice";
import { Plan } from "../../api/models/plans/plan";
import { PlansApi } from "../../api/endpoints/plans.api";

export const PreMadePlansPage = () => {
  const { data, isLoading, isFetching, isError } = PlansApi.useGetPlansQuery("");
  const dispatch = useAppDispatch();
  const selectedPlanId = useAppSelector(state => state.onboarding.selectedPlanId);
  const selectedPlan = (data?.data || []).find(itemPlan => itemPlan.id === selectedPlanId);

  const navigate = useNavigate();

  const isStarterPlan = (plan: Plan) =>
    (plan.currencies.card && plan.currencies.card.enabled.length) ||
    (plan.currencies.virtualCard && plan.currencies.virtualCard.enabled.length);

  const handleSelectPlan = (plan: Plan) => {
    dispatch(OnboardingActions.selectPlan({ planId: plan.id, planPrice: plan.price }));
    const nextRoute = isStarterPlan(plan) ? AppRoutePath.CARD_DESIGN() : AppRoutePath.STARTER_PLAN_CONFIRMATION();

    navigate(nextRoute);
  };

  if (isError) {
    notify("Wrong access token, login or sign up", ToastTypes.error);
    navigate(AppRoutePath.SIGN_IN());
  }

  return isLoading || isFetching ? (
    <InlineSpinner />
  ) : (
    <div className="mx-auto w-[85%] py-14">
      <div className="grid gap-5 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-5 ">
        {data?.data.map((plan: Plan) => (
          <div className="w-full" key={plan.id}>
            <PreMadePlanCard plan={plan} handleSelectPlan={handleSelectPlan} selectedPlan={selectedPlan} />
          </div>
        ))}
      </div>
    </div>
  );
};
