import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { ReactComponent as PlusIcon } from "ui-components/src/assets/icon/plus.svg";
import { AccountApi } from "../../../api/endpoints/account.api";
import { AsyncLoadableArea } from "../../../components/AsyncLoadableArea/AsyncLoadableArea.component";
import { AccountRoutePath } from "../account.constants";
import { AccountListItem } from "./AccountListItem.component";

export const AccountListPage = () => {
  const { t } = useTranslation();
  const { data, error, isLoading } = AccountApi.endpoints.getAccountList.useQuery("");

  return (
    <AsyncLoadableArea
      data={data}
      error={error}
      isLoading={isLoading}
      render={accountList => (
        <div className="grid auto-rows-fr grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
          {accountList.map(itemAccount => (
            <AccountListItem key={itemAccount.accountId} account={itemAccount} />
          ))}
          <Link
            to={generatePath(AccountRoutePath.ADD())}
            className="flex items-center justify-center rounded-2xl border border-dashed border-white bg-transparent transition-all hover:border-black/30 hover:bg-black/10"
          >
            <div className="relative w-36 rounded-3xl bg-white p-3">
              <PlusIcon />
              <div className="absolute inset-0 flex items-center justify-center">
                <h2 className="font-brown-bold text-lg">{t("accounts.list.addNewAccount")}</h2>
              </div>
            </div>
          </Link>
        </div>
      )}
    />
  );
};
