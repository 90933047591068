import { OnBoardingHeader } from "../../components/OnBoardingHeader/OnBoardingHeader.component";
import { useTranslation } from "react-i18next";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import Question from "./Question.component";
import Button from "ui-components/src/components/Button/Button.component";
import { BackButton } from "../../components/BackButton/BackButton.component";
import { Form } from "../../components/Form/Form.component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { AppRoutePath } from "../../app/constants";

interface Plan {
  question0: string;
  question1: string;
  question2: string;
  question3: string;
  question4: string;
  question5: string;
}

interface IQuestionOptions {
  label: string;
  value: string;
}

interface IQuestionsData {
  question: string;
  name: string;
  options: IQuestionOptions[];
}

export const CreateCustomPlan: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const methods = useForm<Plan>({
    resolver: yupResolver(
      yup.object().shape({
        question0: yup.string().required(),
        question1: yup.string().required(),
        question2: yup.string().required(),
        question3: yup.string().required(),
        question4: yup.string().required(),
        question5: yup.string().required(),
      })
    ),
    mode: "onChange",
    defaultValues: {
      question0: "",
      question1: "",
      question2: "",
      question3: "",
      question4: "",
      question5: "",
    },
  });
  const onSubmit = (data: any) => {
    console.log(data);
  };

  // @ts-ignore
  const isEven = index => index % 2 > 0;

  const QuestionsData: IQuestionsData[] = Array.from({ length: 5 }, (_, i) => ({
    question: t(`createCustomPlan.question${i}`),
    name: t(`question${i}`),
    options: [
      {
        label: t(`createCustomPlan.question${i}Option1`),
        value: t(`question${i}Option1`),
      },
      {
        label: t(`createCustomPlan.question${i}Option2`),
        value: t(`question${i}Option2`),
      },
      {
        label: t(`createCustomPlan.question${i}Option3`),
        value: t(`question${i}Option3`),
      },
      {
        label: t(`createCustomPlan.question${i}Option4`),
        value: t(`question${i}Option4`),
      },
    ],
  }));

  return (
    <div className="mx-auto pt-14 sm:w-full md:w-5/6 xl:w-4/5">
      <div className="flex flex-row">
        <BackButton />
        <OnBoardingHeader title={t("createCustomPlan.title")} description={t("createCustomPlan.description")} />
      </div>
      <Form<Plan> {...methods} onSubmit={onSubmit}>
        <div className="grid grid-cols-1 gap-5">
          {QuestionsData.map((question, index) => (
            <Question
              key={index}
              question={question.question}
              name={question.name}
              reverseOrder={isEven(index)}
              options={question.options}
              errors={methods.formState.errors}
            />
          ))}
        </div>
        <div className="flex flex-col">
          <Button
            type="submit"
            onClick={() => navigate(AppRoutePath.UPGRADE_PLAN())}
            className="mx-auto my-11 w-auto py-2 px-12"
          >
            {t("createCustomPlan.button.title")}
          </Button>
        </div>
      </Form>
    </div>
  );
};
