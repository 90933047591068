import { toast } from "react-toastify";
import { ToastTypes } from "./util-vars";

const toastConfig = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
};

const ToastIt = {
  // @ts-ignore
  [ToastTypes.success]: (text, config) => toast.success(text, config),
  // @ts-ignore
  [ToastTypes.error]: (text, config) => toast.error(text, config),
  // @ts-ignore
  [ToastTypes.warning]: (text, config) => toast.warning(text, config),
  // @ts-ignore
  [ToastTypes.info]: (text, config) => toast.info(text, config),
  // @ts-ignore
  [ToastTypes.default]: (text, config) => toast(text, config),
};
export const notify = (text: string, type?: ToastTypes) => {
  ToastIt[type || ToastTypes.default](text, toastConfig);
};
