import { z } from "zod";
import { AccountType } from "../../../app/constants";
import { currencyCodeSchema } from "../atoms/currency-code";

export const accountSchema = () =>
  z.object({
    accountId: z.string(),
    alias: z.string(),
    currencyCode: currencyCodeSchema(),
    currentBalance: z.number(),
    accountNo: z.string(),
    status: z.number(),
    providerStatus: z.string(),
    type: z.nativeEnum(AccountType),
    trackingReference: z.unknown().nullable(),
    trackingLink: z.unknown().nullable(),
    trackingStatus: z.unknown().nullable(),
    accountType: z.string(),
    accountStatus: z.string(),
    cardDesign: z.string(),
    images: z.object({
      front: z.string().optional(),
      back: z.string().optional(),
    }),
  });

export type Account = z.infer<ReturnType<typeof accountSchema>>;
