import { z } from "zod";
import { AccountType, NationalCurrencyIso } from "../../app/constants";
import { AccountEndpoints } from "../enums/account-endpoints";
import { accountSchema } from "../models/account/account.schema";
import { commonResponseSchema } from "../models/responses/common-response";
import { BaseProtectedApi } from "./slices/base-protected.api";

export const AccountApi = BaseProtectedApi.injectEndpoints({
  endpoints: builder => ({
    getAccountList: builder.query({
      query: () => ({
        url: AccountEndpoints.ACCOUNT_LIST,
        method: "GET",
        validateStatus: ({ status }: Response) => [200, 404].includes(status),
      }),
      transformResponse: resposne =>
        commonResponseSchema(z.array(accountSchema()).nullable()).parse(resposne).data || [],
    }),
    addAccount: builder.mutation({
      query: (body: { type: AccountType; currencyCode: NationalCurrencyIso }) => ({
        url: AccountEndpoints.ADD_ACCOUNT,
        method: "POST",
        body,
      }),
    }),
  }),
});
