import { Route } from "react-router-dom";
import { AppRoutePath } from "../../app/constants";
import { AddAccountPage } from "./add/AddAccount.page";
import { AccountListPage } from "./list/AccountList.page";

export const getAccountRoutes = () => (
  <Route path={AppRoutePath.ACCOUNTS.ROOT()}>
    <Route index element={<AccountListPage />} />
    <Route path={AppRoutePath.ACCOUNTS.ADD()} element={<AddAccountPage />} />
  </Route>
);
