import { z } from "zod";

export const ShippingPlanSchema = () =>
  z.array(
    z.object({
      cost: z.number(),
      currency: z.string(),
      provider: z.string(),
    })
  );

export type ShippingPlanResponse = z.infer<ReturnType<typeof ShippingPlanSchema>>;
