import { CellProps } from "react-table";
import { Transaction } from "../../../api/models/transactions/transactions";
import { TransactionStatuses } from "../../../app/constants";

const StatusCell = ({ value }: CellProps<Transaction>) => {
  return (
    <p
      className={`w-min rounded-lg px-2 py-1 text-center ${
        value === TransactionStatuses.success ? "bg-green-100 text-green-600" : "bg-red-100 text-red-600"
      }`}
    >
      {value}
    </p>
  );
};

export default StatusCell;
