import { FC } from "react";
import { Card } from "../../components/Card/Card";
import TransactionsTable from "./TransactionsTable/TransactionsTable.component";
import UserCard from "./UserCard/UserCard.components";

export const DashboardPage: FC = () => {
  return (
    <div className="grid grid-cols-[1fr] flex-wrap gap-2 lg:grid-cols-[2fr_1fr] lg:gap-6">
      <Card className="p-6">
        CARDS Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, praesentium! Lorem ipsum dolor sit amet
        consectetur adipisicing elit. Aspernatur repudiandae velit, aut, nulla totam distinctio harum illo dolore error
        placeat, ab molestiae dicta sapiente aliquid eius. Quisquam quis modi ducimus nulla sequi numquam dignissimos,
        vero enim quasi tempore, quo accusantium doloribus alias incidunt eligendi consequatur animi dolorem laboriosam
        architecto perferendis cumque ullam, unde praesentium. Nostrum nesciunt, laboriosam accusantium iure quidem
        expedita, eos ratione quos fugit suscipit, repellat magnam perspiciatis animi pariatur aliquid voluptate
        praesentium. Dolorem a natus debitis qui asperiores vero, ipsam beatae illum omnis ratione sequi quidem tenetur
        quasi obcaecati rem labore voluptas saepe fugiat optio harum molestias veniam.
      </Card>
      <UserCard />
      <TransactionsTable />
      <Card className="self-start p-6">
        NOTIFICATIONS Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dignissimos, molestias! Lorem ipsum
        dolor sit, amet consectetur adipisicing elit. Sed!
      </Card>
    </div>
  );
};
