import { useRef, useState } from "react";
import { useAppSelector } from "../../../store/store";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements } from "@stripe/react-stripe-js";
import Payment from "../../../components/Payment/Payment.component";
import PaymentConfirmationComponent from "../../../components/PaymentConfirmation/PaymentConfirmation.component";
import { Modal } from "../../../components/Modal/Modal.component";

if (!process.env.REACT_APP_STRIPE_KEY) {
  throw new Error(`"REACT_APP_STRIPE_KEY" environment variable is not provided`);
}

export const MakePayment = () => {
  // @ts-ignore
  const stripePromiseRef = useRef(loadStripe(process.env.REACT_APP_STRIPE_KEY));
  const onboardingState = useAppSelector(state => state.onboarding);
  const isModalOpened = Boolean(onboardingState?.selectedPaymentMethod);
  const [transactionId, setTransactionId] = useState<string | undefined>();
  const retry = () => setTransactionId(undefined);

  return (
    <Modal isOpened={isModalOpened}>
      <div className="relative h-full w-[495px] rounded-lg bg-white/95 p-10">
        <Elements stripe={stripePromiseRef.current} options={{ appearance: { theme: "flat" } }}>
          {transactionId ? (
            <PaymentConfirmationComponent transactionId={transactionId} retry={retry} />
          ) : (
            <Payment nextStep={setTransactionId} />
          )}
        </Elements>
      </div>
    </Modal>
  );
};
