import { z } from "zod";

export const commonBalanceSchema = () =>
  z.object({
    currency: z.string(),
    totalBalance: z.number(),
  });

export const accountBalanceResponseSchema = () =>
  z.object({
    card: commonBalanceSchema(),
    eWallet: commonBalanceSchema(),
    currency: z.string().optional(),
    totalBalance: z.number().optional(),
  });

export type AccountBalanceResponse = z.infer<ReturnType<typeof accountBalanceResponseSchema>>;
