import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const OnboardingSlice = createSlice({
  name: "onboarding",
  initialState: {
    selectedPlanId: null,
    selectedCardDesign: null,
    selectedProductRef: null,
    selectedCardImageUrl: null,
    selectedCurrency: null,
    selectedDebitAmount: null,
    selectedShipping: null,
    selectedPaymentMethod: null,
    selectedPlanCode: null,
  },
  reducers: {
    selectPlan: (state, action: PayloadAction<{ planId: string; planPrice: number }>) => {
      // @ts-ignore
      state.selectedPlanId = action.payload.planId;
      // @ts-ignore
      state.selectedDebitAmount = action.payload.planPrice;
    },
    selectCard: (state, action: PayloadAction<{ cardDesign: string; productRef: string; cardDesignUrl: string }>) => {
      // @ts-ignore
      state.selectedCardImageUrl = action.payload.cardDesignUrl;
      // @ts-ignore
      state.selectedCardDesign = action.payload.cardDesign;
      // @ts-ignore
      state.selectedProductRef = action.payload.productRef;
    },
    selectCurrency: (state, action: PayloadAction<{ currency: string }>) => {
      // @ts-ignore
      state.selectedCurrency = action.payload.currency;
    },
    selectShipping: (state, action: PayloadAction<{ shipping: string }>) => {
      // @ts-ignore
      state.selectedShipping = action.payload.shipping;
    },
    // @ts-ignore
    selectPaymentMethod: (state, action: PayloadAction<{ paymentMethod }>) => {
      state.selectedPaymentMethod = action.payload.paymentMethod;
    },
  },
});

export const OnboardingActions = OnboardingSlice.actions;
