import { Transaction } from "../../../api/models/transactions/transactions";
import { Card } from "../../../components/Card/Card";
import Table from "../../../components/Table/Table.component";
import { Spinner } from "../../../components/Loading/Spinner.component";
import { SpinnerTypes } from "../../../utils/util-vars";
import { useTranslation } from "react-i18next";
import { useTransactionsTable } from "./hooks/useTransactionsTable";

const TransactionsTable = () => {
  const { t } = useTranslation();
  const { isLoading, handleLoadMore, isFetching, columns, data } = useTransactionsTable();

  return (
    <>
      <Card className="relative w-[100%] px-6 py-8">
        {(isLoading || isFetching) && <Spinner type={SpinnerTypes.default} />}
        <div className={`${isLoading && "opacity-50"}`}>
          <div className="mb-4 flex justify-between border-b-2 border-gray-400">
            <p className="grow pb-2 text-lg font-bold">{t("transactionsTable.title")}</p>
            <button
              disabled={isLoading || isFetching}
              onClick={handleLoadMore}
              className="hover:text-zinc-600 disabled:text-zinc-400"
            >
              {t("transactionsTable.loadMoreButton")}
            </button>
          </div>
          {!!data.length && (
            <Table<Transaction> columns={columns} data={data} rowsClassName="max-h-[244px] overflow-y-auto" />
          )}
        </div>
      </Card>
    </>
  );
};

export default TransactionsTable;
