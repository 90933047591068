import React from "react";
import { Outlet } from "react-router-dom";
import pageBackground from "../assets/page-background.png";

export const BaseLayout = () => (
  <div
    className="h-screen overflow-y-scroll bg-cover bg-center bg-no-repeat"
    style={{ backgroundImage: `url("${pageBackground}")` }}
  >
    <Outlet />
  </div>
);
