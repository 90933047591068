import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useResetPasswordMutation } from "../../api/slices/auth.api";
import { Form } from "../../components/Form/Form.component";
import { EyeIcon } from "ui-components/src/components/icons/icons";
import { notify } from "../../utils/notify";
import { useSearchParams, useNavigate } from "react-router-dom";
import { ToastTypes } from "../../utils/util-vars";
import { AppRoutePath } from "../../app/constants";
import { getServerErrorResponse } from "../../api/error-handling";
import { createErrorMessage } from "../../utils/form/server-side-validation";
import { ApiErrorCode } from "../../api/error-codes";
import { PasswordStrength } from "../../components/PasswordStrength/PasswordStregth.component";
import { TextField } from "ui-components/src/components/Input/Input.field";
import { InlineSpinner } from "../../components/Loading/InlineSpinner.component";
import Button from "ui-components/src/components/Button/Button.component";

interface ResetPasswordFormValues {
  password: string;
  confirmPassword: string;
}

const classes = {
  title: "text-2xl font-bold mb-4 font-brown-bold",
  fieldset: "flex flex-col justify-between gap-3 relative",
};

export const ResetPassword = () => {
  const { t } = useTranslation();
  const resetPasswordSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, t("signIn.form.fields.password.error") || "")
      .matches(/[a-z]/, t("passwordRules.error.lowercase") || "")

      .matches(/[A-Z]/, t("passwordRules.error.uppercase") || "")
      .matches(/[0-9]/, t("passwordRules.error.number") || "")
      .max(32)
      .required(t("signIn.form.fields.password.required") || ""),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], t("resetPassword.validation.error.match.text") || "")
      .min(8, t("signIn.form.fields.password.error") || "")
      .matches(/[a-z]/, t("passwordRules.error.lowercase") || "")
      .matches(/[A-Z]/, t("passwordRules.error.uppercase") || "")
      .matches(/[0-9]/, t("passwordRules.error.number") || "")
      .max(32)
      .required(t("signIn.form.fields.password.required") || ""),
  });
  const navigate = useNavigate();
  const [resetPassword, { data, error, isLoading }] = useResetPasswordMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const methods = useForm<ResetPasswordFormValues>({
    resolver: yupResolver(resetPasswordSchema),
    mode: "onBlur",
  });
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const onSubmitForm = async (data: ResetPasswordFormValues) => {
    const resetData = { password: data.password, token: token };
    await resetPassword(resetData);
  };
  useEffect(() => {
    if (error) {
      const errorResponse = getServerErrorResponse(error);
      const { message } = createErrorMessage(
        {
          [ApiErrorCode.TokenExpired]: t("validation.server.tokenExpired") || "",
        },
        errorResponse?.code,
        t("resetPassword.process.failure.unknown" || "")
      );
      notify(message as string, ToastTypes.error);
    }
  }, [error]);
  useEffect(() => {
    if (data) {
      // notify(data.message, ToastTypes.success);
      navigate(AppRoutePath.SIGN_IN());
    }
  }, [data, navigate]);

  return (
    <Form<ResetPasswordFormValues> {...methods} onSubmit={onSubmitForm}>
      <div className="pb-14 text-center">
        <h1 className={classes.title}>{t("resetPassword.fields.title.text")}</h1>
        <p className="mt-8">{t("resetPassword.fields.subtitle.text")}</p>
      </div>
      <div className="mb-6">
        <div className={classes.fieldset}>
          <TextField
            type={showPassword ? "text" : "password"}
            name="password"
            id="password"
            label={t("resetPassword.fields.password.label")}
            required
          />
          <EyeIcon
            data-testid="password-toggle-icon"
            onClick={togglePassword}
            className="absolute right-0 top-3 mr-3 h-5 w-5"
          />
          {methods.watch("password") && <PasswordStrength password={methods.watch("password")} />}
        </div>
      </div>
      <div className="mb-6">
        <div className={classes.fieldset}>
          <TextField
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            id="confirmPassword"
            label={t("resetPassword.fields.confirmPassword.label")}
            required
          />
          <EyeIcon
            data-testid="confirm-password-toggle-icon"
            onClick={toggleConfirmPassword}
            className="absolute right-0 top-3 mr-3 h-5 w-5"
          />
        </div>
      </div>
      <div className="mb-6 flex items-center justify-center">
        <Button type="submit" data-testid="resetPassword-button" disabled={isLoading}>
          {isLoading ? (
            <>
              <InlineSpinner />
              {t("resetPassword.submitButton.loading")}
            </>
          ) : (
            <>{t("resetPassword.submitButton.text")}</>
          )}
        </Button>
      </div>
    </Form>
  );
};
