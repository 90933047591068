import Select, { Props } from "react-select";
import React from "react";
import classNames from "classnames";
import "./SelectSmooth.css";

export type SelectInputProps<Option> = Props<Option> & {
  getOptionValue: (option: Option) => string;
};
const SelectSmooth = <Option,>(props: SelectInputProps<Option>) => {
  return (
    <Select
      {...props}
      classNames={{
        container: () => classNames("smooth-select"),
        placeholder: () => classNames("smooth-select-placeholder"),
        control: state => classNames("smooth-select--control"),
        valueContainer: () => classNames("smooth-select-value-container"),
        dropdownIndicator: () => classNames("select-dropdown-indicator"),
        menu: () => classNames("smooth-select-menu"),
        option: state =>
          classNames("smooth-select-option", {
            "smooth-select-option--active": state.isFocused || state.isSelected,
          }),
      }}
      placeholder={" "}
      unstyled
    />
  );
};

export default SelectSmooth;
