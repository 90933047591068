import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLoaderData, useMatches } from "react-router-dom";
import { AppRoutePath } from "../../app/constants";
import NavLink from "./Sidebar.navlink";
import { appActions } from "../../store/slices/app.slice";
import DashboardIcon from "ui-components/src/assets/sidebar-icons/Dashboard.svg";
import AccountsIcon from "ui-components/src/assets/sidebar-icons/Accounts.svg";
import CardIcon from "ui-components/src/assets/sidebar-icons/Cards.svg";
import CryptoIcon from "ui-components/src/assets/sidebar-icons/Crypto.svg";
import TravelIcon from "ui-components/src/assets/sidebar-icons/Travel.svg";
import ReferralsIcon from "ui-components/src/assets/sidebar-icons/Referrals.svg";
import ShoppingIcon from "ui-components/src/assets/sidebar-icons/Shopping.svg";
import RewardsIcon from "ui-components/src/assets/sidebar-icons/Rewards.svg";
import SettingsIcon from "ui-components/src/assets/sidebar-icons/Settings.svg";
import LogoutIcon from "ui-components/src/assets/sidebar-icons/Logout.svg";
import ConciergeIcon from "ui-components/src/assets/sidebar-icons/Concierge.svg";
import { MembershipSubscriptionResponse } from "../../api/models/membership/subscription";

const Sidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const matches = useMatches();

  const { id } = useLoaderData() as Partial<MembershipSubscriptionResponse>;

  const topNavLinks = useMemo(
    () => [
      {
        name: t("sidebar.navLinks.dashboard"),
        path: AppRoutePath.DASHBOARD(),
        isActive: matches.some(itemMatch => itemMatch.pathname === AppRoutePath.DASHBOARD()),
        isDisabled: false,
        iconPath: DashboardIcon,
        isHidden: !id,
      },
      {
        name: t("sidebar.navLinks.cards"),
        path: AppRoutePath.CARDS(),
        isActive: matches.some(itemMatch => itemMatch.pathname === AppRoutePath.CARDS()),
        isDisabled: false,
        iconPath: CardIcon,
        isHidden: !id,
      },
      {
        name: t("sidebar.navLinks.accounts"),
        path: AppRoutePath.ACCOUNTS.ROOT(),
        isActive: matches.some(itemMatch => itemMatch.pathname === AppRoutePath.ACCOUNTS.ROOT()),
        isDisabled: false,
        iconPath: AccountsIcon,
        isHidden: !id,
      },
      {
        name: t("sidebar.navLinks.crypto"),
        path: AppRoutePath.CRYPTO(),
        isActive: matches.some(itemMatch => itemMatch.pathname === AppRoutePath.CRYPTO()),
        isDisabled: false,
        iconPath: CryptoIcon,
        isHidden: !id,
      },
      {
        name: t("sidebar.navLinks.travel"),
        path: AppRoutePath.TRAVEL(),
        isActive: matches.some(itemMatch => itemMatch.pathname === AppRoutePath.TRAVEL()),
        isDisabled: false,
        iconPath: TravelIcon,
        isHidden: !id,
      },
      {
        name: t("sidebar.navLinks.concierge"),
        path: AppRoutePath.CONCIERGE(),
        isActive: matches.some(itemMatch => itemMatch.pathname === AppRoutePath.CONCIERGE()),
        isDisabled: false,
        iconPath: ConciergeIcon,
        isHidden: !id,
      },
      {
        name: t("sidebar.navLinks.referrals"),
        path: AppRoutePath.REFERRALS(),
        isActive: matches.some(itemMatch => itemMatch.pathname === AppRoutePath.REFERRALS()),
        isDisabled: false,
        iconPath: ReferralsIcon,
        isHidden: !id,
      },
      {
        name: t("sidebar.navLinks.shopping"),
        path: AppRoutePath.SHOPPING(),
        isActive: matches.some(itemMatch => itemMatch.pathname === AppRoutePath.SHOPPING()),
        isDisabled: false,
        iconPath: ShoppingIcon,
        isHidden: !id,
      },
      {
        name: t("sidebar.navLinks.instantRewards"),
        path: AppRoutePath.INSTANT_REWARDS(),
        isActive: matches.some(itemMatch => itemMatch.pathname === AppRoutePath.INSTANT_REWARDS()),
        isDisabled: false,
        iconPath: RewardsIcon,
        isHidden: !id,
      },
    ],
    [matches, t, id]
  );

  const bottomNavLinks = useMemo(
    () => [
      {
        name: t("sidebar.navLinks.settings"),
        path: AppRoutePath.SETTINGS(),
        isActive: matches.some(itemMatch => itemMatch.pathname === AppRoutePath.SETTINGS()),
        isDisabled: false,
        iconPath: SettingsIcon,
        isHidden: !id,
      },
      {
        name: t("sidebar.navLinks.logout"),
        path: AppRoutePath.SIGN_IN(),
        isActive: false,
        isDisabled: false,
        iconPath: LogoutIcon,
        isHidden: false,
        onClick: () => dispatch(appActions.logout()),
      },
    ],
    [matches, t, dispatch, id]
  );

  return (
    <>
      <div className="m:py-7 flex h-screen w-[60px] shrink-0 flex-col overflow-y-auto overflow-x-hidden bg-white  bg-origin-padding px-1 py-2 backdrop-blur-lg sm:w-[248px] sm:px-4">
        <h1 className={"font-brown-bold mb-6  text-lg font-bold tracking-[-0.07cm] sm:ml-2 sm:text-4xl"}>Fintech.</h1>
        <nav className="flex grow flex-col justify-between">
          <div className="flex flex-col items-center sm:block">
            {topNavLinks.map(({ name, path, isActive, isDisabled, iconPath, isHidden }) => (
              <NavLink key={name} path={path} isActive={isActive} isDisabled={isDisabled} isHidden={isHidden}>
                <img src={iconPath} alt="icon" />
                <span className="hidden sm:block">{name}</span>
              </NavLink>
            ))}
          </div>
          <div className="flex flex-col items-center sm:block">
            {bottomNavLinks.map(({ name, path, isActive, isDisabled, isHidden, iconPath, ...rest }) => (
              <NavLink {...rest} key={name} path={path} isActive={isActive} isDisabled={isDisabled} isHidden={isHidden}>
                <img src={iconPath} alt="icon" />
                <span className="hidden sm:block">{name}</span>
              </NavLink>
            ))}
          </div>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
