import { createApi } from "@reduxjs/toolkit/query/react";

import { AuthEndpoints } from "../enums/auth-endpoints";
import { CommonResponse, ConfirmEmailBody, LoginBody, ResetPasswordBody } from "../../interfaces/auth-interfaces";
import { SignUpFieldValues } from "../../pages/sign-up/useValidationSchema";
import { GetCountryListResponse, getCountryListResponseSchema } from "../models/get-country-list";
import { GetCsrfResponse, getCsrfResponseSchema } from "../models/get-csrf-token";
import { GetUsStatesListResponse, getUsStatesListResponseSchema } from "../models/get-states-list";
import { SignUpSuccessfulResponse, signUpSuccessfulResponseSchema } from "../models/sign-up";
import { ValidateEmailBody, ValidateEmailResponse, validateEmailResponseSchema } from "../models/validate-email";
import { ValidatePhoneBody, ValidatePhoneResponse, validatePhoneResponseSchema } from "../models/validate-phone";
import { baseQueryPublic } from "../query-fn/public";

export const AuthApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryPublic,
  endpoints: builder => ({
    getCsrf: builder.mutation<GetCsrfResponse, void>({
      query: () => AuthEndpoints.AUTH_CSRF,
      transformResponse: response => getCsrfResponseSchema.validateSync(response),
    }),
    login: builder.mutation<SignUpSuccessfulResponse, LoginBody>({
      query: body => ({
        url: AuthEndpoints.AUTH_LOGIN,
        method: "POST",
        body,
      }),
      transformResponse: response => signUpSuccessfulResponseSchema.parse(response),
    }),
    logout: builder.mutation<CommonResponse, void>({
      query: () => ({
        url: AuthEndpoints.AUTH_LOGOUT,
        method: "POST",
      }),
    }),
    validateEmail: builder.mutation<ValidateEmailResponse, ValidateEmailBody>({
      query: body => ({
        url: AuthEndpoints.VALIDATE_EMAIL,
        method: "POST",
        body,
      }),
      transformResponse: response => validateEmailResponseSchema.validateSync(response),
    }),
    validatePhone: builder.mutation<ValidatePhoneResponse, ValidatePhoneBody>({
      query: body => ({
        url: AuthEndpoints.VALIDATE_PHONE,
        method: "POST",
        body,
      }),
      transformResponse: response => validatePhoneResponseSchema.validateSync(response),
    }),
    signUp: builder.mutation<
      SignUpSuccessfulResponse,
      Omit<SignUpFieldValues, "confirmPassword" | "membershipAgreement">
    >({
      query: body => {
        const mutatedBody = { ...body } as Partial<typeof body>;
        if (!mutatedBody.referralCode) {
          delete mutatedBody.referralCode;
        }
        Object.assign(mutatedBody, {
          dateOfBirth: body.dateOfBirth.toISOString().slice(0, 10),
        });

        return {
          url: AuthEndpoints.AUTH_SIGN_UP,
          method: "POST",
          body: mutatedBody,
        };
      },
      transformResponse: response => signUpSuccessfulResponseSchema.parse(response),
    }),
    resetPassword: builder.mutation<CommonResponse, ResetPasswordBody>({
      query: body => ({
        url: AuthEndpoints.AUTH_RESET_PASSWORD,
        method: "POST",
        body,
      }),
    }),
    confirmEmail: builder.mutation<CommonResponse, ConfirmEmailBody>({
      query: body => ({
        url: AuthEndpoints.CONFIRM_EMAIL,
        method: "POST",
        body,
      }),
    }),
    signUpCountryList: builder.mutation<GetCountryListResponse, void>({
      query: () => ({
        url: AuthEndpoints.SIGN_UP_COUNTRY_LIST,
        method: "GET",
      }),
      transformResponse: response => getCountryListResponseSchema.validateSync(response),
    }),
    signUpUsStatesList: builder.mutation<GetUsStatesListResponse, void>({
      query: () => ({
        url: AuthEndpoints.SIGN_UP_US_STATES_LIST,
        method: "GET",
      }),
      transformResponse: (response: GetUsStatesListResponse) => {
        const transformedResponse = (response.data.states as Record<string, string>[]).map(stateName => {
          for (const [key, value] of Object.entries(stateName)) {
            return { code: key, name: value };
          }
        });
        const responseNew = { ...response, ...{ data: { states: transformedResponse } } };
        return getUsStatesListResponseSchema.validateSync(responseNew);
      },
    }),
  }),
});

export const {
  useGetCsrfMutation,
  useLoginMutation,
  useLogoutMutation,
  useSignUpMutation,
  useValidateEmailMutation,
  useValidatePhoneMutation,
  useSignUpCountryListMutation,
  useConfirmEmailMutation,
  useResetPasswordMutation,
  useSignUpUsStatesListMutation,
} = AuthApi;
