import { createApi } from "@reduxjs/toolkit/query/react";
import { AuthEndpoints } from "../enums/auth-endpoints";
import { baseQueryProtected } from "../query-fn/protected";
import { commonResponseSchema } from "../models/responses/common-response";
import { SignUpSuccessfulResponse, signUpSuccessfulResponseSchema } from "../models/sign-up";
import { userSchema, User } from "../models/user/user";
import { UserEndpoints } from "../enums/user-endpoints";

type EditableUserField = "preferredCardCurrency" | "preferredDisplayLanguage";

export const UserApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryProtected,
  endpoints: builder => ({
    getUser: builder.query<User, void>({
      query: () => ({
        url: UserEndpoints.GET_USER,
        method: "GET",
      }),
      transformResponse: response => {
        return commonResponseSchema(userSchema()).parse(response).data;
      },
    }),
    updateUser: builder.mutation({
      query: (body: Partial<Pick<User, EditableUserField>>) => ({
        url: "/v2/auth/onboarding/user",
        method: "PUT",
        body,
      }),
      transformResponse: response => {
        return commonResponseSchema(userSchema()).parse(response).data;
      },
    }),
    permission: builder.query({
      query: () => ({
        url: AuthEndpoints.PERMISSION,
        method: "GET",
        validateStatus: ({ status }: Response) => status === 401 || (status >= 200 && status < 300),
      }),
      transformResponse: response => commonResponseSchema(signUpSuccessfulResponseSchema.shape.data).parse(response),
    }),
  }),
});

export const { useGetUserQuery, useUpdateUserMutation, useLazyPermissionQuery } = UserApi;
