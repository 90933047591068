import React from "react";
import classNames from "classnames";
import Select, { ClassNamesConfig, Props } from "react-select";

export const selectClassNames: ClassNamesConfig = {
  container: () => classNames("select"),
  placeholder: () => classNames("select-placeholder"),
  control: () => classNames("inputfield-control peer select-control"),
  dropdownIndicator: () => classNames("select-dropdown-indicator"),
  menu: () => classNames("select-menu"),
  option: () => classNames("select-option"),
};

export type SelectInputProps<Option> = Props<Option> & {
  getOptionValue: (option: Option) => string;
};

export const BaseSelectInput = <Option,>({ id, ...props }: SelectInputProps<Option>) => (
  <Select
    {...props}
    classNames={{
      container: () => classNames("select"),
      placeholder: () => classNames("select-placeholder"),
      control: state =>
        classNames("inputfield-control peer select-control", props.className, {
          "select-control--active": state.isFocused,
        }),
      dropdownIndicator: () => classNames("select-dropdown-indicator"),
      menu: () => classNames("select-menu"),
      option: state =>
        classNames("select-option", {
          "select-option--active": state.isFocused || state.isSelected,
        }),
    }}
    placeholder={" "}
    unstyled
    inputId={id}
  />
);
