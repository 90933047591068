export const APP_TEST_IDS = {
  signUp: {
    title: () => "sign-up-title",
  },
  signIn: {
    title: () => "sign-in-title",
  },
  forgotPassword: {
    title: () => "forgot-password-title",
  },
  resetPassword: {
    title: () => "reset-password-title",
  },
};
