import React from "react";
import {
  Question0Icon,
  Question1Icon,
  Question2Icon,
  Question3Icon,
  Question4Icon,
  Question5Icon,
} from "ui-components/src/components/icons/icons";
import { CheckboxField } from "ui-components/src/components/Checkbox/Checkbox.field";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

interface QuestionProps {
  question: string;
  name: string;
  options: { label: string; value: string }[];
  errors: any;
  reverseOrder: boolean;
}

const IconList = {
  question0: <Question0Icon />,
  question1: <Question1Icon />,
  question2: <Question2Icon />,
  question3: <Question3Icon />,
  question4: <Question4Icon />,
  question5: <Question5Icon />,
};

const Question = ({ question, name, options, reverseOrder, errors }: QuestionProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full rounded-[20px] border border-gray-200 bg-white p-4 text-center shadow backdrop-blur-xl dark:border-gray-700 dark:bg-gray-800 sm:p-8">
      <div className="grid grid-cols-9 gap-20">
        {/* @ts-ignore */}
        <div className={classNames("col-span-2 question-icon", { "order-last": reverseOrder })}>{IconList[name]}</div>
        <div className="col-span-7 py-10">
          <h5 className="mb-2 text-left text-2xl font-bold text-gray-900 dark:text-white">{question}*</h5>
          <h6 className="mb-4 text-left text-xs font-semibold text-gray-500 dark:text-gray-400">Mandatory*</h6>
          <div className="flex flex-row flex-wrap py-4 text-sm text-gray-500 dark:text-gray-400 sm:text-lg">
            {options.map(option => (
              <div className="flex-auto justify-center space-x-2 text-left align-middle leading-4" key={option.value}>
                <CheckboxField
                  type={"radio"}
                  id={option.value}
                  name={name}
                  classes={{ label: "text-gray-500 dark:text-gray-400 text-sm" }}
                  value={option.value}
                  label={option.label}
                />
              </div>
            ))}
          </div>
          {errors[name] && <span className="text-xs text-red-500">{t("createCustomPlan.question.required")}</span>}
        </div>
      </div>
    </div>
  );
};

export default Question;
