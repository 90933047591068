import * as LDClient from "launchdarkly-js-client-sdk";
import { getLaunchDarklyId, setLDClient } from "../../services/launch-darkly";
import { getStore } from "../../services/store";
import { featureFlagsActions } from "../../store/slices/feature-flags.slice";
import { featureFlagsSchema } from "../guards/feature-flags/featureFlagsSchema";

const snakeToCamel = (str: string) =>
  str.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace("-", "").replace("_", ""));

const convertFlags = (rawFlags: Record<string, any>) => {
  return Object.fromEntries(Object.entries(rawFlags).map(([key, value]) => [snakeToCamel(key), value]));
};

export const loadFeatureFlags = async () => {
  const client = LDClient.initialize(getLaunchDarklyId(), {
    kind: "multi",
    org: {
      key: "org",
      domain: document.location.hostname,
    },
  });
  await client.waitForInitialization();
  setLDClient(client);

  const flags = featureFlagsSchema.validateSync(convertFlags(client.allFlags()));
  getStore().dispatch(featureFlagsActions.setFlags({ flags }));

  client.on("change", nextRawFlags => {
    const nextFlags = featureFlagsSchema.validateSync(convertFlags(nextRawFlags));
    getStore().dispatch(featureFlagsActions.setFlags({ flags: nextFlags }));
  });

  return true;
};
