import * as yup from "yup";

export interface ValidateEmailBody {
  email: string;
}

export const validateEmailResponseSchema = yup.object().shape({
  data: yup.object().shape({
    valid: yup.boolean(),
  }),
});

export type ValidateEmailResponse = yup.InferType<typeof validateEmailResponseSchema>;
