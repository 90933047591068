import { BaseQueryFn, fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { RootState } from "../../store/store";
import { getBaseUrl } from "../getBaseUrl";
import { handleQueryResult } from "./utils/handleQueryResult";

const baseQuery = fetchBaseQuery({
  baseUrl: getBaseUrl(),
  prepareHeaders: (headers, { getState }) => {
    const accessToken = (getState() as RootState).app.accessToken;
    if (accessToken && !headers.has("Authorization")) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
});

export const baseQueryProtected: BaseQueryFn = async (args, api, extra) => {
  const result = await baseQuery(args, api, extra);

  return handleQueryResult(result);
};
