import * as yup from "yup";

export enum AccessTokenScope {
  NEW_USER = "newUser",
  SETUP_MFA = "setupMfa",
  RESET_MFA = "resetMfa",
  DEVICE_APPROVAL = "deviceApproval",
  UNVERIFIED = "unverified",
  VERIFIED = "verified",
}

export enum TokenType {
  ACCESS = "access",
  CONFIRM = "confirm",
}

export const decodedTokenSchema = yup.object().shape({
  scopes: yup.array().of(yup.string().oneOf(Object.values(AccessTokenScope))),
  type: yup.string().required().oneOf(Object.values(TokenType)),
});

export type DecodedToken = yup.InferType<typeof decodedTokenSchema>;
