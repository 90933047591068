import React, { FC } from "react";
import { Outlet } from "react-router";
import { useAppSelector } from "../../../store/store";
import { UserAuthState } from "../../constants";
import { AuthRedirect } from "./AuthRedirect";
import { determineAuthState } from "./determineAuthState";

interface RequireAuthGuardProps {
  authLevel: UserAuthState | UserAuthState[];
}

export const RequireAuth: FC<RequireAuthGuardProps> = ({ authLevel }) => {
  const accessToken = useAppSelector(state => state.app.accessToken);
  // @ts-ignore
  const resolvedAuthLevel = determineAuthState(accessToken);
  const requiredAuthLevel = Array.isArray(authLevel) ? authLevel : [authLevel];

  return requiredAuthLevel.includes(resolvedAuthLevel) ? <Outlet /> : <AuthRedirect />;
};
