import { z } from "zod";
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryProtected } from "../query-fn/protected";
import { commonResponseSchema } from "../models/responses/common-response";
import { paymentCardResponseSchema } from "../models/payment/payment-card";
import { PaymentEndpoints } from "../enums/payment-endpoints";

export const PaymentApi = createApi({
  reducerPath: "paymentApi",
  baseQuery: baseQueryProtected,
  endpoints: builder => ({
    createCardPayment: builder.mutation({
      query: body => ({
        url: PaymentEndpoints.CREATE_CARD_PAYMENT,
        method: "POST",
        body,
      }),
      transformResponse: response => {
        return commonResponseSchema(paymentCardResponseSchema()).parse(response).data;
      },
    }),
    getPaymentConfirmation: builder.query({
      query: (transactionId: string) => ({
        url: PaymentEndpoints.CONFIRM_CARD_PAYMENT,
        method: "POST",
        body: { transactionId },
      }),
      transformResponse: response =>
        commonResponseSchema(
          z.object({
            isPaymentConfirmed: z.boolean(),
            transactionStatus: z.string(),
          })
        ).parse(response).data,
    }),
  }),
});

export const { useCreateCardPaymentMutation, useGetPaymentConfirmationQuery } = PaymentApi;
