import React from "react";
import { Outlet } from "react-router-dom";

export const ResetLayout = () => (
  <div className="mx-auto mb-8 mt-10 max-w-[520px] flex-1">
    <div className="auth-card-padding flex flex-col rounded-2xl bg-white shadow-md">
      <Outlet />
    </div>
  </div>
);
