import React, { forwardRef } from "react";
import classNames from "classnames";
import InputMask, { Props } from "react-input-mask";

export type TextMaskInputProps = Omit<Props, "mask">;

export const BaseTextMaskInput = forwardRef<HTMLInputElement, TextMaskInputProps>((props, ref) => (
  <InputMask
    {...props}
    mask="9999-99-99"
    inputRef={ref}
    className={classNames("inputfield-control peer", props.className, props.className)}
    placeholder={props.placeholder || " "}
  />
));
