import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { Checkbox, CheckboxProps } from "./Checkbox.component";

export const CheckboxField: FC<CheckboxProps> = ({ name, type, ...props }) => {
  const { register, formState, watch, setValue } = useFormContext();
  if (!name) {
    throw new Error(`"name" is mandatory property for CheckBox component`);
  }

  const checked = watch(name);

  const handleChange = () => {
    setValue(name, !checked);
  };

  const errorText = formState.errors[name]?.message?.toString();

  return type === "checkbox" ? (
    <Checkbox
      {...props}
      {...register(name)}
      type={type}
      helperText={errorText || props.helperText}
      checked={checked}
      onChange={handleChange}
      isError={Boolean(errorText)}
      disabled={formState.isSubmitting}
    />
  ) : (
    <Checkbox {...props} {...register(name)} type={type} disabled={formState.isSubmitting} />
  );
};
