import React, { FC } from "react";
import { Navigate, useLocation } from "react-router";
import { useAppSelector } from "../../../store/store";
import { AppRoutePath, UserAuthState } from "../../constants";
import { determineAuthState } from "./determineAuthState";

export const AuthRedirect: FC = () => {
  const { pathname } = useLocation();
  const accessToken = useAppSelector(state => state.app.accessToken);
  const resolvedAuthLevel = determineAuthState(accessToken);
  const defaultRoutes: Record<UserAuthState, string> = {
    [UserAuthState.GUEST]: AppRoutePath.SIGN_IN(),
    [UserAuthState.ONBOARDING_REQUIRED]: AppRoutePath.ONBOARDING(),
    [UserAuthState.VERIFICATION_REQUIRED]: AppRoutePath.EMAIL_VERIFICATION(),
    [UserAuthState.MFA_SETUP_REQUIRED]: AppRoutePath.MFA_SETUP(),
    [UserAuthState.MFA_REQUIRED]: AppRoutePath.DASHBOARD(),
    // TODO: should be added possibility to add notification to user
    [UserAuthState.INVALID_AUTH]: AppRoutePath.SIGN_IN(),
    [UserAuthState.SIGNED_IN]: AppRoutePath.DASHBOARD(),
  };
  const destinationPathname = defaultRoutes[resolvedAuthLevel];

  return pathname === destinationPathname ? null : <Navigate to={destinationPathname} />;
};
