import { useTranslation } from "react-i18next";
import { useConfirmVerificationMutation, useSendVerificationMutation } from "../../api/slices/verification.api";
import { notify } from "../../utils/notify";
import { ToastTypes } from "../../utils/util-vars";
import React, { useEffect, useMemo, useState } from "react";
import { Form } from "../../components/Form/Form.component";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import z from "zod";
import { VerificationCodeField } from "ui-components/src/components/Input/Input.field";
import Button from "ui-components/src/components/Button/Button.component";
import CountdownComponent from "../../components/Countdown/Countdown.component";
import { useGetUserQuery } from "../../api/slices/user.api";
import { ApiErrorCode } from "../../api/error-codes";

interface VerificationFormValues {
  email: string;
  secretCode: string;
}

const replaceLettersWithSymbol = (characters: string, symbol: string = "*") => {
  return (
    characters.slice(0, Math.ceil(characters.length / 3)) +
    characters.slice(Math.ceil(characters.length / 3), characters.length).replace(/./g, symbol)
  );
};

const EmailVerificationPage = () => {
  const { t } = useTranslation();
  const { data: userData } = useGetUserQuery();
  const emailSplitSymbol = "@";
  const [verifyEmail, { error, isLoading }] = useConfirmVerificationMutation();
  const [resendCode, setResendCode] = useState(false);
  const [sendVerificationEmail] = useSendVerificationMutation();
  const email = useMemo(() => {
    if (userData?.emailAddress) {
      const parsed = userData?.emailAddress.split(emailSplitSymbol);
      return [replaceLettersWithSymbol(parsed[0]), parsed[1]].join(emailSplitSymbol);
    }
    return "";
  }, [userData]);

  useEffect(() => {
    if (email) {
      sendVerificationEmail({ type: "email" });
    }
  }, [email, sendVerificationEmail]);

  // @ts-ignore
  const methods = useForm<VerificationFormValues>({
    resolver: zodResolver(
      z.object({
        email: z.string(),
        // @ts-ignore
        secretCode: z
          // @ts-ignore
          .string({ required_error: t("emailVerification.form.secretCode.required") })
          // @ts-ignore
          .min(6, t("emailVerification.form.secretCode.length"))
          // @ts-ignore
          .max(6, t("emailVerification.form.secretCode.length")),
      })
    ),
    mode: "onBlur",
    defaultValues: { email, secretCode: "" },
  });
  const isButtonActive = methods.watch("secretCode")?.length === 6 && !isLoading;
  const handleSubmit = ({ secretCode }: VerificationFormValues) => {
    verifyEmail({ secretCode: parseInt(secretCode, 10) });
  };

  const resendEmailHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    resendCode && sendVerificationEmail({ type: "email" });
  };

  useEffect(() => {
    if (error && (error as any)?.data?.error?.code === ApiErrorCode.VerificationCodeWrong) {
      notify(t("verificationForm.error.wrongCode"), ToastTypes.error);
    }
  }, [error, t]);

  // @ts-ignore
  return (
    <div className="mx-auto mb-8 mt-10 max-w-[495px] flex-1">
      <div className="flex flex-col rounded-2xl bg-white/[.94] px-12 py-6 shadow-md">
        <Form<VerificationFormValues> {...methods} onSubmit={handleSubmit}>
          <div className="mb-6">
            <h1 className="w-full text-center text-2xl font-bold">{t("emailVerification.form.title")}</h1>
            <h2 className="w-full whitespace-pre-wrap py-2 text-center font-inter text-sm ">
              {t("emailVerification.form.description")}
            </h2>
            <h2 className="w-full whitespace-pre-wrap py-2 text-center text-sm font-bold ">
              {t("emailVerification.form.code.sent").replace("{email}", email)}
            </h2>
          </div>
          <div className="mb-6">
            <div className="inter-regular relative flex flex-col justify-between gap-3">
              <VerificationCodeField length={6} name="secretCode" placeholder={""} autoFocus={true} label="code" />
            </div>
          </div>
          <div className="flex items-center justify-center">
            <Button type="submit" buttonType="selectButton" disabled={!isButtonActive}>
              {t("emailVerification.form.submit.label")}
            </Button>
          </div>
          <div className="flex items-center justify-center">
            <button className="font-brown w-full p-4 text-xs underline" type="button" onClick={resendEmailHandler}>
              {!resendCode ? (
                <>
                  {t("emailVerification.form.secretCode.resend.counter")}{" "}
                  <CountdownComponent minutes={0} seconds={15} onTimerComplete={() => setResendCode(true)} />{" "}
                </>
              ) : (
                <span>{t("emailVerification.form.secretCode.resend.button")}</span>
              )}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default EmailVerificationPage;
