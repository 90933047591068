import { useTranslation } from "react-i18next";
import { Label } from "./Label.component";
import { SmoothTextField } from "ui-components/src/components/SmoothTextField/SmoothTextField.component";
import Button from "ui-components/src/components/Button/Button.component";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form } from "../../../components/Form/Form.component";
import { useApplyDiscountMutation } from "../../../api/slices/selectPlan.api";
import { notify } from "../../../utils/notify";
import { ToastTypes } from "../../../utils/util-vars";
import { Plan } from "../../../api/models/plans/plan";

interface ApplyDiscountProps {
  plan: Plan;
  refetchBill: (discountCode?: string) => Promise<void>;
  setIsDiscountFormDirty: (isDiscountFormDirty: boolean) => void;
}

export const ApplyDiscount: FC<ApplyDiscountProps> = ({ plan, setIsDiscountFormDirty, refetchBill }) => {
  const { t } = useTranslation();
  const [applyDiscount, { isLoading, error, data }] = useApplyDiscountMutation();
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      discountCode: "",
    },
  });
  const { setError } = methods;
  const isDiscountFormDirty =
    Boolean(error) || isLoading || (methods.formState.isDirty && !methods.formState.isSubmitSuccessful);

  useEffect(() => {
    setIsDiscountFormDirty(isDiscountFormDirty);
  }, [setIsDiscountFormDirty, isDiscountFormDirty]);

  useEffect(() => {
    if (error) {
      notify(t("checkout.applyDiscount.serverError"), ToastTypes.error);
    }
  }, [t, error, setError]);

  return (
    <div className="mt-12">
      <Label>{t("checkout.applyDiscount.title")}</Label>
      <Form
        {...methods}
        onSubmit={async ({ discountCode }) => {
          if (!discountCode) {
            notify(t("checkout.applyDiscount.emptyDiscountError"), ToastTypes.error);
            return true;
          }
          await applyDiscount({
            planId: plan.id,
            discountCode,
          });
          await refetchBill(discountCode);

          return true;
        }}
      >
        <div className="mt-1">
          {/* @ts-ignore */}
          <SmoothTextField name="discountCode" placeholder={t("checkout.applyDiscount.placeholder")} />
          <Button
            type="submit"
            className="ml-1 w-[120px] !rounded-2xl"
            buttonType="selectButton"
            disabled={isLoading || Boolean(data)}
          >
            {t("checkout.applyDiscount.buttonText")}
          </Button>
        </div>
      </Form>
    </div>
  );
};
