import * as yup from "yup";
import { t } from "i18next";
export const PasswordValidation = yup
  .string()
  .min(8, t("signIn.form.fields.password.error") || "")
  .matches(/[a-z]/, t("passwordRules.error.lowercase") || "")
  .matches(/[A-Z]/, t("passwordRules.error.uppercase") || "")
  .matches(/[0-9]/, t("passwordRules.error.number") || "")
  .max(32)
  .required(t("signIn.form.fields.password.required") || "");

export const noLatinCharactersRegex = /([^\x20-\x7F]+)/;
export const onlyLatinCharactersRegex = /[\x20-\x7F]+/;
// @ts-ignore
export const validatePassword = value => {
  if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).{8,}$/.test(value)) {
    return {
      isValid: true,
      isAcceptable: "strong",
      message: "",
    };
  }
  if (/^(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value)) {
    return {
      isValid: true,
      isAcceptable: "medium",
      message: "",
    };
  }
  return {
    isValid: false,
    isAcceptable: value.length === 0 ? "" : "weak",
    message: value.length === 0 ? "Please enter a password" : "",
  };
};
