import { useTranslation } from "react-i18next";
import SelectSmooth from "ui-components/src/components/SelectSmooth/SelectSmooth";
import { OnboardingActions } from "../../../store/slices/onboarding.slice";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { Label } from "./Label.component";

export const PaymentMethods = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onboardingState = useAppSelector(state => state.onboarding);
  const options = [
    {
      name: t("checkout.paymentMethods.options.card"),
      value: "card",
    },
    {
      name: "Crypto",
      value: "",
    },
  ];

  return (
    <div className="mt-12">
      <Label>{t("checkout.paymentMethods.title")}</Label>
      <SelectSmooth
        className="mt-1"
        value={options.find(itemOption => itemOption.value === onboardingState.selectedPaymentMethod)}
        isMulti={false}
        onChange={nextSelectedOption => {
          dispatch(
            OnboardingActions.selectPaymentMethod({
              paymentMethod: (nextSelectedOption as any).value,
            })
          );
        }}
        options={options}
        getOptionValue={option => option.value}
        getOptionLabel={option => option.name}
      />
    </div>
  );
};
