import React from "react";
import { useTranslation } from "react-i18next";
import { IPlanCard } from "../../interfaces/Cards.interface";
import classNames from "classnames";
import Button from "ui-components/src/components/Button/Button.component";
import { PlanCards } from "../../enums/plan-cards";
import { useNavigate } from "react-router-dom";
import { AppRoutePath } from "../../app/constants";

const PlanCardType = {
  [PlanCards.CUSTOM]: "custom-plan--BG",
  [PlanCards.PRE_MADE]: "pre-made-plan--BG",
};

interface IPlanCardProps {
  plan: IPlanCard;
  bgColor?: string;
  selectedPlan?: IPlanCard;
  onSelectPlan: (plan: IPlanCard) => void;
}
export const PlanCard = ({ plan, selectedPlan, onSelectPlan }: IPlanCardProps) => {
  const navigate = useNavigate();
  const isSelected = selectedPlan?.id === plan.id;
  const onButtonClick = () => {
    onSelectPlan(plan);
    if (plan.id === PlanCards.CUSTOM) {
      navigate(AppRoutePath.CREATE_CUSTOM_PLAN());
    } else if (plan.id === PlanCards.PRE_MADE) {
      navigate(AppRoutePath.CREATE_PRE_MADE_PLAN());
    }
  };
  return (
    <div
      className={classNames(
        `h-full flex flex-col justify-between relative p-8 bg-no-repeat bg-cover bg-right border border-gray-200 rounded-[20px] shadow backdrop-blur-xl ${
          // @ts-ignore
          PlanCardType[plan.id]
        }`,
        {
          "bg-gradient-to-t from-[#5F1DA8] to-[#635CF3]": isSelected,
          "bg-white": !isSelected,
        }
      )}
    >
      <h5
        className={classNames("mb-4 text-4xl font-bold", {
          "text-white": isSelected,
          "text-blue-700": !isSelected,
        })}
      >
        {plan.name}
      </h5>
      <ul role="list" className="my-7 w-2/3 space-y-5">
        {plan.features.map((feature: string, index: number) => (
          <li className="flex space-x-3" key={index + feature}>
            <svg
              className={classNames("flex-shrink-0 w-5 h-5", {
                "color-checked": !isSelected,
                "text-white": isSelected,
              })}
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span
              className={classNames("text-lgt", {
                "text-white": isSelected,
                "text-gray-900": !isSelected,
              })}
            >
              {feature}
            </span>
          </li>
        ))}
      </ul>

      <div className="mb-6 flex items-center justify-center">
        <Button type="button" className="h-12 flex-1" onClick={onButtonClick} buttonType={"selectButton"}>
          {plan.buttonText}
        </Button>
      </div>
    </div>
  );
};
