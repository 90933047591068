import { z } from "zod";

export const getMfaConfirmResponseSchema = () =>
  z.object({
    accessToken: z.object({
      token: z.string(),
      expiresIn: z.number(),
      scopes: z.array(z.string()),
    }),
  });

export type GetMfaConfirmResponse = z.infer<ReturnType<typeof getMfaConfirmResponseSchema>>;
