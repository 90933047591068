import { useLazyShippingPlansQuery } from "../../api/slices/selectPlan.api";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import React, { useEffect, useMemo } from "react";
import { OnboardingActions } from "../../store/slices/onboarding.slice";
import { AppRoutePath, NationalCurrencyIso } from "../../app/constants";
import { notify } from "../../utils/notify";
import { ToastTypes } from "../../utils/util-vars";
import CardSwiper from "ui-components/src/components/CardSwiper/CardSwiper.component";
import { Form } from "../../components/Form/Form.component";
import { SmoothSelectField } from "ui-components/src/components/Input/Input.field";
import flags from "react-phone-number-input/flags";
import Button from "ui-components/src/components/Button/Button.component";
import { useUpdateUserMutation } from "../../api/slices/user.api";
import { PlansApi } from "../../api/endpoints/plans.api";

interface CardDesignForm {
  currency: string;
  shipping: string;
}

const CardDesignPage = () => {
  const [fetchShippingPlan, { data: ShippingOptions }] = useLazyShippingPlansQuery();
  const [setUserData] = useUpdateUserMutation();
  const { data: dataPlans } = PlansApi.useGetPlansQuery("");
  const onboardingState = useAppSelector(state => state.onboarding);
  const selectedPlan = (dataPlans?.data || []).find(itemPlan => itemPlan.id === onboardingState.selectedPlanId);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currencySelector = selectedPlan?.currencies.card.enabled.length
    ? selectedPlan?.currencies.card.config
    : selectedPlan?.currencies.virtualCard?.config;
  const methods = useForm<CardDesignForm>({
    mode: "onChange",
    defaultValues: {
      // @ts-ignore
      currency: onboardingState.selectedCurrency,
      // @ts-ignore
      shipping: onboardingState.selectedShipping,
    },
  });

  const values = methods.watch();
  // // TODO: replace it with calculated based on values
  // // TODO: improve typing: use schema typing instead of interfaces
  // @ts-ignore
  const selectedCard = currencySelector && values.currency ? currencySelector[values.currency].list[0] : null;
  let cards =
    currencySelector && values.currency ? currencySelector?.[values.currency as NationalCurrencyIso]?.list : [];
  // @ts-ignore
  const convertToSelectOptions = cardsConfig => {
    return Array.from(Object.entries(cardsConfig)).map(([currency]) => ({
      // @ts-ignore
      name: currency,
      value: currency,
    }));
  };

  // @ts-ignore
  const shippingPlansToOptions = (shippingPlans): { name: string; value: string }[] => {
    // @ts-ignore
    return shippingPlans.map(plan => ({ name: plan.provider, value: plan.provider }));
  };

  const currencies = useMemo(() => {
    if (currencySelector && Object.keys(currencySelector).length > 0 && currencySelector.constructor === Object) {
      const convertedOptions = convertToSelectOptions(currencySelector);
      // @ts-ignore
      cards = currencySelector[convertedOptions[0].name];
      methods.setValue("currency", convertedOptions[0].value);
      return convertedOptions;
    }
    return [];
  }, [currencySelector]);

  useEffect(() => {
    if (selectedPlan?.code) {
      fetchShippingPlan({ planCode: selectedPlan.code });
    }
  }, [selectedPlan, fetchShippingPlan]);

  useEffect(() => {
    if (selectedCard) {
      dispatch(
        OnboardingActions.selectCard({
          cardDesign: selectedCard.cardDesign,
          productRef: selectedCard.productRef,
          cardDesignUrl: selectedCard.images.front,
        })
      );
    }
  }, [dispatch, selectedCard]);

  useEffect(() => {
    if (values.currency) {
      dispatch(OnboardingActions.selectCurrency({ currency: values.currency }));
    }
  }, [dispatch, values.currency]);

  useEffect(() => {
    if (values.shipping) {
      dispatch(OnboardingActions.selectShipping({ shipping: values.shipping }));
    }
  }, [dispatch, values.shipping]);

  const onSubmit = async () => {
    if (selectedCard && values.currency && values.shipping) {
      await setUserData({ preferredCardCurrency: values.currency });
      navigate(AppRoutePath.CHECKOUT());
    } else {
      // TODO: replace it with schema validation
      notify("Select card, currency, and shipping", ToastTypes.error);
    }
  };

  if (!selectedPlan) {
    return null;
  }

  return (
    <div className="mx-auto flex w-3/4 py-24">
      <div className="relative grid flex-auto grid-cols-2 rounded-[20px] border-gray-200 bg-white/80 px-14 py-8 shadow backdrop-blur-xl dark:border-gray-700 dark:bg-gray-800">
        <div className="relative col-span-1">
          <h1 className="px-6 font-brown-regular text-xs uppercase">Card Design</h1>
          {cards && <CardSwiper cards={cards} />}
        </div>
        <div className="relative col-span-1">
          <Form<CardDesignForm> {...methods} onSubmit={onSubmit}>
            <div className="flex flex-col gap-3.5 py-11 pl-16 pr-3">
              {/* @ts-ignore */}
              {currencies && (
                <SmoothSelectField
                  name="currency"
                  options={currencies}
                  label={t("designCard.form.fields.currency.label")}
                  id="currency"
                  getOptionValue={option => option.name as string}
                  getOptionLabel={option => option.name as string}
                  formatOptionLabel={({ value, name }) => {
                    // @ts-ignore
                    const Flag = flags[value.substring(0, 2)];
                    return (
                      <div className="flex items-center gap-2">
                        <Flag style={{ height: "23px" }} />
                        <div>{name}</div>
                      </div>
                    );
                  }}
                  required
                />
              )}
              {ShippingOptions && (
                <SmoothSelectField
                  name="shipping"
                  options={shippingPlansToOptions(ShippingOptions)}
                  label={t("designCard.form.fields.shipping.label")}
                  id="shipping"
                  getOptionValue={option => option.name as string}
                  getOptionLabel={option => option.name as string}
                  required
                />
              )}
              <span className="px-4 text-xs text-gray-500">{t("designCard.form.description")}</span>
            </div>
            <div className="flex flex-col pl-14">
              <span className="p-2 text-center font-brown-bold text-xs text-black">
                {t("designCard.form.submit.information")}
              </span>
              <Button type="submit" buttonType="selectButton" className="h-14">
                {t("designCard.form.submit.label")}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CardDesignPage;
