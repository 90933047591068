import { yupResolver } from "@hookform/resolvers/yup";
import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { CheckboxField } from "ui-components/src/components/Checkbox/Checkbox.field";
import { ApiErrorCode } from "../../api/error-codes";
import { getServerErrorResponse } from "../../api/error-handling";
import {
  useSignUpCountryListMutation,
  useSignUpMutation,
  useSignUpUsStatesListMutation,
} from "../../api/slices/auth.api";
import { Card } from "../../components/Card/Card";
import { Form } from "../../components/Form/Form.component";
import { appActions } from "../../store/slices/app.slice";
import { createErrorMessage } from "../../utils/form/server-side-validation";
import { notify } from "../../utils/notify";
import { ToastTypes } from "../../utils/util-vars";
import { useSignUpValidation } from "./useValidation";
import { noLatinCharactersRegex } from "../../utils/validators";
import { SignUpFieldValues } from "./useValidationSchema";

import { useFeatureFlags } from "../../app/guards/feature-flags/useFeatureFlags";
import { InlineSpinner } from "../../components/Loading/InlineSpinner.component";
import { useAppDispatch } from "../../store/store";
import { renderSection } from "./renderSection";
import { useSignUpFormConfig } from "./useSignUpFormConfig";
import Button from "ui-components/src/components/Button/Button.component";

interface AgreementLinkProps {
  href?: string;
}

const AgreementLink: FC<PropsWithChildren<AgreementLinkProps>> = ({ href, children }) => (
  <a href={href} className="text-primary-blue">
    {children}
  </a>
);

export const SignUpPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [signUp, { data, error }] = useSignUpMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const featureFlags = useFeatureFlags();
  const { sections } = useSignUpFormConfig();

  const [fetchCountryList, { data: countriesData }] = useSignUpCountryListMutation();
  const [signUpUsStatesList, { data: statesList }] = useSignUpUsStatesListMutation();
  const countries = useMemo(() => countriesData?.data || [], [countriesData?.data]);
  const usStates = statesList?.data?.states || [];
  const allCountries = useMemo(() => countries.map(itemCountry => itemCountry.iso2), [countries]);

  const signUpValidationSchema = useSignUpValidation();
  const methods = useForm<SignUpFieldValues>({
    mode: "onBlur",
    resolver: yupResolver(signUpValidationSchema),
    defaultValues: signUpValidationSchema.getDefault(),
    shouldUnregister: true,
  });
  const country = methods.watch("countryCode");
  const displayLatinNameFields =
    methods.watch("firstName").match(noLatinCharactersRegex) || methods.watch("lastName").match(noLatinCharactersRegex);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    fetchCountryList();
  }, [fetchCountryList]);

  useEffect(() => {
    if (data) {
      notify(t("signUp.process.successfully"), ToastTypes.success);
      dispatch(appActions.signUp({ accessToken: data.data.accessToken.token }));
    }
  }, [data, dispatch, t]);

  useEffect(() => {
    if (error) {
      const errorResponse = getServerErrorResponse(error);
      const { message } = createErrorMessage(
        {
          // token expired shouldn't be an error when we adjust how token refreshing works
          [ApiErrorCode.TokenExpired]: t("validation.server.tokenExpired"),
          [ApiErrorCode.UserAlreadyRegistered]: t("validation.server.userAlreadyRegistered"),
        },
        errorResponse?.code,
        t("signUp.process.failure.unknown")
      );
      notify(message as string, ToastTypes.error);
    }
  }, [error, t]);

  useEffect(() => {
    if (country === "US") {
      signUpUsStatesList();
    }
  }, [country, signUpUsStatesList]);

  return (
    <Form<SignUpFieldValues> {...methods} onSubmit={signUp}>
      <Card className="form-padding mx-auto my-8 flex max-w-4xl flex-col gap-14 font-brown-regular">
        <div className="grid grid-cols-2 gap-y-12 pr-6">
          {sections.map(({ section, fields }) =>
            renderSection({
              // @ts-ignore
              name: section,
              fields,
              variables: {
                t,
                methods,
                country,
                countries,
                allCountries,
                displayLatinNameFields: Boolean(displayLatinNameFields),
                usStates,
                showPassword,
                togglePassword,
                showConfirmPassword,
                toggleConfirmPassword,
              },
            })
          )}
        </div>
        {/* Agreements and submit part */}
        <div>
          <div className="mb-6 flex flex-col items-center font-inter text-sm">
            <div>
              {/* FIXME: return the actual check when LD is up again */}
              {/* {featureFlags.membershipAgreement.enabled && ( */}
              {
                <CheckboxField
                  id="membershipAgreement"
                  name="membershipAgreement"
                  label={
                    <Trans
                      i18nKey="signUp.form.fields.agreeMembershipAgreement.label"
                      components={{
                        agreementLink: <AgreementLink href={featureFlags.membershipAgreement.url} />,
                      }}
                    />
                  }
                  required
                />
              }
              <CheckboxField
                id="termsAgreement"
                classes={{ root: "mt-2" }}
                name="termsAgreement"
                label={
                  <Trans
                    i18nKey="signUp.form.fields.agreeTermsAndConditions.label"
                    components={{
                      agreementLink: (
                        <AgreementLink href={t("signUp.form.fields.agreeTermsAndConditions.link") ?? undefined} />
                      ),
                    }}
                  />
                }
                required
              />
            </div>
          </div>

          <div className="flex items-center justify-center">
            <Button type="submit" className="max-w-[400px]" disabled={methods.formState.isSubmitting}>
              {methods.formState.isSubmitting ? (
                <>
                  <InlineSpinner />
                  {t("signUp.form.submitButton.loading")}
                </>
              ) : (
                <>{t("signUp.form.submitButton.text")}</>
              )}
            </Button>
          </div>
        </div>
      </Card>
    </Form>
  );
};
