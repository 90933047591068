export enum AuthEndpoints {
  AUTH_CSRF = "/v2/auth/csrf",
  AUTH_LOGIN = "/v2/auth/login",
  AUTH_LOGOUT = "/v2/auth/logout",
  AUTH_SIGN_UP = "/v2/auth/onboarding/signup",
  AUTH_RESET_PASSWORD = "/v1/auth/reset",
  PERMISSION = "/v2/auth/permission",
  CONFIRM_EMAIL = "/v1/auth/send",
  VALIDATE_EMAIL = "/v2/auth/signup/email/validate",
  VALIDATE_PHONE = "/v2/auth/signup/phone/validate",
  SIGN_UP_COUNTRY_LIST = "/v2/auth/signup/country",
  SIGN_UP_US_STATES_LIST = "/v2/auth/signup/country/US/states",
  SELECT_PLAN = "/v1/membership/plans",
}
