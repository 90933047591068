import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { KYC_VERIFICATION_STATUS } from "../../../api/models/kyc/get-verification-status";
import { KycApi } from "../../../api/slices/kyc.api";
import { AppRoutePath } from "../../../app/constants";
import { Spinner } from "../../../components/Loading/Spinner.component";
import { appActions } from "../../../store/slices/app.slice";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { notify } from "../../../utils/notify";
import { SpinnerTypes, ToastTypes } from "../../../utils/util-vars";

const POLLING_STATUSES = ["PENDING", "UNDER_REVIEW"];
const ALLOWED_POLLING_TIME = 30 * 1000;

export const KycStatusPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { kycSubmitted, kycApproved } = useAppSelector(state => state.app);
  const [isPolling, setIsPolling] = useState(true);
  const { data, error } = KycApi.useGetVerificationStatusQuery("", {
    pollingInterval: isPolling ? 3000 : undefined,
  });
  const isApproved = data?.overallStatus && !POLLING_STATUSES.includes(data.overallStatus);
  const showCheckLater = !error && !isApproved && !isPolling;
  const localizedStatusLabels = useMemo(
    (): Record<KYC_VERIFICATION_STATUS, string> => ({
      PENDING: t("kyc.confirmation.status.pending"),
      APPROVED: t("kyc.confirmation.status.approved"),
      REJECTED: t("kyc.confirmation.status.rejected"),
      UNDER_REVIEW: t("kyc.confirmation.status.underReview"),
      ADDITIONAL_DOCUMENTS_REQUIRED: t("kyc.confirmation.status.additionalDocumentsRequired"),
    }),
    [t]
  );
  const verificationStatusLabel = data?.overallStatus ? localizedStatusLabels[data.overallStatus] : null;
  const errorLabel = error ? t("kyc.confirmation.error.label") : null;

  useEffect(() => {
    if (kycApproved) {
      navigate(AppRoutePath.DASHBOARD(), { replace: true });
      return;
    }

    if (!kycSubmitted) {
      navigate(AppRoutePath.KYC_INTRO(), { replace: true });
    }
  }, [kycSubmitted, kycApproved, navigate]);

  useEffect(() => {
    if (data?.overallStatus === "APPROVED") {
      dispatch(appActions.kycApproved());
      notify(t("kyc.confirmation.success.message"), ToastTypes.success);
      navigate(AppRoutePath.DASHBOARD(), { replace: true });
    }
  }, [data?.overallStatus, navigate, t, dispatch]);

  useEffect(() => {
    if (error || isApproved) {
      setIsPolling(false);
    }
  }, [error, isApproved]);

  useEffect(() => {
    setTimeout(() => {
      setIsPolling(false);
    }, ALLOWED_POLLING_TIME);
  }, [setIsPolling]);

  return (
    <div className="mx-auto mb-8 mt-10 max-w-[995px] flex-1">
      <div className="auth-card-padding flex h-[556px] flex-col items-center justify-between rounded-2xl bg-white shadow-md">
        <h1 className="font-brown-bold text-2xl">
          {errorLabel ? t("kyc.confirmation.error.title") : t("kyc.confirmation.title")}
        </h1>
        <div className="my-auto">
          <div className="relative h-10">{isPolling && <Spinner type={SpinnerTypes.default} />}</div>
          {!showCheckLater && verificationStatusLabel && (
            <h2 className="font-inter mt-5 text-xl uppercase text-black/60">{verificationStatusLabel}</h2>
          )}
          {showCheckLater && (
            <h2 className="font-inter text-primary-blue mt-5 whitespace-pre text-center text-xl">
              {t("kyc.confirmation.checkLater")}
            </h2>
          )}
          {errorLabel && <h2 className="font-inter mt-5 text-xl uppercase text-red-400">{errorLabel}</h2>}
        </div>
      </div>
    </div>
  );
};
