import { useTranslation } from "react-i18next";
import CopyIcon from "ui-components/src/assets/CopyIcon.svg";
import InfoIcon from "ui-components/src/assets/InfoIcon.svg";
import AccountsIcon from "ui-components/src/assets/sidebar-icons/Accounts.svg";
import CardIcon from "ui-components/src/assets/sidebar-icons/Cards.svg";
import CryptoIcon from "ui-components/src/assets/sidebar-icons/Crypto.svg";
import { Card } from "../../../components/Card/Card";
import { Spinner } from "../../../components/Loading/Spinner.component";
import { SpinnerTypes } from "../../../utils/util-vars";
import { useUserCard } from "./hooks/useUserCard";

const UserCard = () => {
  const { t } = useTranslation();
  const { isLoading, accountsBalance, cardsBalance, cryptoBalance, totalBalance, firstName, lastName } = useUserCard();

  return (
    <Card className="relative min-h-[340px] w-[100%] px-6 py-8">
      {isLoading && <Spinner type={SpinnerTypes.default} />}
      {!isLoading && (
        <div className={`flex h-[100%] flex-col ${isLoading && "opacity-30"}`}>
          <div className={"flex gap-4"}>
            <img className={"h-12 w-12 rounded-full bg-gray-600"} alt=""></img>
            <div>
              <p className="font-semibold">
                {firstName} {lastName}
              </p>
              {/* TODO: add position data in the future */}
              {/* <p className="text-sm"></p> */}
            </div>
          </div>
          <button className="mt-4 mb-6 flex w-full justify-center gap-2 rounded-lg border border-[#635CF3] py-2 text-xs font-semibold text-[#635CF3]">
            <p>{t("userCard.referralLinkButton")}</p>
            <img src={CopyIcon} alt="Copy Icon" />
          </button>
          <div className="mb-4 text-sm text-gray-600">
            <div className="mb-2 flex">
              <h2 className=" mr-2 text-[12px] uppercase tracking-[1.5px] text-gray-400">
                {t("userCard.totalBalances")}
              </h2>
              <img className="mr-2" src={InfoIcon} alt="" />
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex justify-between">
                <div className="flex">
                  <img className="mr-2" src={AccountsIcon} alt="" />
                  <p>{t("userCard.accounts")}</p>
                </div>
                {<p>{accountsBalance}</p>}
              </div>
              <div className="flex justify-between">
                <div className="flex">
                  <img className="mr-2" src={CardIcon} alt="" />
                  <p>{t("userCard.cards")}</p>
                </div>
                <p>{cardsBalance}</p>
              </div>
              <div className="flex justify-between">
                <div className="flex">
                  <img className="mr-2" src={CryptoIcon} alt="" />
                  <p>{t("userCard.crypto")}</p>
                </div>
                <p>{cryptoBalance}</p>
              </div>
            </div>
          </div>
          <div className="mt-auto border-t border-blue-600">
            <div className="mt-2 flex justify-between gap-2">
              <p className="text-sm font-bold">{t("userCard.total")}</p>
              <p className="text-2xl font-bold">{totalBalance}</p>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

export default UserCard;
