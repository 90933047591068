import React from "react";
import Button from "ui-components/src/components/Button/Button.component";
import { CloseIcon } from "ui-components/src/components/icons/icons";

// @ts-ignore
export const CloseButton = ({ closeHandler }) => {
  return (
    <Button type="button" onClick={closeHandler} buttonType={"closeButton"} className="right-1 top-1 ">
      <CloseIcon />
    </Button>
  );
};
