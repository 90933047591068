import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";

if (!process.env.REACT_APP_IMAGES_AUTH) {
  throw new Error(`"REACT_APP_IMAGES_AUTH" environment variable is not provided`);
}

export const baseQueryImages = fetchBaseQuery({
  prepareHeaders: headers => {
    headers.set("Access-Control-Allow-Credentials", "true");
    headers.set("Access-Control-Allow-Origin", "*");
    headers.set("Access-Control-Allow-Methods", "GET, POST, PUT, DELETE, OPTIONS");
    headers.set("Access-Control-Allow-Headers", "Content-Type, Accept, X-Requested-With, remember-me");
    headers.set("Access-Control-Expose-Headers", "Content-Disposition");
    headers.set("With-credentials", "true");
    headers.set("Authorization", "Basic " + btoa(process.env.REACT_APP_IMAGES_AUTH));
    headers.set("Content-Type", "application/xml");
    return headers;
  },
});
