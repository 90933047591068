import { createApi } from "@reduxjs/toolkit/query/react";
import { KycEndpoints } from "../enums/kyc-endpoints";
import { getVerificationStatusResponseSchema } from "../models/kyc/get-verification-status";
import { initiateWebClientResponseSchema } from "../models/kyc/initiate-web-client";
import { commonResponseSchema } from "../models/responses/common-response";
import { baseQueryProtected } from "../query-fn/protected";

export const KycApi = createApi({
  reducerPath: "kycApi",
  baseQuery: baseQueryProtected,
  endpoints: builder => ({
    initiateWebClient: builder.mutation({
      query: () => ({
        url: KycEndpoints.INITIATE_WEB_CLIENT,
        method: "POST",
      }),
      transformResponse: response => commonResponseSchema(initiateWebClientResponseSchema()).parse(response).data,
    }),
    getVerificationStatus: builder.query({
      query: () => ({
        url: KycEndpoints.GET_VERIFICATION_STATUS,
      }),
      transformResponse: response => commonResponseSchema(getVerificationStatusResponseSchema()).parse(response).data,
    }),
  }),
});
