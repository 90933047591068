import * as yup from "yup";

export const getCountryListResponseSchema = yup.object().shape({
  data: yup.array().of(
    yup.object().shape({
      id: yup.string().required(),
      iso2: yup.string().required(),
      iso3: yup.string().required(),
      isoNumCode: yup.number().required(),
      name: yup.string().required(),
    })
  ),
});

export type GetCountryListResponse = yup.InferType<typeof getCountryListResponseSchema>;
