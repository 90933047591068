import React, { useEffect, useState } from "react";

// @ts-ignore
export const PasswordStrength = ({ password }) => {
  const [strength, setStrength] = useState(0);
  const barColor = {
    0: "bg-red-500",
    25: "bg-red-500",
    50: "bg-yellow-500",
    75: "bg-orange-500",
    100: "bg-green-500",
  };
  useEffect(() => {
    let strengthProgress = 0;
    if (password.match(/[a-z]/)) {
      strengthProgress += 25;
    }
    if (password.length >= 8) {
      strengthProgress += 25;
    }
    if (password.match(/\d/)) {
      strengthProgress += 25;
    }
    if (password.match(/[A-Z]/)) {
      strengthProgress += 25;
    }
    setStrength(strengthProgress);
  }, [password]);

  // @ts-ignore
  const colorClassName = barColor[strength];

  return (
    <div>
      <div className="relative h-2">
        {/* @ts-ignore */}
        <div className={`relative  inset-0 h-2 rounded-lg bg-gray-300 `}>
          <div className={`${colorClassName}  inset-0 h-2 rounded-lg `} style={{ width: `${strength}%` }}></div>
        </div>
      </div>
    </div>
  );
};
