import { ReactElement } from "react";
import { SpinnerTypes } from "../../utils/util-vars";
import { Spinner } from "../Loading/Spinner.component";

interface AsyncLoadableAreaProps<T> {
  data: T | null | undefined;
  error: unknown;
  isLoading: boolean;
  loader?: ReactElement;
  noData?: ReactElement;
  render: (data: T) => ReactElement;
  renderError?: (error: unknown) => ReactElement;
}

export const AsyncLoadableArea = <T,>({
  data,
  error,
  isLoading,
  loader = <Spinner type={SpinnerTypes.default} />,
  noData = <>No data</>,
  render,
  renderError = () => <>Failed to fetch data</>,
}: AsyncLoadableAreaProps<T>) => {
  if (isLoading) {
    return loader;
  }

  if (error) {
    return renderError(error);
  }

  if (!data) {
    return noData;
  }

  return render(data);
};
