import React from "react";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { Account } from "../../../api/models/account/account.schema";
import { getCurrenciesDescriptors } from "../../../components/CurrencyLabel/getCurrenciesDescriptors";
import { AccountRoutePath } from "../account.constants";

interface AccountListItemProps {
  account: Account;
}

export const AccountListItem: FC<AccountListItemProps> = ({ account }) => {
  const { t } = useTranslation();
  const currency = useMemo(() => getCurrenciesDescriptors(t)[account.currencyCode], [t, account.currencyCode]);
  const FlagIcon = currency.icon;

  return (
    <Link
      to={generatePath(AccountRoutePath.DETAILS(), account)}
      className="cursor-pointer rounded-2xl border border-black/5 bg-white p-6 transition-all hover:border-black/50 hover:bg-white/30"
    >
      <div className="flex flex-row items-center justify-start">
        <FlagIcon className="h-8 w-8" />
        <div className="ml-2">
          <h2 className="font-brown-bold text-base text-black">{currency.label}</h2>
          <h3 className="font-brown-regular text-xs opacity-50">{currency.shortCode}</h3>
        </div>
        <div className="ml-auto self-start">
          <h2 className="font-brown-regular text-lg text-black">
            {currency.symbol} {account.currentBalance}
          </h2>
        </div>
      </div>
      <p className="font-brown-regular mt-6 text-sm text-black">
        {t("accounts.accountNumber", { number: account.accountNo })}
      </p>
    </Link>
  );
};
