interface OnBoardingHeaderProps {
  title: string;
  description: string;
}
export const OnBoardingHeader = ({ title, description }: OnBoardingHeaderProps) => {
  return (
    <div className="w-full px-4">
      <h3 className="mb-4 text-center text-2xl font-extrabold leading-none tracking-tight text-gray-900  dark:text-white">
        {title}
      </h3>
      <h5 className="mx-auto mb-8 max-w-2xl text-center text-sm font-normal text-gray-500  dark:text-gray-400">
        {description}
      </h5>
    </div>
  );
};
