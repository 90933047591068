import React from "react";
import { Outlet } from "react-router-dom";
import { TopBar } from "../components/TopBar/TopBar";

export const AuthLayout = () => (
  <>
    <TopBar withHeaderMenu={false} />
    <Outlet />
  </>
);
