import * as yup from "yup";

export const getUsStatesListResponseSchema = yup.object().shape({
  data: yup.object().shape({
    states: yup.array().of(
      yup.object().shape({
        code: yup.string().required(),
        name: yup.string().required(),
      })
    ),
  }),
});

export type GetUsStatesListResponse = yup.InferType<typeof getUsStatesListResponseSchema>;
