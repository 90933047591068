import React, { useState, useEffect } from "react";

interface CountdownProps {
  minutes: number;
  seconds: number;
  onTimerComplete?: () => void;
}

const Countdown: React.FC<CountdownProps> = ({ minutes, seconds, onTimerComplete = () => {} }) => {
  const [timeLeft, setTimeLeft] = useState(minutes * 60 + seconds);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(prevTime => {
        if (prevTime === 0) {
          onTimerComplete();
          return prevTime;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft, onTimerComplete]);

  const displayMinutes = String(Math.floor(timeLeft / 60)).padStart(2, "0");
  const displaySeconds = String(timeLeft % 60).padStart(2, "0");

  return (
    <span className="font-brown-bold text-xs">
      {displayMinutes}:{displaySeconds}
    </span>
  );
};

export default Countdown;
