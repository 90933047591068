import React, { FC, forwardRef, HTMLProps } from "react";

import VerificationInput, { VerificationInputProps } from "react-verification-input";

export const VerificationCodeInput: FC<VerificationInputProps> = props => (
  <VerificationInput
    {...props}
    classNames={{
      container: "code-verification--container",
      // @ts-ignore
      character: `code-verification--character code-verification--character-${props?.length}`,
      characterInactive: "character--inactive",
      characterSelected: "code-verification--character__selected",
    }}
  />
);
