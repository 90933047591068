import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar.component";
import NotificationIcon from "ui-components/src/assets/NotificationIcon.svg";
import DashboardTopBar from "../components/DashboardTopBar/DashboardTopBar.component";
import { FC } from "react";
import { IBarLink } from "../components/DashboardTopBar/DashboardTopBar.barlink";

interface DashboardLayoutProps {
  topBarLinks?: IBarLink[];
}

const DashboardLayout: FC<DashboardLayoutProps> = ({ topBarLinks = [] }) => {
  return (
    <div className="font-brown-regular flex h-screen w-full overflow-x-hidden">
      <div className="fixed h-screen">
        <Sidebar />
      </div>
      <div className="grow pt-16 pl-[60px] sm:pt-20 sm:pl-[248px]">
        <div className="fixed top-0 right-0 flex h-16 w-[calc(100%-60px)] items-center justify-between bg-white  px-4 sm:h-20 sm:w-[calc(100%-248px)] sm:px-9">
          <DashboardTopBar links={topBarLinks} />
          <button>
            <img src={NotificationIcon} alt="Notification Icon" />
          </button>
        </div>
        <div className="p-2 md:p-8">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
