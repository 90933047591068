import { z } from "zod";

export const getVerificationStatusResponseSchema = () =>
  z.object({
    latinFirstName: z.string(),
    latinLastName: z.string(),
    status: z.string(),
    ssnStatus: z.string(),
    overallStatus: z.enum(["UNDER_REVIEW", "APPROVED", "ADDITIONAL_DOCUMENTS_REQUIRED", "REJECTED", "PENDING"]),
    dateTime: z.string(),
    id: z.string(),
    reference: z.string(),
  });

export type KYC_VERIFICATION_STATUS = z.infer<ReturnType<typeof getVerificationStatusResponseSchema>>["overallStatus"];
