import { useAppSelector } from "../../../store/store";
import { FeatureFlags } from "./featureFlagsSchema";

/**
 * This hook should be used when we want to retrieve object of feature flags configuration
 *
 * @returns {FeatureFlags}
 */
export const useFeatureFlags = (): FeatureFlags => {
  const { flags } = useAppSelector(state => state.featureFlags);

  if (!flags) {
    throw new Error(`"useFeatureFlags" used before feature flags were determined`);
  }

  return flags;
};
