import { z } from "zod";
import { currencyCodeSchema } from "../atoms/currency-code";
import { cardSchema } from "./card";
import { feeSchema } from "./fee";
import { walletSchema } from "./wallet";

export const planSchema = () =>
  z.object({
    // UUID
    id: z.string(),
    // Starter | Premier | VIP | Club Swan G1 | Club Swan G2 | Chairman
    name: z.string(),
    description: z.string().nullable(),
    enabled: z.boolean(),
    currencyISO: currencyCodeSchema(),
    // UUID
    currencyId: z.string(),
    // $
    currencySymbol: z.string(),
    price: z.number(),
    recurringFee: z.number(),
    fees: z.array(feeSchema()),
    // T1 | T2 | T3 | T4 | T5 | T6 | T7
    code: z.string(),
    // UUID
    membershipId: z.string(),
    autoRenew: z.enum(["YES", "NO"]),
    // Public | Private
    visibility: z.string().optional(),
    frequency: z.string(),
    services: z.array(z.any()),
    activeVirtualCardCount: z.number().optional(),
    cycle: z.number().optional().nullable(),
    currencies: z.object({
      card: cardSchema(),
      virtualCard: cardSchema().optional(),
      wallet: walletSchema(),
    }),
  });

export type Plan = z.infer<ReturnType<typeof planSchema>>;
