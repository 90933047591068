import { z } from "zod";

export const signUpSuccessfulResponseSchema = z.object({
  data: z.object({
    accessToken: z.object({
      token: z.string(),
      expiresIn: z.number(),
    }),
    confirmToken: z
      .object({
        token: z.string(),
        expiresIn: z.number(),
      })
      .optional(),
  }),
});

export type SignUpSuccessfulResponse = z.infer<typeof signUpSuccessfulResponseSchema>;
export type SignUpResponseData = z.infer<typeof signUpSuccessfulResponseSchema.shape.data>;
