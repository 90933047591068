import * as yup from "yup";
import { ApiErrorCode } from "../error-codes";
import { TokenSchema } from "./token";

export const nextActionRequiredResponseSchema = yup.object().shape({
  data: yup.object().shape({
    data: yup.object().shape({
      accessToken: TokenSchema,
      confirmToken: TokenSchema.default(undefined),
    }),
    error: yup
      .object()
      .required()
      .shape({
        code: yup
          .number()
          .required()
          .oneOf([
            ApiErrorCode.OnboardingRequired,
            ApiErrorCode.MfaRequired,
            ApiErrorCode.MfaSetupRequired,
            ApiErrorCode.EmailVerificationRequired,
            ApiErrorCode.PhoneVerificationRequired,
          ]),
      }),
  }),
});

type NextActionRequiredResponseSchema = yup.InferType<typeof nextActionRequiredResponseSchema>;

export function isNextActionRequired(error: any): error is NextActionRequiredResponseSchema {
  return nextActionRequiredResponseSchema.isValidSync(error);
}
