import { OnBoardingHeader } from "../../components/OnBoardingHeader/OnBoardingHeader.component";
import { useTranslation } from "react-i18next";
import { BackButton } from "../../components/BackButton/BackButton.component";
import React from "react";
import { PreMadePlanCard } from "../../components/PreMadePlanCard/PreMadePlanCard.component";
import CustomPlanCard from "../../components/CustomPlanCard/CustomPlanCard";
import { Plan } from "../../api/models/plans/plan";
import { NationalCurrencyIso } from "../../app/constants";

const UpgradePlanDummyData: Plan = {
  currencies: {
    card: {
      purchaseEnabled: [],
      disabled: [],
      config: {
        GBP: {
          list: [
            {
              cardType: "ALUMINIUM",
              productRef: "NV_SWANALUM",
              images: {
                back: "https://member.dev.clubswan.com/images/cards/aluminum_back.svg",
                front: "https://member.dev.clubswan.com/images/cards/aluminum_front.svg",
              },
              cost: 0,
              cardDesign: "1206",
            },
          ],
          currencyId: "1ba587ec-672a-e811-a958-002248018b80",
          activeCardCount: 2,
        },
        USD: {
          list: [
            {
              cardType: "ALUMINIUM",
              productRef: "NV_SWANALUM",
              images: {
                back: "https://member.dev.clubswan.com/images/cards/aluminum_back.svg",
                front: "https://member.dev.clubswan.com/images/cards/aluminum_front.svg",
              },
              cost: 0,
              cardDesign: "1248",
            },
          ],
          currencyId: "d22db4d5-0238-e811-a8a8-0022480173bb",
          activeCardCount: 1,
        },
        EUR: {
          list: [
            {
              cardType: "ALUMINIUM",
              productRef: "NV_SWANALUM",
              images: {
                back: "https://member.dev.clubswan.com/images/cards/aluminum_back.svg",
                front: "https://member.dev.clubswan.com/images/cards/aluminum_front.svg",
              },
              cost: 0,
              cardDesign: "1229",
            },
          ],
          currencyId: "91882582-494d-e811-a958-0022480187f0",
          activeCardCount: 2,
        },
      },
      enabled: [NationalCurrencyIso.USD, NationalCurrencyIso.GBP, NationalCurrencyIso.EUR],
    },
    wallet: {
      config: {
        //: {
        //   currencyId: "1a64b391-4d71-ed11-81ac-002248428616",
        //   list: [
        //     {
        //       cardDesign: "14045",
        //     },
        //   ],
        // },
        JPY: {
          currencyId: "973b3846-dee0-e811-a8be-0022480173bb",
          list: [
            {
              cardDesign: "3523",
            },
          ],
        },
        EUR: {
          currencyId: "91882582-494d-e811-a958-0022480187f0",
          list: [
            {
              cardDesign: "3513",
            },
          ],
        },
        GBP: {
          currencyId: "1ba587ec-672a-e811-a958-002248018b80",
          list: [
            {
              cardDesign: "3514",
            },
          ],
        },
        CNY: {
          currencyId: "e63ad09c-2d74-ea11-a811-00224801cecd",
          list: [
            {
              cardDesign: "3536",
            },
          ],
        },
        USD: {
          currencyId: "d22db4d5-0238-e811-a8a8-0022480173bb",
          list: [
            {
              cardDesign: "3515",
            },
          ],
        },
      },
      enabled: [
        NationalCurrencyIso.CNY,
        NationalCurrencyIso.EUR,
        NationalCurrencyIso.GBP,
        NationalCurrencyIso.JPY,
        NationalCurrencyIso.USD,
      ],
      disabled: [],
    },
    virtualCard: {
      purchaseEnabled: [],
      disabled: [],
      config: {
        GBP: {
          list: [
            {
              cardType: "VIRTUAL",
              productRef: "NV_VIRTUAL",
              images: {
                back: "https://member.dev.clubswan.com/images/cards/aluminum_back.svg",
                front: "https://member.dev.clubswan.com/images/cards/aluminum_front.svg",
              },
              cost: 3,
              cardDesign: "1206",
            },
          ],
          currencyId: "1ba587ec-672a-e811-a958-002248018b80",
        },
        USD: {
          list: [
            {
              cardType: "VIRTUAL",
              productRef: "NV_VIRTUAL",
              images: {
                back: "https://member.dev.clubswan.com/images/cards/aluminum_back.svg",
                front: "https://member.dev.clubswan.com/images/cards/aluminum_front.svg",
              },
              cost: 3,
              cardDesign: "1248",
            },
          ],
          currencyId: "d22db4d5-0238-e811-a8a8-0022480173bb",
        },
        EUR: {
          list: [
            {
              cardType: "VIRTUAL",
              productRef: "NV_VIRTUAL",
              images: {
                back: "https://member.dev.clubswan.com/images/cards/aluminum_back.svg",
                front: "https://member.dev.clubswan.com/images/cards/aluminum_front.svg",
              },
              cost: 3,
              cardDesign: "1229",
            },
          ],
          currencyId: "91882582-494d-e811-a958-0022480187f0",
        },
      },
      enabled: [NationalCurrencyIso.USD, NationalCurrencyIso.GBP, NationalCurrencyIso.EUR],
      // activeVirtualCardCount: 3,
    },
  },
  cycle: 1,
  activeVirtualCardCount: 3,
  services: [],
  frequency: "MONTH",
  visibility: "Public",
  name: "Continue Card Selection",
  autoRenew: "YES",
  membershipId: "3069a4cb-22df-4316-aeea-0e4f6d5daa8a",
  enabled: true,
  currencySymbol: "$",
  code: "T3",
  fees: [
    {
      amount: 0.005,
      code: "EXG",
      transactionGroup: {
        id: "9e8223b6-63ab-ec11-9840-000d3ad4b5d5",
        code: "EXG",
        financeCode: null,
      },
      statusCodeValue: "Active",
      description: "Crypto Exchange",
      type: "PCT",
      feeType: "Percentage",
      maximum: null,
      typeId: 827080001,
      stateCode: 0,
      id: "ea94efa8-50ab-ec11-9840-000d3ad555de",
      stateCodeValue: "Active",
      minimum: null,
      statusCode: 1,
    },
    {
      amount: 0.2,
      code: "M2M",
      transactionGroup: {
        id: "4698ad44-a49c-e811-a96a-0022480187f0",
        code: "M2M",
        financeCode: null,
      },
      statusCodeValue: "Active",
      description: "Member to Member",
      type: "PCT",
      feeType: "Percentage",
      maximum: null,
      typeId: 827080001,
      stateCode: 0,
      id: "ba33e75e-bd39-e911-a8c2-00224801a03a",
      stateCodeValue: "Active",
      minimum: 10,
      statusCode: 1,
    },
    {
      amount: 0.01,
      code: "WIR",
      transactionGroup: {
        id: "eae2eb57-a49c-e811-a96a-0022480187f0",
        code: "WIR",
        financeCode: null,
      },
      statusCodeValue: "Active",
      description: "SWIFT Payment Fee",
      type: "PCT",
      feeType: "Percentage",
      maximum: 500,
      typeId: 827080001,
      stateCode: 0,
      id: "92dc5c77-bd39-e911-a8c2-00224801a03a",
      stateCodeValue: "Active",
      minimum: 20,
      statusCode: 1,
    },
    {
      amount: 0.15,
      code: "RSP",
      transactionGroup: {
        id: "df41e1da-4534-e911-a8c2-00224801a03a",
        code: "RFL",
        financeCode: null,
      },
      statusCodeValue: "Active",
      description: "Referral Commission",
      type: "PCT",
      feeType: "Percentage",
      maximum: null,
      typeId: 827080001,
      stateCode: 0,
      id: "8d679e89-bd39-e911-a8c2-00224801a03a",
      stateCodeValue: "Active",
      minimum: 0,
      statusCode: 1,
    },
    {
      amount: 0.01,
      code: "FX",
      transactionGroup: {
        id: "ee00bee3-c99b-e811-a96a-0022480187f0",
        code: "FX",
        financeCode: null,
      },
      statusCodeValue: "Active",
      description: "FX Margin",
      type: "PCT",
      feeType: "Percentage",
      maximum: null,
      typeId: 827080001,
      stateCode: 0,
      id: "6470c695-bd39-e911-a8c2-00224801a03a",
      stateCodeValue: "Active",
      minimum: 0,
      statusCode: 1,
    },
    {
      amount: 0.005,
      code: "EXP",
      transactionGroup: {
        id: "0d025c5f-5bfd-e811-a8bc-00224801a03a",
        code: "EXP",
        financeCode: null,
      },
      statusCodeValue: "Active",
      description: "Expedite",
      type: "PCT",
      feeType: "Percentage",
      maximum: null,
      typeId: 827080001,
      stateCode: 0,
      id: "0e45c3a7-bd39-e911-a8c2-00224801a03a",
      stateCodeValue: "Active",
      minimum: 0,
      statusCode: 1,
    },
    {
      amount: 0,
      code: "FCI",
      transactionGroup: {
        id: "180a4455-b52e-e911-a8dc-00224801a4ba",
        code: "BTG",
        financeCode: null,
      },
      statusCodeValue: "Active",
      description: "Crypto Receive Fee",
      type: "FLAT",
      feeType: "Flat",
      maximum: null,
      typeId: 827080000,
      stateCode: 0,
      id: "88fef3c8-bd39-e911-a8c2-00224801a03a",
      stateCodeValue: "Active",
      minimum: 0,
      statusCode: 1,
    },
    {
      amount: 5,
      code: "FPP",
      transactionGroup: {
        id: "eae2eb57-a49c-e811-a96a-0022480187f0",
        code: "WIR",
        financeCode: null,
      },
      statusCodeValue: "Active",
      description: "Push Payment",
      type: "FLAT",
      feeType: "Flat",
      maximum: null,
      typeId: 827080000,
      stateCode: 0,
      id: "eb6ce4bd-7ab7-ea11-a812-00224801c499",
      stateCodeValue: "Active",
      minimum: 5,
      statusCode: 1,
    },
    {
      amount: 0.0198,
      code: "TLF",
      transactionGroup: {
        id: "bf0d8e5e-cb9b-e811-a96a-0022480187f0",
        code: "TXN",
        financeCode: null,
      },
      statusCodeValue: "Active",
      description: "Card Topup Fee",
      type: "PCT",
      feeType: "Percentage",
      maximum: null,
      typeId: 827080001,
      stateCode: 0,
      id: "931f9fb3-80d8-ea11-a813-00224801c499",
      stateCodeValue: "Active",
      minimum: null,
      statusCode: 1,
    },
    {
      amount: 0.01,
      code: "FP",
      transactionGroup: {
        id: "eae2eb57-a49c-e811-a96a-0022480187f0",
        code: "WIR",
        financeCode: null,
      },
      statusCodeValue: "Active",
      description: "FasterPayment Fee",
      type: "PCT",
      feeType: "Percentage",
      maximum: 500,
      typeId: 827080001,
      stateCode: 0,
      id: "e76758f2-6440-ed11-bba3-002248428616",
      stateCodeValue: "Active",
      minimum: 20,
      statusCode: 1,
    },
    {
      amount: 0.01,
      code: "SEPA",
      transactionGroup: {
        id: "eae2eb57-a49c-e811-a96a-0022480187f0",
        code: "WIR",
        financeCode: null,
      },
      statusCodeValue: "Active",
      description: "SEPA Payment Fee",
      type: "PCT",
      feeType: "Percentage",
      maximum: 500,
      typeId: 827080001,
      stateCode: 0,
      id: "4ff77b00-6540-ed11-bba3-002248428616",
      stateCodeValue: "Active",
      minimum: 20,
      statusCode: 1,
    },
    {
      amount: 0.01,
      code: "ACH",
      transactionGroup: {
        id: "eae2eb57-a49c-e811-a96a-0022480187f0",
        code: "WIR",
        financeCode: null,
      },
      statusCodeValue: "Active",
      description: "ACH Payment Fee",
      type: "PCT",
      feeType: "Percentage",
      maximum: 500,
      typeId: 827080001,
      stateCode: 0,
      id: "3281c514-6540-ed11-bba3-002248428616",
      stateCodeValue: "Active",
      minimum: 20,
      statusCode: 1,
    },
  ],
  recurringFee: 95,
  currencyId: "d22db4d5-0238-e811-a8a8-0022480173bb",
  currencyISO: NationalCurrencyIso.USD,
  description: null,
  price: 1195,
  id: "823f2518-7a3d-e811-a8a8-00224801a0fe",
};
const extraCards = [
  {
    title: "50$ and more",
    list: ["Free of charge occasionally", "Sometimes maybe Free", "Sometimes maybe not", "It's way too much"],
  },
  {
    title: "$109 and something",
    list: ["It's way too much", "Sometimes maybe Free", "Free of charge occasionally", "Sometimes maybe not"],
  },
];
export const UpgradePlanPage = () => {
  const { t } = useTranslation();
  const PreMadePlan = UpgradePlanDummyData;
  const selectPlan = (plan: Plan) => {
    console.log(plan);
    // navigate(AppRoutePath.());
  };
  return (
    <div className="mx-auto pt-14 sm:w-full md:w-5/6 xl:w-4/5">
      <div className="grid grid-flow-col grid-cols-8 grid-rows-3  gap-4">
        <div className="col-span-3 row-span-3 flex flex-auto gap-8">
          <div className="flex-none">
            <BackButton />
          </div>
          <div className="flex-auto">
            <PreMadePlanCard type={"upgrade"} plan={PreMadePlan} handleSelectPlan={selectPlan} />
          </div>
        </div>
        <div className="col-span-5 row-span-3">
          <OnBoardingHeader title={t("selectPlan.title")} description={t("selectPlan.description")} />
          <div className="flex flex-col gap-14">
            {extraCards.map((card, index) => (
              <CustomPlanCard key={index} title={card.title} list={card.list} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
