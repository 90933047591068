import { z } from "zod";
import { PlanEndpoints } from "../enums/plan-endpoints";
import { planSchema } from "../models/plans/plan";
import { commonResponseSchema } from "../models/responses/common-response";
import { BaseProtectedApi } from "./slices/base-protected.api";

export const PlansApi = BaseProtectedApi.injectEndpoints({
  endpoints: builder => ({
    getPlans: builder.query({
      query: () => ({
        url: PlanEndpoints.PLAN_LIST,
        method: "GET",
      }),
      transformResponse: response => commonResponseSchema(z.array(planSchema())).parse(response),
    }),
  }),
});
