import debounce from "lodash/debounce";

/**
 * This function helps to avoid extra API calls in cases where server-side validation is used
 * You can refer to `useSignUpValidationSchema` to see how it is used
 * Originally, the code was taken from here:
 * https://github.com/lodash/lodash/issues/4815#issuecomment-1006573679
 * The code wasn't added as library because of:
 * 1. no typescript support in the original code
 * 2. it's not a super-popular library and including it's as a library
 *    will increase possibility for instability of dependencies
 * 3. the function is straitforward and the changes are not expected,
 *    lets do not repeat left-pad problem :)
 *
 * @param func - async function to debounce
 * @param wait - delay time for debounce
 * @returns wrapper around original function with debounce effect
 */
export function asyncDebounce<F extends (...args: any[]) => Promise<any>>(func: F, wait?: number) {
  const resolveSet = new Set<(p: any) => void>();
  const rejectSet = new Set<(p: any) => void>();

  const debounced = debounce((args: Parameters<F>) => {
    func(...args)
      .then((...res) => {
        resolveSet.forEach(resolve => resolve(...res));
        resolveSet.clear();
      })
      .catch((...res) => {
        rejectSet.forEach(reject => reject(...res));
        rejectSet.clear();
      });
  }, wait);

  return (...args: Parameters<F>): ReturnType<F> =>
    new Promise((resolve, reject) => {
      resolveSet.add(resolve);
      rejectSet.add(reject);
      debounced(args);
    }) as ReturnType<F>;
}
