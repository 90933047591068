import React from "react";
import { useTranslation } from "react-i18next";
import Button from "ui-components/src/components/Button/Button.component";
import { useNavigate } from "react-router-dom";
import { BackIcon } from "ui-components/src/components/icons/icons";

export const BackButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <Button type="button" onClick={handleGoBack} buttonType={"backButton"} className="flex gap-2">
      <span>
        <BackIcon />
      </span>
      <span>{t("backButton.text")}</span>
    </Button>
  );
};
