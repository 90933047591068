export enum ApiErrorCode {
  VerificationCodeWrong = 40002,
  EmailAlreadyInUse = 40005,
  PhoneAlreadyInUse = 40006,
  // I don't know why we have 2 possible codes
  // TODO: study all codes
  PhoneAlreadyInUse2 = 40030,
  InvalidEmailField = 40007,
  InvalidPhoneField = 40008,
  PaymentFailed = 40050,
  // auth errors
  OnboardingRequired = 40104,
  EmailVerificationRequired = 40105,
  PhoneVerificationRequired = 40106,
  MfaSetupRequired = 40107,
  MfaRequired = 40113,
  TokenExpired = 40110,
  InvalidToken = 40111,
  UserAlreadyRegistered = 40901,
}
