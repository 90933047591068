import classNames from "classnames";
import { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

export interface IBarLink extends React.HTMLAttributes<HTMLAnchorElement> {
  path: string;
  isActive?: boolean;
  className?: string;
  isDisabled?: boolean;
}

interface BarLinkProps extends IBarLink {}

const BarLink: FC<PropsWithChildren<BarLinkProps>> = ({
  path,
  isActive,
  className,
  children,
  ...props
}: BarLinkProps) => {
  return (
    <Link
      {...props}
      className={classNames(
        "relative flex h-full items-center pr-5 text-sm font-bold opacity-30 after:absolute after:bottom-[0] after:content-[''] after:left-0 after:block after:h-1 after:w-full  active:opacity-100 [&:not(:last-child)]:mr-3",
        className,
        { "opacity-100 after:bg-black ": isActive }
      )}
      to={path}
    >
      {children}
    </Link>
  );
};

export default BarLink;
