import { z } from "zod";

export const feeSchema = () =>
  z.object({
    id: z.string(),
    // ACH Payment Fee, Asset Liquidation Fee, Card Load Fee,
    // Card Topup Fee, Crypto Exchange, Crypto Receive, Crypto Receive Fee,
    // Crypto Send, Crypto Send - External, Crypto Send - Internal, Expedite,
    // Expedite Payment, FX Margin, FasterPayment Fee, Member to Member, Payment Fee,
    // Push Payment, Referral Commission, SEPA Payment Fee, SWIFT Payment Fee
    description: z.string(),
    transactionGroup: z.object({
      id: z.string(),
      // BTG, CRD, EXG, EXP, FX, M2M, RFL, TXN, WIR
      code: z.string(),
      financeCode: z.any(),
    }),
    type: z.enum(["FLAT", "PCT"]),
    feeType: z.enum(["Flat", "Percentage"]),
    amount: z.number(),
    minimum: z.number().optional().nullable(),
    maximum: z.number().optional().nullable(),
    // ACH, ALF, ESD, EXG, EXP, FCI, FP, FPP, FX, ISD, M2M, RSP, SEPA, TLF, WIR
    code: z.string(),
    statusCode: z.number(),
    // Active
    statusCodeValue: z.string(),
    stateCode: z.number(),
    // Active
    stateCodeValue: z.string(),
    // 827080000, 827080001
    typeId: z.number(),
  });
