import { FC } from "react";
import BarLink, { IBarLink } from "./DashboardTopBar.barlink";

interface DashboardTopBarProps {
  links: IBarLink[];
}

const DashboardTopBar: FC<DashboardTopBarProps> = ({ links }) => {
  return (
    <nav className="mr-2 flex h-full">
      {links.map(({ path, isActive, children }) => (
        <BarLink key={path} path={path} isActive={isActive}>
          {children}
        </BarLink>
      ))}
    </nav>
  );
};

export default DashboardTopBar;
