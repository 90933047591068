import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CellProps, Column } from "react-table";
import { DEFAULT_TABLE_LIMIT } from "../../../../api/mocks/responses/transactions";
import { Transaction } from "../../../../api/models/transactions/transactions";
import { useGetTransactionsQuery } from "../../../../api/slices/transactions.api";
import StatusCell from "../StatusCell.component";

export const useTransactionsTable = () => {
  const { t } = useTranslation();
  const [tableLimit, setTableLimit] = useState(DEFAULT_TABLE_LIMIT);
  const { data, isLoading, isFetching, refetch } = useGetTransactionsQuery({ limit: tableLimit });

  const columns: Column<Transaction>[] = useMemo(
    () => [
      {
        Header: t("transactionsTable.columns.data")!,
        accessor: "transactionDate",
      },
      {
        Header: t("transactionsTable.columns.type")!,
        accessor: "debitCredit",
      },
      {
        Header: t("transactionsTable.columns.amount")!,
        accessor: "amount",
        // TODO: add corresponding icon according to that which status was retrieved
        Cell: (props: CellProps<Transaction>) => <span>{Number(props.value).toFixed(7)}</span>,
      },
      {
        Header: t("transactionsTable.columns.status")!,
        accessor: "currencyCode",
        Cell: (props: CellProps<Transaction>) => <StatusCell {...props} />,
      },
    ],
    [t]
  );

  const handleLoadMore = useCallback(() => setTableLimit(prev => prev + DEFAULT_TABLE_LIMIT), []);

  useEffect(() => {
    refetch();
  }, [tableLimit, refetch]);

  return { isLoading, handleLoadMore, isFetching, columns, data: data || [] };
};
