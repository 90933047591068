import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FeatureFlags } from "../../app/guards/feature-flags/featureFlagsSchema";

export interface FeatureFlagsState {
  flags: null | FeatureFlags;
}

const initialState: FeatureFlagsState = {
  flags: null,
};

export const featureFlagsSlice = createSlice({
  name: "featureFlags",
  initialState,
  reducers: {
    setFlags: (state, action: PayloadAction<{ flags: FeatureFlags }>) => {
      state.flags = action.payload.flags;
    },
  },
});

export const featureFlagsActions = featureFlagsSlice.actions;
