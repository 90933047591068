import * as yup from "yup";
import { SignUpFieldValues } from "../../../../pages/sign-up/useValidationSchema";

const AllSignUpFields: (keyof SignUpFieldValues)[] = [
  "email",
  "mobileNumber",
  "firstName",
  "lastName",
  "latinFirstName",
  "latinLastName",
  "countryCode",
  "state",
  "postCode",
  "city",
  "addressLine1",
  "addressLine2",
  "password",
  "confirmPassword",
  "preferredDisplayLanguage",
  "referralCode",
  "dateOfBirth",
  "ssn",
];

const AllSections = ["contactDetails", "addressDetails", "security", "preferences", "identityVerification"];

const sectionsOrder = () => yup.array().of(yup.string().oneOf(AllSections));

const fieldsOrder = () => yup.array().of(yup.string().oneOf(AllSignUpFields));

const sectionsFieldsOrder = () =>
  yup.object().shape({
    contactDetails: fieldsOrder(),
    addressDetails: fieldsOrder(),
    security: fieldsOrder(),
    preferences: fieldsOrder(),
    identityVerification: fieldsOrder(),
  });

export const defaultSectionsOrder = () =>
  sectionsOrder().validateSync(["contactDetails", "addressDetails", "security", "preferences", "identityVerification"]);

export const defaultSectionsFieldsOrder = () =>
  sectionsFieldsOrder().validateSync({
    contactDetails: ["email", "mobileNumber", "firstName", "lastName", "latinFirstName", "latinLastName"],
    addressDetails: ["countryCode", "state", "postCode", "city", "addressLine1", "addressLine2"],
    security: ["password", "confirmPassword"],
    preferences: ["preferredDisplayLanguage", "referralCode"],
    identityVerification: ["dateOfBirth", "ssn"],
  });

export const signUpFieldsArrangement = () =>
  yup
    .object()
    .shape({
      enabled: yup.boolean().required(),
      sectionsOrder: sectionsOrder(),
      sectionsFieldsOrder: sectionsFieldsOrder(),
    })
    .noUnknown();
