import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { To } from "react-router-dom";
import { z } from "zod";
import { AppRoutePath } from "../../../app/constants";
import { getRouter } from "../../../services/router";
import { AuthEndpoints } from "../../enums/auth-endpoints";
import { ConfirmMfaEndpoints } from "../../enums/confirm-mfa.endpoints";
import { ApiErrorCode } from "../../error-codes";
import { commonResponseSchema } from "../../models/responses/common-response";

type QueryResult = Awaited<ReturnType<ReturnType<typeof fetchBaseQuery>>>;

const isErrorCode = (code: number) => (queryResult: QueryResult) => {
  const parseResult = commonResponseSchema(z.unknown()).safeParse(queryResult.data || queryResult.error?.data);

  return parseResult.success ? parseResult.data.error?.code === code : false;
};

interface RedirectAfterQueryRule {
  condition: (queryResult: QueryResult) => boolean;
  destination: To;
}

const RedirectAfterQueryRuleList: RedirectAfterQueryRule[] = [
  {
    condition: ({ meta }) =>
      Boolean(meta?.request.url.endsWith(AuthEndpoints.AUTH_SIGN_UP) && meta?.response?.status === 200),
    destination: AppRoutePath.ONBOARDING(),
  },
  {
    condition: queryResult =>
      isErrorCode(ApiErrorCode.OnboardingRequired)(queryResult) &&
      // prevent this redirect on checkout page:
      // we have undesired behavior that API doesn't return
      // properly user state immediately after payment confirm
      // it takes some time
      getRouter().state.location.pathname !== AppRoutePath.CHECKOUT(),
    destination: AppRoutePath.ONBOARDING(),
  },
  {
    condition: isErrorCode(ApiErrorCode.EmailVerificationRequired),
    destination: AppRoutePath.EMAIL_VERIFICATION(),
  },
  {
    condition: isErrorCode(ApiErrorCode.PhoneVerificationRequired),
    destination: AppRoutePath.MOBILE_VERIFICATION(),
  },
  {
    condition: isErrorCode(ApiErrorCode.MfaSetupRequired),
    destination: AppRoutePath.MFA_SETUP(),
  },
  {
    condition: isErrorCode(ApiErrorCode.MfaRequired),
    destination: AppRoutePath.CONFIRM_MFA(),
  },
  {
    condition: queryResult =>
      Boolean(queryResult.meta?.request.url.endsWith(ConfirmMfaEndpoints.DEVICE_CONFIRM)) && Boolean(queryResult.data),
    destination: AppRoutePath.DASHBOARD(),
  },
];

export const handleRedirect = (queryResult: QueryResult) => {
  const foundRedirectRule = RedirectAfterQueryRuleList.find(({ condition }) => condition(queryResult));

  if (foundRedirectRule) {
    // setTimout is needed to give a change component hooks to handle everything
    setTimeout(() => {
      getRouter().navigate(foundRedirectRule.destination);
    }, 0);
  }
};
