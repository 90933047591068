import { createApi } from "@reduxjs/toolkit/query/react";
import { GetMfaSetupResponse, getMfaSetupResponseSchema } from "../models/mfa-setup";
import { ConfirmMfaEndpoints } from "../enums/confirm-mfa.endpoints";
import { commonResponseSchema } from "../models/responses/common-response";
import { GetMfaConfirmResponse, getMfaConfirmResponseSchema } from "../models/mfa-confirm";
import { IConfirmMfa } from "../models/confirm-mfa.interface";
import { baseQueryProtected } from "../query-fn/protected";

export const ConfirmMfaApi = createApi({
  reducerPath: "confirmMfaApi",
  baseQuery: baseQueryProtected,
  endpoints: builder => ({
    setupMfa: builder.mutation<GetMfaSetupResponse, { type: "TOTP" }>({
      query: body => ({
        url: ConfirmMfaEndpoints.SETUP_MFA,
        method: "Post",
        body,
      }),
      transformResponse: response => {
        return commonResponseSchema(getMfaSetupResponseSchema()).parse(response).data;
      },
    }),
    confirmMfa: builder.mutation<GetMfaConfirmResponse, IConfirmMfa>({
      query: input => ({
        url: ConfirmMfaEndpoints.CONFIRM_MFA,
        method: "POST",
        body: {
          secret: input.secretCode,
          secretCode: input.secretCode,
        },
        headers: {
          Authorization: `Bearer ${input.confirmToken}`,
        },
      }),
      transformResponse: response => {
        return commonResponseSchema(getMfaConfirmResponseSchema()).parse(response).data;
      },
    }),
    deviceConfirm: builder.mutation<GetMfaConfirmResponse, Omit<IConfirmMfa, "secret">>({
      query: input => ({
        url: ConfirmMfaEndpoints.DEVICE_CONFIRM,
        method: "POST",
        body: {
          secretCode: input.secretCode,
        },
        headers: {
          Authorization: `Bearer ${input.confirmToken}`,
        },
      }),
      transformResponse: response => {
        return commonResponseSchema(getMfaConfirmResponseSchema()).parse(response).data;
      },
    }),
  }),
});

export const { useSetupMfaMutation, useConfirmMfaMutation } = ConfirmMfaApi;
