import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryProtected } from "../query-fn/protected";
import { VerificationEndpoints } from "../enums/verification-endpoints";
import { commonResponseSchema } from "../models/responses/common-response";
import { VerificationResponse, VerificationSchema } from "../models/verification/verification";

export const VerificationApi = createApi({
  reducerPath: "verificationApi",
  baseQuery: baseQueryProtected,
  endpoints: builder => ({
    sendVerification: builder.mutation({
      query: (body: { type: "email" | "phone" }) => ({
        url: VerificationEndpoints.GET_VERIFICATION_CODE,
        method: "POST",
        body,
      }),
    }),
    confirmVerification: builder.mutation<VerificationResponse, { secretCode: number }>({
      query: body => ({
        url: VerificationEndpoints.CONFIRM_VERIFICATION,
        method: "POST",
        body,
        validateStatus: ({ status }: Response) => status === 401,
      }),
      transformResponse: response => {
        return commonResponseSchema(VerificationSchema()).parse(response).data;
      },
    }),
  }),
});

export const { useSendVerificationMutation, useConfirmVerificationMutation } = VerificationApi;
