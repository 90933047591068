import { t, TFunction } from "i18next";
import { Fragment, ReactElement } from "react";
import { UseFormReturn } from "react-hook-form";
import { Country } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { TextField, PhoneField, SelectField, TextMaskField } from "ui-components/src/components/Input/Input.field";
import { GetCountryListResponse } from "../../api/models/get-country-list";
import { GetUsStatesListResponse } from "../../api/models/get-states-list";
import { PasswordStrength } from "../../components/PasswordStrength/PasswordStregth.component";
import { SignUpFieldValues } from "./useValidationSchema";
import { EyeIcon, SecureIcon } from "ui-components/src/components/icons/icons";

interface RenderFieldVariables {
  t: TFunction;
  methods: UseFormReturn<SignUpFieldValues>;
  country?: string;
  countries: GetCountryListResponse["data"];
  allCountries: string[];
  displayLatinNameFields: boolean;
  usStates: GetUsStatesListResponse["data"]["states"];
  showPassword: boolean;
  togglePassword: () => void;
  showConfirmPassword: boolean;
  toggleConfirmPassword: () => void;
}

const SIGN_UP_FIELDS: Record<string, (variables: RenderFieldVariables) => ReactElement> = {
  email: () => <TextField type="email" id="email" name="email" label={t("signUp.form.fields.email.label")} required />,
  mobileNumber: ({ countries, methods, allCountries }) => (
    <>
      {/* @ts-ignore */}
      {countries.length > 0 && (
        <PhoneField
          id="mobileNumber"
          name="mobileNumber"
          label={t("signUp.form.fields.phone.label")}
          required
          defaultCountry={methods.watch("countryCode") as Country}
          countries={allCountries as Country[]}
          onCountryChange={countryCode => {
            // @ts-ignore
            methods.setValue("countryCode", countryCode);
          }}
        />
      )}
    </>
  ),
  firstName: () => (
    <TextField type="text" id="firstName" name="firstName" label={t("signUp.form.fields.firstName.label")} required />
  ),
  lastName: () => (
    <TextField type="text" id="lastName" name="lastName" label={t("signUp.form.fields.lastName.label")} required />
  ),
  latinFirstName: ({ displayLatinNameFields }) => (
    <>
      {displayLatinNameFields && (
        <TextField
          type="text"
          id="latinFirstName"
          name="latinFirstName"
          label={t("signUp.form.fields.latinFirstName.label")}
          required
        />
      )}
    </>
  ),
  latinLastName: ({ displayLatinNameFields }) => (
    <>
      {displayLatinNameFields && (
        <TextField
          type="text"
          id="latinLastName"
          name="latinLastName"
          label={t("signUp.form.fields.latinLastName.label")}
          required
        />
      )}
    </>
  ),
  countryCode: ({ countries }) => (
    <>
      {/* @ts-ignore */}
      {countries.length > 0 && (
        <SelectField
          name="countryCode"
          options={countries}
          label={t("signUp.form.fields.country.label")}
          id="countryCode"
          getOptionValue={option => option.iso2}
          getOptionLabel={option => option.name}
          formatOptionLabel={({ iso2, name }) => {
            // @ts-ignore
            const Flag = flags[iso2];
            return (
              <div className="flex items-center gap-2">
                <Flag style={{ width: "20px", height: "15px" }} /> <div>{name}</div>
              </div>
            );
          }}
          required
        />
      )}
    </>
  ),
  state: ({ country, usStates }) => (
    <>
      {/* @ts-ignore */}
      {country === "US" && usStates.length ? (
        <SelectField
          name="state"
          options={usStates}
          label={t("signUp.form.fields.state.label")}
          id="state"
          getOptionValue={option => option.name as string}
          getOptionLabel={option => option.name as string}
          classes={{ root: "slide-in-transition" }}
          required
        />
      ) : null}
    </>
  ),
  postCode: () => (
    <TextField type="text" id="postCode" name="postCode" label={t("signUp.form.fields.zipCode.label")} required />
  ),
  city: () => <TextField type="text" id="city" name="city" label={t("signUp.form.fields.city.label")} required />,
  addressLine1: () => (
    <TextField
      type="text"
      id="addressLine1"
      name="addressLine1"
      label={t("signUp.form.fields.addressLine1.label")}
      required
    />
  ),
  addressLine2: () => (
    <TextField type="text" id="addressLine2" name="addressLine2" label={t("signUp.form.fields.addressLine2.label")} />
  ),
  password: ({ showPassword, togglePassword, methods }) => (
    <div className="relative">
      <TextField
        type={showPassword ? "text" : "password"}
        id="password"
        name="password"
        label={t("signUp.form.fields.password.label")}
        required
      />
      <EyeIcon onClick={togglePassword} className="absolute right-0 top-3 mr-3 h-5 w-5 cursor-pointer" />
      {methods.watch("password") && <PasswordStrength password={methods.watch("password")} />}
    </div>
  ),
  confirmPassword: ({ showConfirmPassword, toggleConfirmPassword }) => (
    <div className="relative">
      <TextField
        type={showConfirmPassword ? "text" : "password"}
        id="confirmPassword"
        name="confirmPassword"
        label={t("signUp.form.fields.confirmPassword.label")}
        helperText={t("signUp.form.fields.confirmPassword.helperText")}
        required
      />
      <EyeIcon onClick={toggleConfirmPassword} className="absolute right-0 top-3 mr-3 h-5 w-5 cursor-pointer" />
    </div>
  ),
  preferredDisplayLanguage: () => (
    <SelectField
      id="preferredDisplayLanguage"
      name="preferredDisplayLanguage"
      label={t("signUp.form.fields.language.label") as string}
      getOptionValue={option => option.value}
      options={[{ value: "en", label: "English" }]}
    />
  ),
  referralCode: () => (
    <TextField type="text" id="referralCode" name="referralCode" label={t("signUp.form.fields.referralCode.label")} />
  ),
  dateOfBirth: () => (
    <div className="relative">
      <TextMaskField
        type="text"
        id="dateOfBirth"
        name="dateOfBirth"
        label={t("signUp.form.fields.birthday.label")}
        required
      />
      <SecureIcon className="absolute right-0 top-3 mr-3 h-5 w-5" />
    </div>
  ),
  ssn: ({ country }) => (
    <>
      {country === "US" && (
        <div className="relative">
          <TextField
            type="text"
            id="ssn"
            name="ssn"
            label={t("signUp.form.fields.ssn.label")}
            required
            helperText={t("signUp.form.fields.ssn.helperText")}
            classes={{ helperText: "underline" }}
          />
          <SecureIcon className="absolute right-0 top-3 mr-3 h-5 w-5" />
        </div>
      )}
    </>
  ),
};

interface RenderSectionArgs {
  name: string;
  fields: string[];
  variables: RenderFieldVariables;
}

const getSectionsTexts = (t: TFunction): Record<string, { title: string; description: string }> => ({
  contactDetails: {
    title: t("signUp.contactDetails.title"),
    description: t("signUp.contactDetails.description"),
  },
  addressDetails: {
    title: t("signUp.address.title"),
    description: t("signUp.address.description"),
  },
  security: {
    title: t("signUp.security.title"),
    description: t("signUp.security.description"),
  },
  preferences: {
    title: t("signUp.preferences.title"),
    description: t("signUp.preferences.description"),
  },
  identityVerification: {
    title: t("signUp.identityVerification.title"),
    description: t("signUp.identityVerification.description"),
  },
});

export const renderSection = ({ name, fields, variables }: RenderSectionArgs) => {
  const texts = getSectionsTexts(variables.t);

  if (!texts[name] || fields.filter(itemField => SIGN_UP_FIELDS[itemField]).length === 0) {
    return null;
  }

  const { title, description } = texts[name];

  return (
    <Fragment key={name}>
      <div className="mr-7">
        <h1 className="font-brown mb-4 text-2xl font-bold">{title}</h1>
        <p className="font-inter">{description}</p>
      </div>
      <div className="flex flex-col justify-between gap-3">
        {fields.map(fieldName => {
          if (!SIGN_UP_FIELDS[fieldName]) {
            return null;
          }

          return <Fragment key={fieldName}>{SIGN_UP_FIELDS[fieldName](variables)}</Fragment>;
        })}
      </div>
    </Fragment>
  );
};
