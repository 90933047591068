import React, { PropsWithChildren } from "react";
import { FormProvider, FormProviderProps, SubmitHandler, SubmitErrorHandler, FieldValues } from "react-hook-form";

interface FormProps<TFieldValues extends FieldValues> extends FormProviderProps<TFieldValues> {
  onSubmit: SubmitHandler<TFieldValues>;
  onSubmitInvalid?: SubmitErrorHandler<TFieldValues>;
}

export const Form = <TFieldValues extends FieldValues>({
  children,
  onSubmit,
  onSubmitInvalid,
  ...props
}: PropsWithChildren<FormProps<TFieldValues>>) => {
  return (
    <FormProvider<TFieldValues> {...props}>
      <form onSubmit={props.handleSubmit(onSubmit, onSubmitInvalid)} noValidate>
        {children}
      </form>
    </FormProvider>
  );
};
