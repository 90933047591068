import { AccountRoutePath } from "../pages/account/account.constants";

export const PERSISTOR_STORAGE_ROOT_KEY = "root";

export const AppRoutePath = {
  ROOT: () => "/",
  SIGN_IN: () => "/sign-in",
  CONFIRM_MFA: () => `${AppRoutePath.SIGN_IN()}/confirm-mfa`,
  SIGN_UP: () => "/sign-up",
  FORGOT_PASSWORD: () => "/forgot-password",
  RESET_PASSWORD: () => "/reset-password",
  ONBOARDING: () => "/onboarding",
  MFA_SETUP: () => "/mfa-setup",
  EMAIL_VERIFICATION: () => "/verify-email",
  MOBILE_VERIFICATION: () => "/verify-mobile",
  DASHBOARD: () => "/dashboard",
  CARDS: () => `/cards`,
  VIRTUAL_CARDS: () => `${AppRoutePath.CARDS()}/virtual`,
  ADD_VIRTUAL_CARDS: () => `${AppRoutePath.CARDS()}/virtual-add`,
  ACCOUNTS: AccountRoutePath,
  CRYPTO: () => `/crypto`,
  TRAVEL: () => `/travel`,
  CONCIERGE: () => `/concierge`,
  REFERRALS: () => `/referrals`,
  SHOPPING: () => `/shopping`,
  INSTANT_REWARDS: () => `/instantRewards`,
  SETTINGS: () => `/settings`,
  CREATE_CUSTOM_PLAN: () => `${AppRoutePath.ONBOARDING()}/create-custom-plan`,
  CREATE_PRE_MADE_PLAN: () => `${AppRoutePath.ONBOARDING()}/create-pre-made-plan`,
  STARTER_PLAN_CONFIRMATION: () => `${AppRoutePath.ONBOARDING()}/starter-plan-confirmation`,
  UPGRADE_PLAN: () => `${AppRoutePath.ONBOARDING()}/upgrade-plan`,
  CARD_DESIGN: () => "/card",
  CHECKOUT: () => "/checkout",
  KYC: () => "/kyc",
  KYC_INTRO: () => `${AppRoutePath.KYC()}/intro`,
  KYC_VERIFICATION: () => `${AppRoutePath.KYC()}/verification`,
  KYC_STATUS: () => `${AppRoutePath.KYC()}/status`,
} as const;

export enum UserAuthState {
  GUEST = "guest",
  INVALID_AUTH = "invalid-auth",
  ONBOARDING_REQUIRED = "onboarding-required",
  VERIFICATION_REQUIRED = "verification-required",
  MFA_SETUP_REQUIRED = "mfa-setup-required",
  MFA_REQUIRED = "mfa-required",
  SIGNED_IN = "signed-in",
}

export enum NationalCurrencyIso {
  CNY = "CNY",
  EUR = "EUR",
  GBP = "GBP",
  JPY = "JPY",
  // TODO: Add php icon to currencies descriptors
  PHP = "PHP",
  USD = "USD",
}

// TODO: Add other ticker icons
export enum CryptoCurrencyTicker {
  // TBCH = "TBCH",
  TBTC = "TBTC",
  // TEOS = "TEOS",
  // TERC = "TERC",
  // TETH = "TETH",
  // TLTC = "TLTC",
}

export enum AccountType {
  Card = 1,
  Wallet = 2,
}

export enum TransactionStatuses {
  unconfirmed = "unconfirmed",
  success = "success",
}
