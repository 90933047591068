import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { GroupBase } from "react-select";
import { InputProps, SmoothSelect, VerificationCode } from "./Input.component";
import { PhoneInput, SelectInput, TextInput, TextMaskInput } from "./Input.component";
import { PhoneInputProps } from "./inputs/BasePhoneInput";
import { SelectInputProps } from "./inputs/BaseSelectInput";
import { TextInputProps } from "./inputs/BaseTextInput";
import { TextMaskInputProps } from "./inputs/BaseTextMaskInput";
import VerificationInput, { VerificationInputProps } from "react-verification-input";

const useUncontrolledInputFieldProps = (props: InputProps) => {
  const { register, formState } = useFormContext();

  if (!props.name) {
    throw new Error(`"name" is mandatory property for InputField component`);
  }

  const errorText = formState.errors[props.name]?.message?.toString();
  return {
    inputProps: register(props.name),
    helperText: errorText || props.helperText,
    isError: Boolean(errorText),
    disabled: formState.isSubmitting || props.disabled,
  };
};

export const TextField: FC<InputProps & TextInputProps> = props => {
  const { inputProps, helperText, isError, disabled } = useUncontrolledInputFieldProps(props);

  return <TextInput {...props} {...inputProps} helperText={helperText} isError={isError} disabled={disabled} />;
};

export const TextMaskField: FC<InputProps & TextMaskInputProps> = props => {
  const { inputProps, helperText, isError, disabled } = useUncontrolledInputFieldProps(props);

  return <TextMaskInput {...props} {...inputProps} helperText={helperText} isError={isError} disabled={disabled} />;
};

export const isOption = <Option,>(option: Option | GroupBase<Option>): option is Option =>
  !(option as GroupBase<Option>).options;

export const SelectField = <Option,>(props: InputProps & SelectInputProps<Option>) => {
  const { control } = useFormContext();
  const { helperText, isError, disabled } = useUncontrolledInputFieldProps(props);
  const { name, options } = props;

  if (!name) {
    throw new Error(`"name" is mandatory property for SelectField component`);
  }

  if (!options) {
    throw new Error(`"options" is mandatory property for SelectField component`);
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const selectedOption = options.find(
          itemOption => isOption(itemOption) && props.getOptionValue(itemOption) === field.value
        );
        return (
          <SelectInput<Option>
            {...props}
            helperText={helperText}
            isError={isError}
            isDisabled={disabled}
            name={field.name}
            onBlur={field.onBlur}
            value={selectedOption as Option}
            onChange={newSelectedOption => {
              if (isOption(newSelectedOption)) {
                field.onChange(props.getOptionValue(newSelectedOption as Option));
              }
            }}
          />
        );
      }}
    />
  );
};

export const VerificationCodeField: FC<
  InputProps & VerificationInputProps & Omit<PhoneInputProps, "value" | "onChange">
> = props => {
  const { control } = useFormContext();
  const { helperText, isError, disabled } = useUncontrolledInputFieldProps(props);
  const { name } = props;

  if (!name) {
    throw new Error(`"name" is mandatory property for InputField component`);
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <VerificationCode
          {...props}
          helperText={helperText}
          isError={isError}
          disabled={disabled}
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          label={""}
        />
      )}
    />
  );
};

export const SmoothSelectField = <Option,>(props: InputProps & SelectInputProps<Option>) => {
  const { control } = useFormContext();
  const { helperText, isError, disabled } = useUncontrolledInputFieldProps(props);
  const { name, options } = props;

  if (!name) {
    throw new Error(`"name" is mandatory property for SelectField component`);
  }

  if (!options) {
    throw new Error(`"options" is mandatory property for SelectField component`);
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const selectedOption = options.find(
          itemOption => isOption(itemOption) && props.getOptionValue(itemOption) === field.value
        );
        return (
          <SmoothSelect<Option>
            {...props}
            helperText={helperText}
            isError={isError}
            isDisabled={disabled}
            name={field.name}
            onBlur={field.onBlur}
            value={selectedOption as Option}
            onChange={newSelectedOption => {
              if (isOption(newSelectedOption)) {
                field.onChange(props.getOptionValue(newSelectedOption as Option));
              }
            }}
          />
        );
      }}
    />
  );
};

export const PhoneField: FC<InputProps & Omit<PhoneInputProps, "value" | "onChange">> = props => {
  const { control } = useFormContext();
  const { helperText, isError, disabled } = useUncontrolledInputFieldProps(props);
  const { name } = props;

  if (!name) {
    throw new Error(`"name" is mandatory property for InputField component`);
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <PhoneInput
          {...props}
          helperText={helperText}
          isError={isError}
          disabled={disabled}
          name={field.name}
          value={field.value}
          onBlur={field.onBlur}
          onChange={field.onChange}
        />
      )}
    />
  );
};
