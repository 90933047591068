import * as yup from "yup";

export interface ValidatePhoneBody {
  phone: string;
}

export const validatePhoneResponseSchema = yup.object().shape({
  data: yup.object().shape({
    valid: yup.boolean(),
  }),
});

export type ValidatePhoneResponse = yup.InferType<typeof validatePhoneResponseSchema>;
