import React, { useEffect, useState } from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { List } from "fintech-app/src/interfaces/Cards.interface";
import { useLazyGetImageQuery } from "fintech-app/src/api/slices/image.api";
import "./CardSwiper.css";

interface ICardSwiperProps {
  cards: List[];
}

const CardSwiper = ({ cards }: ICardSwiperProps) => {
  const [getCardImage] = useLazyGetImageQuery();
  const [cardImages, setCardImages] = useState([] as string[]);

  useEffect(() => {
    Promise.all(
      cards.map((card: List) => {
        return getCardImage(card.images.front).unwrap();
      })
    ).then(data => setCardImages(data));
  }, [cards, getCardImage]);
  return (
    <>
      <Swiper
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {cardImages.map((cardImage, index: number) => (
          <SwiperSlide key={index + cardImage} className="card swiper-card relative">
            {<img src={cardImage} />}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default CardSwiper;
